import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class TechnicalCourses extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-TCouse-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Technical Courses</h4>
                                <p>Driving Businesses Through Advanced Technology</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Technological Courses</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>The best way to expand your career is through professional knowledge and training. Today IT and Tech sector is facing cutthroat competition, and it is only through intensive knowledge and expertise could stand out from the crowd.</p>
                                        <p className='font-18'>Mount Talent Consulting a leading name in the ‘integrated employment service’ sector works hard in finding and moulding talented individuals and getting them ready for tomorrow. We provide some of the most in-demand courses to learn and sharpen your skills so you can be ready for tomorrow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='usp-top-box'>
                                <div className='main-head-box'>
                                    <h3>Why Would You Need Tech Courses?</h3>
                                    <div className='feature-line'>
                                        <span className='animate-bar'></span>
                                    </div>
                                </div>
                                <div className='usp-main-item'>
                                    <div className='usp-item-box color-one'>
                                        <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                        <h4>To Gain Credibility And Validation.</h4>
                                    </div>
                                    <div className='usp-item-box color-two'>
                                        <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                        <h4>To Increase Your Market Value During The Hiring Process.</h4>
                                    </div>
                                    <div className='usp-item-box color-three'>
                                        <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                        <h4>To Heighlight And Improve Your Skills And Keep Up With The Latest Advancement.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Some Of The In-Demand Tech Courses We Provide</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Data Science</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>One of the most in-demand career options in the world today is data science, which has taken the world by storm. There are no limits to what can be done in the field, and Data Science jobs promise to pay well.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Big Data</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Big Data isn't a technology; rather, it's a way of looking at huge amounts of data to learn more and make better decisions. Professionals with expertise in Big Data are in high demand as the daily volume of data grows.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Machine Learning & Artificial Intelligence</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>AI and machine learning are the hottest buzzwords in technology right now, and all of the big businesses are using them to do business better. From drones to voice assistants, AI and machine learning are increasingly finding applications in our day-to-day lives. They are also generating new career paths and creating jobs across sectors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Cloud Computing</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Internet-based computing is referred to as cloud computing. In the past, businesses were required to use software that was downloaded onto a physical computer or server. Cloud computing has been widely embraced and is now employed by the majority of organisations worldwide. It enables individuals to work on the same applications from different systems using the Internet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Networking</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Even in the era of cloud computing, every business requires a computer network that is both effective and well-maintained. So, do not underestimate the importance of Networking in the developing world.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-six'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Software Development</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Software developers who can develop new apps as needed are in high demand because of the rapid pace of change in today's world. Every domain in the market is coming up with its software and applications making software development a hot job in the world.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-seven'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>DevOps</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>The term "DevOps" refers to a method that combines development and operations to rev software development. Any business that wants to remain competitive in the fast-paced market must have these skills.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-eight'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Cyber Security</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Every business has sensitive information that shouldn't be compromised, so cyber security is essential in light of the ever-increasing number of cyberattacks. Cybersecurity experts are in short supply right now, making this a smart career choice for those who are interested.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
