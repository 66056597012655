import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class LeadershipCourses extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-LCouse-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Leadership Courses</h4>
                                <p>We frequently discover that additional skills</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Leadership Courses</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>We frequently discover that additional skills are required in the workplace when starting a new career or working in an existing position, particularly now that machines increasingly dominate the workforce.</p>

                                        <p className='font-18'>Leadership or the capacity to lead and direct others toward the improvement of themselves and the company is one of these skills that is crucial in a workplace that may become more machine than human.</p>

                                        <p className='font-18'>Mount Talent Consulting is the leading name in the business itself and is working towards making the business and corporate world a better place through development, knowledge and skills. We bring students to their learning passion, turning simple employees into a leader of work.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Leadership Courses We Provide</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16 text-black pt-3'>We present some of the best programmes to earn that leadership capability through both practical and theoretical approaches. Mentioned below are the top leadership courses that we provide.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Inclusive Leadership</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>This course focuses on inclusive leadership and the significance of promoting diversity and inclusion in the workplace as a means of demonstrating one's ability to lead. The course gives students the ability to consider the people they work with and ensure that they are at ease and included when participating in work. By enrolling in this course, you will improve your capacity to ensure that your coworkers are valued and that you are viewed as a social leader in the workplace.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>High-Performance Collaboration</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>This course is all about the skills you need to become a great leader among your coworkers and a worker your bosses will want to pay attention to. This course will help beginners understand the fundamentals of leadership and create a winning situation for both you and the people you are leading. Negotiation, teamwork, and collaboration are the main pillars of leadership that are the focus of this class, which will help students better understand leadership.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Enhancing Leadership Skills</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>This course is important for many reasons, the most important of which is the ability to identify various leadership styles. The style that is being taught is called the "Savoir-Relier" style, and it is a newer style that tries to help people who don't agree with each other form relationships. This course works well for developing your unique leadership style, identifying your strengths and weaknesses, and then customising a leadership style that works for you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Teamwork Skills</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>This course will help you understand interactions and figure out why some things work and others don't when working as a team. It will make you the "manager" of your team so that everything runs smoothly. Those who took it agreed, saying that while it is beneficial for those interested in communication skills and theory, it may be too theoretical for those seeking entry-level jobs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Managing Uncertain Time Stress</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>This course focuses on teaching you how to control your emotions and maintain a positive outlook both in your professional life and at home. This class teaches you how to direct your emotions in a positive way to avoid stress and how to recognise negative emotions before they arise.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
