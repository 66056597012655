import React, { Component, Suspense } from 'react'
import { jobCountByTopCategory, topCompanyList, topCompanyImages, topPremiumFeaturedCompanyList } from '../../action/dashboard';
import { popularCity, premiumCityList, selectCountry } from '../../action/jobsByActions';
import Shimmer from '../../components/common/Shimmer';
import constant from '../../constant';
import HometabsWithShimmer from '../../components/home/hometabsWithShimmer'
import { MetaTags } from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import { getStorage } from '../../utils';
const Hometabs = React.lazy(() => import('../../components/home/hometabs'));

export default class homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOCATION_LIST: null,
            JOB_COUNT_BY_TOP_CATEGORY: null,
            FEATURED_COMPANIES: null,
            PREMIUM_COMPANIES: null,
            TOP_COMPANY_IMAGES: null,
            Country:'',
            //regionSelect:this.props?this.props.regionSelect:[],
            //City:""
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = constant.title.Homepage
        this.getPremiumCityList()
        // jobCountByTopCategory().then(res => {
        //     if (res.status) {
        //         this.setState({ JOB_COUNT_BY_TOP_CATEGORY: res.result })
        //     }
        //     else {
        //         alert(res.error)
        //     }
        // }).catch(err => {
        //     alert(err)
        // })

       
        
        topPremiumFeaturedCompanyList().then(res => {
            if (res.status) {
                this.setState({ FEATURED_COMPANIES: res.result.featured, PREMIUM_COMPANIES: res.result.premium })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })


        topCompanyImages().then(res => {
            if (res.status) {
                this.setState({ TOP_COMPANY_IMAGES: res.result.images })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

   

    getPremiumCityList = () => {
        premiumCityList(getStorage(constant.keys.region)?getStorage(constant.keys.region):this.props.regionSelect.REGION_ID).then(res => {
            if (res.status) {
                this.setState({ LOCATION_LIST: res.result })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const { LOCATION_LIST,City, JOB_COUNT_BY_TOP_CATEGORY, FEATURED_COMPANIES, PREMIUM_COMPANIES, TOP_COMPANY_IMAGES,regionlist} = this.state;
       // console.log(this.props.regionSelect,'hometabregionSelect');
        return (<React.Fragment>
            <Helmet>


<title > Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Mounttalent.com</title>
<meta name="HandheldFriendly" content="True" />

<meta name="description" content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Mounttalent.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@Mounttalent.com"} />
<link  href={"https://www.mounttalent.com/"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>

<meta property="og:site_name" content="Mount Talent Official Blog" />
<meta property="og:type" content="article" />
<meta property="og:title"content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Mounttalent.com"} />
<meta property="og:description" content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Mounttalent.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@Mounttalent.com"} />
<meta property="og:url" content= {"https://www.mounttalent.com/"}  />
<meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />


<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Mounttalent.com"} />
<meta name="twitter:description"  content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Mounttalent.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@Mounttalent.com"} />
<meta name="twitter:url"content= {"https://www.mounttalent.com/"} />
<meta name="twitter:image"  content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@mounttalent_india" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />
</Helmet>
            <div id="parentdiv">
                {/* {LOCATION_LIST === null || JOB_COUNT_BY_TOP_CATEGORY === null || FEATURED_COMPANIES === null
                    || PREMIUM_COMPANIES === null || TOP_COMPANY_IMAGES === null ||
                    <HometabsWithShimmer
                        JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                        LOCATION_LIST={LOCATION_LIST}
                        FEATURED_COMPANIES={FEATURED_COMPANIES}
                        PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                        TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                    />} */}
                {
                    // LOCATION_LIST && JOB_COUNT_BY_TOP_CATEGORY && FEATURED_COMPANIES && PREMIUM_COMPANIES && TOP_COMPANY_IMAGES &&

                    <Suspense fallback={<>  <HometabsWithShimmer
                       // City={City}
                       regionSelect={this.props.regionSelect}
                       update={this.props.update}
                        JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                        LOCATION_LIST={LOCATION_LIST}
                        FEATURED_COMPANIES={FEATURED_COMPANIES}
                        PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                        TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                    /></>}>
                        <Hometabs
                            history={this.props.history}
                            regionSelect={this.props.regionSelect}
                            update={this.props.update}
                            // JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                            // LOCATION_LIST={LOCATION_LIST}
                            FEATURED_COMPANIES={FEATURED_COMPANIES}
                            PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                            TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                            //City={City}
                        // getPremiumCityList={this.getPremiumCityList}
                        />
                    </Suspense>

                }
            </div>
        </React.Fragment>

        )
    }
}
