import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'

export default class ContractualStaffing extends Component {
    render() {
        return (

            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-contractual-hiring-bg rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>Contractual Hiring</h4>
                                        <p>Flexible Hiring Global Talents for Your Dream Workforce</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry />
                <main id="rg-main" className="rg-main rg-haslayout pt-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Distinguishing Expertise</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box color-one'>
                                                    <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                                    <h4>Cost-Effectiveness</h4>
                                                    <p>Leverage flexible hiring at global competitive rates with no additional cost for new employee training</p>
                                                </div>
                                                <div className='usp-item-box color-two'>
                                                    <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                                    <h4>Flexible Professional Hiring</h4>
                                                    <p>Compatible candidates for on-demand expertise skills</p>
                                                </div>
                                                <div className='usp-item-box color-three'>
                                                    <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                                    <h4>Stable Team Management</h4>
                                                    <p>Low turnover rates of experienced talents to accelerate your project's outcome</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='./assets/images/staff-augmentation2.jpg' className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-4  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Offered Services</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p className='fa-18'>With our distinguished reputation of reliable <b>staffing solution providers,</b> we find skilled talents to boost your business productivity, growth, and development.</p>
                                            <ul className='list-offer'>
                                                <li>Recruiting an experienced talent</li>
                                                <li>Hiring specialists for your project needs</li>
                                                <li>Attracting the compatible experts</li>
                                                <li>Delivering the required skills to fulfil your business needs</li>
                                                <li>Screening, identifying, and recruiting the right candidate with the perfect blend of experience, skills, and required expertise.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15'>
                                            <div className='title-header2'>
                                                <h4 className='title'>Benefits and needs of staff augmentation services</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Time-Efficiency</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Staff augmentation reduces the stress of meeting the deadlines of delivering qualified talents. It opens the gate to a larger pool of experienced and qualified experts in specific areas</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Ace Selection of An Expert</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>With the augmented staffing services, you can focus on core business needs and deliver more projects within the defined deadlines</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Scalability</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>The staff augmentation process is a reliable, scalable, and easy way to enhance your chances of hiring experts. The services bring top-notch solutions to your every need of a specialised workforce without any long-term commitment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6 offset-2'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-four'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Cost-Efficient</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>With our strategic staff augmented process, you only pay for the services you need. We cut short the lengthy complications of traditional hiring methods to provide you with a team of experts to increase your consistent workflow.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-five'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Continuous Support</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>On-going resource flow with transparent communication with the resource to enjoy uninterrupted control and support for the development projects</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix secrtion-bg-gray'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15'>
                                            <div className='title-header'>
                                                <h4 className='title'>Why choose mount talent for staff augmentation services?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Years of experience</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Mount Talent leads its way with its extensive industry-oriented experience to deliver qualified, professional, and highly-trained professionals anytime. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Dedicated Professional Team</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We have a team of experienced and expert recruiters and HR professionals to provide you with the best talents you deserve.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Ease of Scalability</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Whether for your one-time project need or a continuous need, Mount Talent will leave no stone unturned to walk that extra mile for you. With our passion and determination, we are the leading staffing solution provider for a compatible companionship to deliver the workforce you need</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6 offset-2'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Affordable Service Plans</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>You won't be disappointed with our affordable staff augmentation services. We stand distinguished with our quick turnaround time and 100% assured satisfaction</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>24*7 Support</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We offer 24*7 availability to provide you the support and assistance you need. We work on all the requirements very passionately to deliver on-time solutions without any hassles</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='../assets/images/faqs2.jpg' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>
                                                        What is staff augmentation?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Staff augmentation is outsourcing <b>staffing services</b> to recruit compatible talent for a specific project to fulfill the business objectives. It evaluates the existing staff and determines the additional skills required.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>
                                                        Why do you need staff augmentation?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>The main requirement of staff augmentation is to add flexibility to the existing workforce. The process ensures fulfilling the staffing needs for short-term projects. It is also time-saving and cost-effective.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>
                                                        What is the difference between staff augmentation and managed services?
                                                    </label>
                                                    <div className='answers'>
                                                        <p><b>Staff augmentation services</b> focus on outsourcing specific tasks, functions, and needs of the organization. On the other hand, managed services focus on outsourcing the entire problem and its solutions.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question4' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question4' className='question'>
                                                        Is staff augmentation important?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Yes. It ensures better control to the organizations on their projects by recruiting a temporary yet best team of talents. It can assure timely delivery of projects without burdening the existing workforce.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question5' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question5' className='question'>
                                                        Who uses staff augmentation?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Staff augmentation services are mainly used by organizations where in-house specialists and experts are unavailable for certain projects. Coming from a reliable <b>staffing solution provider</b>, staff augmentation fulfills the need quickly.</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <input type='checkbox' id='question6' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question6' className='question'>
                                                        What is staffing?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Staffing is the process of recruiting the right candidate with the desired right skills, qualifications, and experience for a specific job role and position. It evaluates their knowledge before offering them the role.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>


            </React.Fragment>
        )
    }
}
