import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { capFirstLetterInSentence } from '../../utils'
import ContractHiring from '../../components/EmployerServices/ContractHiring'
export default class ContractHirings extends Component {
  render() {
    return (
        <React.Fragment>
        <Helmet >
            <title title={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " | Mounttalent.com"}>{capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " | Mounttalent.com"}</title>
            <meta name="HandheldFriendly" content="True" />
            <meta name="Keywords" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
            <meta name="description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <link rel="canonical" href={window.location.href} />
            <meta name="referrer" content="no-referrer-when-downgrade" />

            <meta property="og:site_name" content="Mounttalent.com" />
            <meta property="og:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
            <meta property="og:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
            <meta property="og:image:width" content="4000" />
            <meta property="og:image:height" content="6000" /><br />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
            <meta name="twitter:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <meta name="twitter:url" content={window.location.href} />
            <meta name="twitter:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
            <meta name="twitter:label1" content="Written by" />
            <meta name="twitter:data1" content="Smita Nag" />
            <meta name="twitter:label2" content="Filed under" />
            <meta name="twitter:data2" content="Career Advice, Career Insights" />
            <meta name="twitter:site" content="@mounttalent_india" />
        </Helmet>
        <ContractHiring/>
</React.Fragment>
    )
  }
}
