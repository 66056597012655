import React, { Component } from 'react'
import ManagedHrServices from '../../components/HrTechConsulting/ManagedHrServices'
import constant from '../../constant'
export default class ManagedHrServicess extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

    }
    componentDidMount(){
        document.title = constant.title.ManagedHrServices
    }
  render() {
    return (
      <React.Fragment>
        <ManagedHrServices/>
      </React.Fragment>
    )
  }
}
