import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class ContractHiring extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-full-time-hiring-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Contract Hiring</h4>
                                <p>Talented Part-Timers To Make An Excellent Workforce</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">

                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Our Skills</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <div className='usp-main-item'>
                                        <div className='usp-item-box color-one'>
                                            <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                            <h4>Cost-Effecticious</h4>
                                            <p>We hire the most talented contract staff for you with zero employee training and reasonable hiring rates.</p>
                                        </div>
                                        <div className='usp-item-box color-two'>
                                            <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                            <h4>Flexible Hiring Methods</h4>
                                            <p>MTC presents the most constituent employees with perfect talent and the right skills in every domain and for every company.</p>
                                        </div>
                                        <div className='usp-item-box color-three'>
                                            <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                            <h4>High-Quality Team Management</h4>
                                            <p>Our services offer decreased turnover rates in order to boost project productivity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>Our Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>Mount Talent Consulting is a leading integrated employment platform in the market with a strong emphasis on finding and delivering the best talent. Our candidates not only give you the best performance but also promote your business productivity and expansion.</p>
                                    <ul className='list-offer'>
                                        <li>Sourcing and hiring the most effective talent according to your business needs.</li>
                                        <li>Retaining the best and most talented employees.</li>
                                        <li>Sourcing, screening, selecting and hiring the best talent with the right skills, manners and work ethics.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Why Would We Need Contract Hiring?</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Time And Money Saving</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Finding a full-time candidate is going to be stressful, but contract staffing takes care of that. By shortening the lengthy and complicated process of the traditional hiring system with our individualised technology, we also provide you with a team of experienced professionals for your consistent workflow.1</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Selection Criteria</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>With the assistance of contract staffing, a company can complete the project within the allotted time frames and concentrate on business-centric requirements.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Ascendible</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Without requiring a long-term commitment, this service provides high-quality solutions to the day-to-day requirements of a specialized workforce.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Constant Support</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Maintaining continuous command and support for development projects is made possible by open communication and continuous resource flow.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix secrtion-bg-gray fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Why Choose Mount Talent Consulting For Contract Hiring?</h4>
                                    </div>
                                    <div className='feature-line'>
                                        <span className='animate-bar'></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Industry Experience</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>MTC is the industry leader because of its market-oriented experience in providing skilled, trained, and experienced working professionals.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Industry Experts</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We have the best most experienced HR professional catering to all your hiring and staffing needs, helping your business to grow.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Affordable Service</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our cost-effective contract staffing services won't let you down, and you won't believe it. We stand out thanks to our quick turnaround time and 100 per cent satisfaction guarantee.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Global Assistance</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We not only bring companies to the world but also bring our assistance to the companies no matter when or where. 24*7 we are live to tackle all your problems.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='../assets/images/faqs2.jpg' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>FAQs</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <div className='faqscontent'>
                                        <div>
                                            <input type='checkbox' id='question1' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question1' className='question'>
                                                What do you understand by contract hiring?
                                            </label>
                                            <div className='answers'>
                                                <p>Outsourcing candidates for a limited period to an organisation to deliver the projects and assignments within the given deadline is contract hiring.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question2' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question2' className='question'>
                                                What is the need for contract hiring?
                                            </label>
                                            <div class="answers">
                                                <p>To increase the team's adaptability, contract hiring is required. The procedure ensures that all short-term hiring requirements are met. It saves money and time.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question3' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question3' className='question'>
                                                Why Contract Hiring is important?
                                            </label>
                                            <div class="answers">
                                                <p>By employing a temporary but highly skilled team, contract hiring gives a company greater project control. It ensures the timely completion of projects without putting undue strain on the current full-time workforce.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question4' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question4' className='question'>
                                            What is Hiring?
                                            </label>
                                            <div class="answers">
                                                <p>Hiring is a process of hiring the best candidate with the right skills, experience and qualifications to carry out business operations, needs and functions.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question5' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question5' className='question'>
                                                What is the difference between direct hire and agency?
                                            </label>
                                            <div class="answers">
                                                <p>In direct hire, there isn't any role of the 3rd party for finding the qualified candidate for the job position. Hiring through an agency involves the consistent efforts of reputed staffing agencies like Mount Talent Consulting to get <b>full time hiring services in India.</b>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </React.Fragment>
    )
  }
}
