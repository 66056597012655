import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class LearningManagementSystem extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-lms-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Learning Management System</h4>
                                <p>Best LMS Solutions Covering Learning Needs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Learning Management System</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Web-based e-learning and management software of the highest quality is our LMS. We effectively manage the institution's training<br/>essentials and are an adequate provider of learning management systems.</p>
                                        <p className='font-18'>We cater to the requirements of both individuals and businesses when it comes to online education by using<br/>our potent LMS (learning management system) software.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='usp-top-box'>
                                <div className='main-head-box'>
                                    <h3>Our Skills</h3>
                                    <div className='feature-line'>
                                        <span className='animate-bar'></span>
                                    </div>
                                </div>
                                <div className='usp-main-item'>
                                    <div className='usp-item-box color-one'>
                                        <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                        <h4>We provide expert faculty panels to provide the best learning needs.</h4>
                                    </div>
                                    <div className='usp-item-box color-two'>
                                        <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                        <h4>Our LMS is very customisable making it a perfect match for your schedule.</h4>
                                    </div>
                                    <div className='usp-item-box color-three'>
                                        <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                        <h4>MTC have an interoperable e-learning software service.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>Our Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>MTC, the best provider of learning management system services, provides robust, fully customisable, mobile-friendly, and simple-to-integrate LMS.</p>
                                    <ul className='list-offer'>
                                        <li>We first analyse companies' requirements and provide solutions and services as per the needs. </li>
                                        <li>Maintain the application, create courses, facilitate efficient course structure, assess learner performance, manage user-friendly knowledge dissemination, etc.</li>
                                        <li>Maintain a single login for all courses, effectively communicate with instructors about course design and required LMS tools, and emailed batchmates for assistance.</li>
                                        <li>Our LMS truly combines online and other learning modules led by instructors. We use a strong and safe password-protected automated procedure to enhance results with reduced training.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>What Will Be The Need For Learning Management System?</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Cost-Effecticious</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Through LMS you can cut down various costs like instructor’s travelling, printing learning materials, time management, and so much more. Also, our software is pretty affordable to develop learning skills and personality development.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Fast Learning</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>You can save a lot of time learning by quickly accessing the necessary information in an organized, flexible, and clear way. In a short amount of time, you can learn everything you need to know.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Time Management</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our best learning management system makes it simple to record learners' performance milestones and monitor their progress. Provide additional resources, including regular performance evaluations and reports, to guarantee their progress.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Creative Content</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Instructors and trainers can use videos, texts, audio, and images from a variety of sources to create interactive and engaging online courses.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
