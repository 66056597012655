import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { courseList } from '../../action/CandidateAction'
import constant from '../../constant'
import logo from './../../assets/images/logo.png'
import { getGlobalSetting } from "../../action/dashboard";
import { regionList, selectCountry, selectRegion } from '../../action/jobsByActions'
import { getStorage, onChange, removeStorage, setStorage } from '../../utils'



export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: undefined,
            MasterData: undefined,
            regionlist: '',
            regionSelect:'',
            CountrySel:'',
            Region:{value:getStorage(constant.keys.region)?getStorage(constant.keys.region):'',name:'Region',error: '',isRequired: true,options: []}
        }
    }
    componentDidMount() {
        this.getselectCountry()
        courseList().then((res) => {
            if (res.status) {
                this.setState({ data: res.result })
            }
        })

        regionList().then(res => {
            if (res.status) {
                this.setState({ regionlist: res.result.list })
            }
            else {
                console.log(res.error)
            }
        })

        getGlobalSetting().then(res => {
            if (res.status) {
                this.setState({ MasterData: res.result })
            }
            else {
                console.log(res.error)
            }
        })


    }    
    
   
    getselectCountry = () => {
        selectCountry().then((res) => {
                this.setState({
                    CountrySel: res.country_name
                })
                this.getselectRegion( res.country_name)
        })
    }

    getselectRegion = (name) => {
        selectRegion(name ).then((res) => {
           // console.log(res.result,"HeaderResult");
            if (res.status) {
                this.setState({
                    regionSelect: res.result.list
                })
            }
        });
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.props.HeaderUpdate(value)
        removeStorage(constant.keys.region)
        setStorage(constant.keys.region,value);
        onChange(this, name, value)
    }
    render() {
        const { Region,regionlist,regionSelect} = this.state;
        console.log();
      
        return (
            <React.Fragment>
                
                <header id="rg-header" className="rg-header rg-haslayout">
                    <div className="rg-navigationlogoarea">
                        <div className='top-menu top-menu-new'>
                            <div className='container'>
                                <div className='col-md-12 text-right pr-0'>
                                    <ul>
                                        <li><i className='lnr lnr-phone-handset'></i> +6590554495</li>
                                        <li><i className='lnr lnr-envelope'></i> sales@mounttalent.com</li>
                                        <li>
                                            <label className="jui-dropdown">
                                                <span className="jui-dropdown__caret"></span>
                                               
                                                        <select className="jui-dropdown__select"
                                                        value={Region.value?Region.value:regionSelect.REGION_ID }
                                                        name={Region.name}
                                                        onChange={this.onChange}
                                                        >
                                                        {regionlist.length>0&&regionlist.map((item) => {
                                                         return (
                                                            <option value={item.REGION_ID} >{item.REGION}</option>
                                                            )
                                                          })}
                                                        </select>
                                                  
                                            </label>
                                        </li>
                                        <li className='socialmediaicon'>
                                            <a target='_blank' href={'https://twitter.com/mount_talent'}><i className='fa fa-twitter'></i></a>
                                            <a target='_blank' href={'https://www.facebook.com/MountTalentOfficial'}><i className='fa fa-facebook'></i></a>
                                            <a target='_blank' href={'https://www.youtube.com/@mounttalentofficial'}><i className='fa fa-youtube'></i></a>
                                            <a target='_blank' href={'https://www.linkedin.com/company/407445/admin/'}><i className='fa fa-linkedin'></i></a>
                                        </li>
                                        <li><Link target='_blank' to={constant.component.RequestCallback.url} className='dropyourcv'>Request a Callback</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <strong className="rg-logo"><Link to="/"><img src={logo} alt="mounttalent.com" title={constant.build.version} /></Link></strong>
                                    <div className="rg-rightarea">
                                        <nav id="rg-nav" className="rg-nav navbar-expand-lg navbar-toggleable-sm">
                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                                <i className="lnr lnr-menu"></i>
                                            </button>
                                            <div className="collapse navbar-collapse rg-navigation" id="navbarNav">
                                                <ul>
                                                    <li className="menu-item-has-children page_item_has_children">
                                                        <Link to={''}>Jobs</Link>

                                                        <ul className="sub-menu men04">
                                                            <div className='width25'>
                                                                <li className='meneiconbox'><h5>Explore jobs</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsBySkill.url}>Jobs by skill</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsByDesignation.url}>Jobs by designation</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsByCompany.url}>Jobs by company</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsByCategory.url}>Jobs by category</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsByLocation.url}>Jobs by location</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.CreateJobAlert.url}>Create a Free Job Alert</Link>
                                                                </li>
                                                            </div>
                                                            <div className='width25'>
                                                                <li className='meneiconbox'><h5>Jobs in demand</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.latestfresherjob.url}>Fresher jobs</Link>
                                                                </li>
                                                                {/* <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.}>MNC jobs</Link>
                                                                </li> */}
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.RemoteJobs.url}>Remote jobs</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.WorkFromHomeJobs.url}>Work from home jobs</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.WalkInJobs.url}>Walk-in jobs</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.PartTimeJobs.url}>Part-time jobs</Link>
                                                                </li>
                                                            </div>
                                                            <div className='width25'>
                                                                <li className='meneiconbox'><h5>Video JDs</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.MostPopularVideoJdJob.url}>Most Popular</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.HotSectorVideoJdJob.url}>Hot Sectors</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.MostLikedVideoJdJob.url}>Most Liked</Link>
                                                                </li>
                                                            </div>
                                                            <div className='width25'>
                                                                <li className='meneiconbox'><h5>Freshers jobs</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.latestfresherjob.url}>Latest fresher jobs</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <a target='_blank' href={'https://campus.rozgar.com'}>Students</a>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <a target='_blank' href={'https://campus.rozgar.com'}>Institution</a>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.jobsByCompany.url}>Hiring Company</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <a target='_blank' href={'https://campus.rozgar.com'}>Internships</a>
                                                                </li>
                                                            </div>
                                                        </ul>

                                                    </li>
                                                    <li className="menu-item-has-children page_item_has_children">
                                                        <Link to={''}>Companies</Link>
                                                        <ul className="sub-menu men01">
                                                            <div className='width50'>
                                                                <li className='meneiconbox'><h5>Services</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.fulltimehiring.url}>Full Time Hiring's</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.ContractHiring.url}>Contract Hiring</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.RemoteResources.url}>Remote Resource</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.FreelanceResources.url}>Freelance Resources</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.RecruitmentProcessOutsourcing.url}>Recruitment Process Outsourcing</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <a target='_blank' href={'https://campus.rozgar.com'}>Campus Placement</a>
                                                                </li>
                                                            </div>
                                                            <div className='width50'>
                                                                <li className='meneiconbox'><h5>Branding</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.CreateMicrosites.url}>Create Microsites</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.PremiumJds.url}>Premium JD’s</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <a target='_blank' href='https://recruit.mounttalentonline.com'>Search Candidates</a>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.companieslist.url}>View All Companies</Link>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                    </li>

                                                    <li className="menu-item-has-children page_item_has_children">
                                                        <Link to={''}>Resources</Link>
                                                        <ul className="sub-menu men02">
                                                            <div className='width50'>
                                                                <li className='meneiconbox'><h5>Software</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.CustomerRelationshipManagement.url}>Customer Relationship Management (CRM)</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.hrmanagementsystem.url}>HRMS</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.ApplicationTrackingSystem.url}>Applicant Tracking System (ATS)</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.LearningManagementSystem.url}>Learning Management System (LMS)</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.ManagedHrServices.url}>Management Services</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.CustomSolutions.url}>Custom Solutions</Link>
                                                                </li>
                                                            </div>
                                                            <div className='width50'>
                                                                <li className='meneiconbox'><h5>Learning</h5></li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.ResumeMaking.url}>Resume Making</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.MockInterview.url}>Mock Interview</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.TechnicalCourses.url}>Tech Courses</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.ManagementCourses.url}>Management Cources</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.LeadershipCourses.url}>Leadership Courses</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.AstrologyHoroscopeGuidance.url}>Horoscope Based Guidance</Link>
                                                                </li>
                                                                <li className='meneiconbox'>
                                                                    <Link target='_blank' to={constant.component.interviewQuestion.url}>Interview Question</Link>
                                                                </li>
                                                            </div>
                                                        </ul>

                                                    </li>

                                                    {/* <li className="menu-item-has-children page_item_has_children">
                                                        <Link to={''}>Discover us</Link>
                                                        <ul className="sub-menu men04">
                                                            <li className='meneiconbox'>
                                                                <Link to={constant.component.aboutUs.url}>About Us</Link>
                                                            </li>
                                                            <li className='meneiconbox'>
                                                                <Link target='_blank' to={constant.component.OurUsp.url}>Our USP</Link>
                                                            </li>
                                                            <li className='meneiconbox'>
                                                                <Link target='_blank' to={constant.component.CaseStudies.url}>Case Studies</Link>
                                                            </li>
                                                            <li className='meneiconbox'>
                                                                <Link target='_blank' to={constant.component.Clients.url}>Clients</Link>
                                                            </li>
                                                            <li className='meneiconbox'>
                                                                <Link target='_blank' to={constant.component.Products.url}>Products</Link>
                                                            </li>
                                                            <li className='meneiconbox'>
                                                                <Link target='_blank' to={constant.component.ContactUs.url}>Contact us</Link>
                                                            </li>
                                                        </ul>
                                                    </li> */}

                                                    <li class="menu-item-has-children mobhidearrow">
                                                        <a target='_blank' href={'https://recruit.mounttalentonline.com/job-post'}>
                                                            <strong style={{color:'rgb(255 38 39)'}}>Post a job </strong>
                                                            </a>
                                                    </li>

                                                    <li className="menu-item-has-children loginpd">
                                                        <div className='loginhome'>
                                                            <Link to={constant.component.register.url}>Sign Up / Sign In</Link>
                                                        </div>
                                                    </li>
                                                    <li className="menu-item-has-children employezone p-0">
                                                        <a href="javascript:void(0);" className="rg-menubar-ez"><i className="lnr lnr-apartment"></i> Employer Zone <i className="lnr lnr-chevron-right"></i></a>
                                                        <ul className="sub-menu men0em">
                                                            <li><a href={'https://recruit.mounttalentonline.com/subscriptions'} target='_blank'>Buy online</a></li>
                                                            <li><a href={'https://recruit.mounttalentonline.com/job-post'} target='_blank'>Post a Job</a></li>
                                                            <li className="menu-item-has-children">
                                                                <a target='_blank' href={'https://recruit.mounttalentonline.com/'}>Employer Login</a>
                                                            </li>
                                                            <li><a href={'https://recruit.mounttalentonline.com/SalesEnquiry'} target='_blank'>Sales Enquiry</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}
