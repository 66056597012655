import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class FreelanceResources extends Component {
  render() {
    return (
      <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-remote-resources-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Freelance Resource</h4>
                                <p>Best Freelance Candidates For Workforce</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Freelance Resource</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Freelancing is one of the important aspects that keeps an organisation up and running. The majority of small-scale startups use freelance employees, but it is not just confined to small-scale companies. Many large firms also freelance employees for many of their projects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-12'>
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>What Is The Need For Freelance Resources?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Freelance workers are completely different from full-time or permanent employees. They work when certain projects and deadlines<br/>are required to complete. A freelance resource is a very important aspect of the employment industry as it gives the<br/>employer a chance to complete the task without hiring further employees.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Key Features Of Freelance Resource</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Hire Eligible Candidates</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We provide you with the best of the best employee. Even if they work as a freelancer, they will be ethical and dedicated and will deliver your project smoothly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Geography Assessment</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Freelancers can be from across the globe. The best way to stay at the top of the global market is by appointing top workers from the global market. There are a major number of freelance workers experienced and dedicated that will give their best to you and your company, and we find that for you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Low Hiring Cost</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Forget the expensive process of full-time hiring, freelance resource of our delivers you the best candidate at a reasonable price, so you will get the work done without spending an extra cost.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Reduced Hiring Time</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Finding the perfect candidate, be it full-time or part-time, can be a daunting task and not to mention a major time consumer. Not with us. With our Freelance resources, we provide you with the best market-experienced freelancers in all domains in a quick time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>End-To-End Responsibility</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Leave all processes to us, from screening to hiring to onboarding to payroll our team of professionals will take care of everything. You will get your work done and we will take care of all other employee-related responsibilities. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
