import React, { Component } from 'react'
import LeadershipCourses from '../../components/LearningHub/LeadershipCourses'
import constant from '../../constant'
export default class LeadershipCoursess extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.LeadershipCourses
    }
  render() {
    return (
      <React.Fragment>
        <LeadershipCourses/>
      </React.Fragment>
    )
  }
}
