import React, { Component } from 'react'
import JobsBySkill from '../../components/jobsBySkill/jobsBySkill'
import constant from '../../constant'
import { ITSkillList, NonITSkillList } from '../../action/jobsByActions'
import { capFirstLetterInSentence } from '../../utils';
import { Helmet } from 'react-helmet';
export default class jobsBySkill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IT_SKILL_LIST: [],
      NON_IT_SKILL_LIST: [],
      IT_SKILL_LIST_COUNT: undefined,
      NON_IT_SKILL_LIST_COUNT: undefined,

    }
  }
  componentDidMount() {

    document.title = constant.title.JobsBySkill
    window.scrollTo(0, 0)
    ITSkillList().then(res => {
      if (res.status) {
        this.setState({ IT_SKILL_LIST: res.result.list, IT_SKILL_LIST_COUNT: res.result.count })
      }
      else {
        alert(res.error)
      }
    }).catch(err => {
      console.log(err)
    })


    NonITSkillList().then(res => {
      if (res.status) {
        this.setState({ NON_IT_SKILL_LIST: res.result.list, NON_IT_SKILL_LIST_COUNT: res.result.count })
      }
      else {
        alert(res.error)
      }
    }).catch(err => {
      console.log(err)
    })



  }
  render() {
    const { IT_SKILL_LIST, IT_SKILL_LIST_COUNT, NON_IT_SKILL_LIST, NON_IT_SKILL_LIST_COUNT } = this.state;
    return (
      <React.Fragment>
          <Helmet >


{/* <title title={capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}>{ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}</title> */}
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<link rel="canonical"  href={window.location.href} />
<meta name="referrer" content="no-referrer-when-downgrade" />

<meta property="og:site_name" content="Mounttalent.com" />
<meta property="og:title"content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))} />
<meta property="og:description" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<meta property="og:url" content= {window.location.href}  />
<meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" /><br/>

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))} />
<meta name="twitter:description"  content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<meta name="twitter:url"content= {window.location.href} />
<meta name="twitter:image"  content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@mounttalent_india" />
</Helmet>
        {IT_SKILL_LIST.length && NON_IT_SKILL_LIST.length 
        ?
          <JobsBySkill
            IT_SKILL_LIST={IT_SKILL_LIST}
            IT_SKILL_LIST_COUNT={IT_SKILL_LIST_COUNT}
            NON_IT_SKILL_LIST={NON_IT_SKILL_LIST}
            NON_IT_SKILL_LIST_COUNT={NON_IT_SKILL_LIST_COUNT}
          />
          :
          null
        }</React.Fragment>
    )
  }
}
