import React, { useEffect, useState } from "react";
import { getStorage } from "./utils";
import { Route, Switch } from "react-router-dom";
import Footer from "./components/common/Footer";
import Header from "../src/components/common/Header";
import constant from "./constant";
import Homepage from "./pages/home/homepage";
import MobileSearch from "./components/home/MobileSearch"
import Joblist from "./pages/joblist/joblist";
import Jobdetails from "./pages/jobdetail/jobdetails";
import signin from "./pages/signin/signin";
import register from "./pages/register/register";
import companieslist from "./pages/companies/companieslist";
import Companydetails from "./pages/companies/companydetails";
import jobbycity from "./pages/jobbycity/jobbycity";
import CityOverview from "./pages/jobbycity/CityOverview";
import faqs from "./pages/faqs/faqs";
import faqDetail from "./pages/faqs/faqDetail";
import faqCategory from "./pages/faqs/faqCategory";
import jobsByLocation from "./pages/jobsByLocation/jobsByLocation";
import jobsByCompany from "./pages/jobsByCompany/jobsByCompany";
import jobsByCategory from "./pages/jobsByCategory/jobsByCategory";
import jobsByDesignation from "./pages/jobsByDesignation/jobsByDesignation";
import jobsBySkill from "./pages/jobsBySkill/jobsBySkill";
import aboutUs from "./pages/aboutUs/aboutUs";
import privacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import termsConditions from "./pages/termsConditions/termsConditions";
import forgotPassword from "./pages/forgotPassword/forgotPassword";
import EmployeeHeader from "../src/components/common/EmployeeHeader";
import Logout from "./components/common/SignOut";
import JobsByDashboard from "./pages/jobsBy/dashboard";
import { Layout } from "./Layout";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRouteMatch } from "react-router-dom";
import blog from "./pages/blog/blog";
import blogDetails from "./components/blog/blogDetails";
import JobSearchIndia from "./pages/JobsearchIndia/Jobsearchindia";
// import blogDetails from './pages/blog/blogDetails'
// import BLogCategoryDetail from './components/blog/BLogCategoryDetail'
import BlogListByCategory from "./pages/blog/blogListByCategory";
import CreateJobAlert from "./pages/CreateFreeJobAlert/CreateJobAlert";
// import profile from './pages/myAccount/profile'
import EditProfile from "./pages/myAccount/editProfile";
import SavedJobs from "./components/myAccount/SavedJobs";
import RecommendedJobs from "./components/myAccount/RecommendedJobs";
import ProfilePerformance from "./pages/myAccount/ProfilePerformance";
import InboxMessage from "./components/myAccount/InboxMessage";
import ApplicationStatus from "./components/myAccount/ApplicationStatus";
import ProfilePreview from "./components/myAccount/ProfilePreview";
import ResetPassword from "./pages/forgotPassword/resetPassword";
import BlogListBySearch from "./pages/blog/BlogListBySearch";
import AllRecommendedJobs from "./pages/myAccount/AllRecommendedJobs";
import myRozgar from "./pages/myAccount/profile";
import reportIssue from "./pages/reportIssue/reportIssue";
import JobListBySearch from "./pages/joblist/jobListBySearch";
import Enquiry from "./pages/Enquiry/Enquiry";
import profilePicture from "./components/myAccount/profilePicture";
import PersonalRecruiter from "./pages/PersonalRecruiter/PersonalRecruiter";
import TopCompanyList from "./pages/companies/topcompanies";
import LatestFresherJoblist from "./pages/joblist/latestfresherjobs";
import error from "./pages/Error/error";
import ByCompany from "./pages/InterviewQuestions/ByCompany";
import BySkills from "./pages/InterviewQuestions/BySkills";
import ShareAnInterview from "./pages/InterviewQuestions/ShareAnInterview";
import AddAnswer from "./pages/InterviewQuestions/AddAnswer";
import AllJobs from "./pages/AllJobs/AllJobs";
import courseDetail from "./pages/LearningHub/courseDetail";
import GovermentJobList from "./pages/joblist/govermentJobs";
import InternationalJobList from "./pages/joblist/internationalJobs";

import Fulltimehiring from "./pages/EmployerServices/Fulltimehiring";
import ContractualStaffingCopy from "./pages/EmployerServices/Contractualstaffing";
import FeaturedJobs from "./components/myAccount/FeaturedJobs";
import PremiumJobs from "./components/myAccount/PremiumJobs";
import EmailVerificationScreen from "./components/common/EmailVerificationScreen";
import HRMS from "./pages/EmployerServices/Hrmanagementsystem";
import Marketingtechnologysolution from "./pages/EmployerServices/Marketingtechnologysolution";
import Payrollautomation from "./pages/EmployerServices/Payrollautomation";
import Startupincubation from "./pages/EmployerServices/Startupincubation";

import InterviewQuestion from "./components/InterviewQues/InterviewQuestion";

import DiscussionForum from './pages/DiscussionForum/DiscussionForum'
import buyCourse from './pages/LearningHub/buyCourse'
import ThankYou from './pages/LearningHub/thankYou'
import studyAbroad from './pages/StudyAbroad/studyAbroad'
import AdmissionAssistance from './pages/StudyAbroad/AdmissionAssistance'
import StudyAbroadScholarship from './pages/StudyAbroad/StudyAbroadScholarship'
import TestPreparation from './pages/StudyAbroad/TestPreparation'
import travelGuidance from './pages/StudyAbroad/TravelGuidance'
import VisaApplicationAssistance from './pages/StudyAbroad/VisaApplicationAssistance'
import CoursesAdviceGuidance from './pages/StudyAbroad/CoursesAdviceGuidance'
import studyAbroadCounselling from './pages/StudyAbroad/StudyAbroadCounselling'
import VisaCoverLetter from './pages/StudyAbroad/VisaCoverLetter'
import SopWritingServices from './pages/StudyAbroad/SopWritingServices'
import LorWritingServices from './pages/StudyAbroad/LorWritingServices'
import ResumeWritingServices from './pages/StudyAbroad/ResumeWritingServices'
import StudyInAustralia from './pages/StudyAbroad/StudyInAustralia'
import StudyInCanada from './pages/StudyAbroad/StudyInCanada'
import StudyInUk from './pages/StudyAbroad/StudyInUk'
import StudyInUsa from './pages/StudyAbroad/StudyInUsa'
import StudyInItaly from './pages/StudyAbroad/StudyInItaly'
import StudyInIreland from './pages/StudyAbroad/StudyInIreland'
import StudyInNewZealand from './pages/StudyAbroad/StudyInNewZealand'
import StudyInSingapore from './pages/StudyAbroad/StudyInSingapore'
import CompanyAnswer from './pages/InterviewQuestions/CompanyAnswer'
import ByDesignation from './pages/InterviewQuestions/ByDesignation'
import DesignationAnswer from './pages/InterviewQuestions/DesignationAnswer'
import InternationalWorkVisas from './pages/InternationalWorkVisas/InternationalWorkVisas'
import ResumeMaking from './pages/ResumeMaking/ResumeMaking'
import UpgradeSkills from './components/EmployerServices/UpgradeSkills'
import TemplatePreview from './pages/ResumeMaking/TemplatePreview'
import TemplatePreview01 from './pages/ResumeMaking/TemplatePreview01'
import TemplatePreview02 from './pages/ResumeMaking/TemplatePreview02'
import TemplatePreview04 from './pages/ResumeMaking/TemplatePreview04'
import TemplatePreview05 from './pages/ResumeMaking/TemplatePreview05'
import TemplatePreview06 from "./pages/ResumeMaking/TemplatePreview06";
import TemplatePreview07 from "./pages/ResumeMaking/TemplatePreview07";
import TemplateEdit from './components/ResumeMaking/TemplateEdit'
import TemplateEdit01 from './components/ResumeMaking/TemplateEdit01'
import TemplateEdit02 from './components/ResumeMaking/TemplateEdit02'
import CareerReport1Year from './components/common/CareerAstrology/CareerReport1Year'
// import DiscussionForum from "./pages/DiscussionForum/DiscussionForum";
import DiscussionForumCategory from "./pages/DiscussionForum/DiscussionForumCategory";
// import BuyCourse from "./pages/LearningHub/buyCourse";
// import buyCourse from "./pages/LearningHub/buyCourse";
// import ThankYou from "./pages/LearningHub/thankYou";
// import studyAbroad from "./pages/StudyAbroad/studyAbroad";
// import AdmissionAssistance from "./pages/StudyAbroad/AdmissionAssistance";
// import StudyAbroadScholarship from "./pages/StudyAbroad/StudyAbroadScholarship";
// import TestPreparation from "./pages/StudyAbroad/TestPreparation";
// import travelGuidance from "./pages/StudyAbroad/TravelGuidance";
// import VisaApplicationAssistance from "./pages/StudyAbroad/VisaApplicationAssistance";
// import CoursesAdviceGuidance from "./pages/StudyAbroad/CoursesAdviceGuidance";
// import studyAbroadCounselling from "./pages/StudyAbroad/StudyAbroadCounselling";
// import VisaCoverLetter from "./pages/StudyAbroad/VisaCoverLetter";
// import SopWritingServices from "./pages/StudyAbroad/SopWritingServices";
// import LorWritingServices from "./pages/StudyAbroad/LorWritingServices";
// import ResumeWritingServices from "./pages/StudyAbroad/ResumeWritingServices";
// import StudyInAustralia from "./pages/StudyAbroad/StudyInAustralia";
// import StudyInCanada from "./pages/StudyAbroad/StudyInCanada";
// import StudyInUk from "./pages/StudyAbroad/StudyInUk";
// import StudyInUsa from "./pages/StudyAbroad/StudyInUsa";
// import StudyInItaly from "./pages/StudyAbroad/StudyInItaly";
// import StudyInIreland from "./pages/StudyAbroad/StudyInIreland";
// import StudyInNewZealand from "./pages/StudyAbroad/StudyInNewZealand";
// import StudyInSingapore from "./pages/StudyAbroad/StudyInSingapore";
// import CompanyAnswer from "./pages/InterviewQuestions/CompanyAnswer";
// import ByDesignation from "./pages/InterviewQuestions/ByDesignation";
// import DesignationAnswer from "./pages/InterviewQuestions/DesignationAnswer";
// import InternationalWorkVisas from "./pages/InternationalWorkVisas/InternationalWorkVisas";
// import ResumeMaking from "./pages/ResumeMaking/ResumeMaking";
// import TemplatePreview from "./pages/ResumeMaking/TemplatePreview";
// import TemplatePreview01 from "./pages/ResumeMaking/TemplatePreview01";
// import TemplatePreview02 from "./pages/ResumeMaking/TemplatePreview02";
// import TemplateEdit from "./pages/ResumeMaking/TemplateEdit";
// import TemplateEdit01 from "./pages/ResumeMaking/TemplateEdit01";
// import TemplateEdit02 from "./pages/ResumeMaking/TemplateEdit02";
// import CareerReport1Year from "./components/common/CareerAstrology/CareerReport1Year";
import StudentsExplorer from './pages/StudentsExplorer/StudentsExplorer'
import careerExplorerDetail from './pages/StudentsExplorer/CareerExplorerDetail'
import DiscussionForumDetails from './pages/DiscussionForum/DiscussionForumDetails';
import ResumeViewOne from "./pages/ResumeMaking/ResumeViewOne/ResumeViewOne";
import ResumeViewTwo from "./pages/ResumeMaking/ResumeViewOne/ResumeViewTwo";
import ResumeViewThree from "./pages/ResumeMaking/ResumeViewOne/ResumeViewThree";
import ResumeTemplateView01 from "./pages/ResumeMaking/ResumeViewOne/ResumeTemplateView01";
import ResumeTemplateView02 from "./pages/ResumeMaking/ResumeViewOne/ResumeTemplateView02";
import ResumeTemplateView03 from "./pages/ResumeMaking/ResumeViewOne/ResumeTemplateView03";
import CareerAstrology from './pages/CareerAstrology/CareerAstrology';
import CompanyBrandings from "./pages/EmployerServices/CompanyBrandings";
import SponsoredJds from "./pages/EmployerServices/SponsoredJds";
import HrTechSolutions from "./pages/EmployerServices/HrTechSolutions";
import AnswerByQuestion from './components/InterviewQuestions/AnswerbyQuestion';

import RemedialSolutionForCareer from "./components/common/CareerAstrology/RemedialSolutionForCareer";
import StrengthReadingForCareer from "./components/common/CareerAstrology/StrengthReadingForCareer";
import CareerAsk1Question from "./components/common/CareerAstrology/CareerAsk1Question";
import CareerAsk3Question from "./components/common/CareerAstrology/CareerAsk3Question";
import CareerReport2Year from "./components/common/CareerAstrology/CareerReport2Year";
import CareerReport3Year from "./components/common/CareerAstrology/CareerReport3Year";
import CareerReport5Years from "./components/common/CareerAstrology/CareerReport5Years";
import ChangePassword from "./pages/forgotPassword/ChangePassword";
import FourZeroFour from "./pages/404/FourZeroFour";
import DiscussionFormSignIn from "./pages/DiscussionForum/DiscussionFormsignin";

// import AnswerByQuestion from './components/InterviewQuestions/AnswerbyQuestion'
// import CareerAstrology from './components/common/CareerAstrology/CareerAstrology'
// import DiscussionForumDetails from './pages/DiscussionForum/DiscussionForumDetails'
// import RemedialSolutionForCareer from './components/common/CareerAstrology/RemedialSolutionForCareer'
// import StrengthReadingForCareer from './components/common/CareerAstrology/StrengthReadingForCareer'
// import CareerAsk1Question from './components/common/CareerAstrology/CareerAsk1Question'
// import CareerAsk3Question from './components/common/CareerAstrology/CareerAsk3Question'
// import CareerReport2Year from './components/common/CareerAstrology/CareerReport2Year'
// import CareerReport3Year from './components/common/CareerAstrology/CareerReport3Year'
// import CareerReport5Years from './components/common/CareerAstrology/CareerReport5Years'
// import ChangePassword from './pages/forgotPassword/ChangePassword'
// import FourZeroFour from './pages/404/FourZeroFour'
// import WorkinAustralia from './components/InternationalWorkVisas/WorkinAustralia';
// import WorkinCanada from './components/InternationalWorkVisas/WorkinCanada';
// import EducationLoan from './components/StudyAbroad/EducationLoan';

import WorkinUK from "./pages/InternationalWorkVisas/WorkinUK";
import ResumeTemplate1 from "./pages/ResumeMaking/ResumeTemplate1";
import WorkinUSA from "./components/InternationalWorkVisas/WorkinUSA";


import WorkinAustralia from './components/InternationalWorkVisas/WorkinAustralia'
import WorkinCanada from './components/InternationalWorkVisas/WorkinCanada'
import EducationLoan from './components/StudyAbroad/EducationLoan'
import CommonSearchQuestions from "./components/InterviewQuestions/CommonSearchQuestion";
import CommonSearchAnswer from "./pages/InterviewQuestions/CommonSearchAnswer";
import CommonSearchQuestion from "./components/InterviewQuestions/CommonSearchQuestion";
import WorkinIreland from "./components/InternationalWorkVisas/WorkinIreland";
import WorkinNewZealand from "./components/InternationalWorkVisas/WorkinNewZealand";
import WorkinSingapore from "./components/InternationalWorkVisas/WorkinSingapore";
import WorkinMalaysia from "./components/InternationalWorkVisas/WorkinMalaysia";
import AdsMainPage from "./pages/Ads/MainPage"
import MessageById from "./components/myAccount/MessageById";
import ResumeTemplate2 from "./pages/ResumeMaking/ResumeTemplate2";
import ResumeTemplate3 from "./pages/ResumeMaking/ResumeTemplate3";
import ResumeTemplate4 from "./pages/ResumeMaking/ResumeTemplate4";
import HiringFresshersJob from "./pages/joblist/hiringfreshersjob"
import Jobsearchindia from './pages/JobsearchIndia/Jobsearchindia'
import JobSearchDetail from "./pages/JobsearchIndia/JobSearchDetail";
import FullStackJobsIndia from "./pages/JobsearchIndia/FullStackJobsIndia";
import jobsinIndia from "./pages/JobsearchIndia/JobsinIndia";
import ContractHiring from "./pages/EmployerServices/ContractHiring";
import CreateMicrosites from "./pages/EmployerServices/CreateMicrosites";
import PremiumJds from "./pages/EmployerServices/PremiumJds";
import CustomerRelationshipManagement from "./pages/EmployerServices/CustomerRelationshipManagement";
import MockInterview from "./pages/EmployerServices/MockInterview";

import Swiggy from "./pages/companies/Swiggy/Swiggy";
import SwiggyJobs from "./pages/companies/Swiggy/SwiggyJobs";
import SwiggyAboutUs from "./pages/companies/Swiggy/SwiggyAboutUs";
import SwiggyLife from "./pages/companies/Swiggy/SwiggyLife";
import UpdateTemplate from "./pages/ResumeMaking/UpdateTemplate";
import UpdateTemplate01 from "./pages/ResumeMaking/UpdateTemplate01";
import UpdateTemplate02 from "./pages/ResumeMaking/UpdateTemplate02";

import PMRozgarMela from "./pages/PMRozgarMela/PMRozgarMela";
import TemplateEdit04 from "./components/ResumeMaking/TemplateEdit04";

import TemplateEdit05 from "./components/ResumeMaking/TemplateEdit05";
import TemplateEdit06 from "./components/ResumeMaking/TemplateEdit06";
import TemplateEdit07 from "./components/ResumeMaking/TemplateEdit07";
import UpdateTemplate04 from "./pages/ResumeMaking/UpdateTemplate04";
import UpdateTemplate05 from "./pages/ResumeMaking/UpdateTemplate05";
import UpdateTemplate06 from "./pages/ResumeMaking/UpdateTemplate06";
import Unsubscribe from "./pages/termsConditions/unsubscribe";
import UpdateTemplate07 from "./pages/ResumeMaking/UpdateTemplate07";

import RemoteResources from "./pages/HiringSolutions/RemoteResources";
import FreelanceResources from "./pages/HiringSolutions/FreelanceResources";
import RecruitmentProcessOutsourcing from "./pages/HiringSolutions/RecruitmentProcessOutsourcing";
import CampusPlacementPage from "./pages/HiringSolutions/CampusPlacementPage";
import ApplicationTrackingSystem from "./pages/HrTechConsulting/ApplicationTrackingSystem";
import LearningManagementSystem from "./pages/HrTechConsulting/LearningManagementSystem";
import ManagedHrServices from "./pages/HrTechConsulting/ManagedHrServices";
import CustomSolutions from "./pages/HrTechConsulting/CustomSolutions";
import EmployerBrandingSolutions from "./pages/HrTechConsulting/EmployerBrandingSolutions";
import TechnicalCourses from "./pages/LearningHub/TechnicalCourses";
import ManagementCourses from "./pages/LearningHub/ManagementCourses";
import LeadershipCourses from "./pages/LearningHub/LeadershipCourses";
import AstrologyHoroscopeGuidance from "./pages/LearningHub/AstrologyHoroscopeGuidance";
import OurUsp from "./pages/DiscoverUs/OurUsp";
import CaseStudies from "./pages/DiscoverUs/CaseStudies";
import Clients from "./pages/DiscoverUs/Clients";
import Products from "./pages/DiscoverUs/Products";
import ContactUs from "./pages/DiscoverUs/ContactUs";
import RequestCallback from "./components/Enquiry/RequestCallback";
import WorkFromHomeJobs from "./pages/joblist/WorkFromHomeJobs";
import WalkInJobs from "./pages/joblist/WalkInJobs";
import PartTimeJobs from "./pages/joblist/PartTimeJobs";
import RemoteJobs from "./pages/joblist/RemoteJobs";
import HotSectors from "./pages/VideoJD/HotSectors";
import MostLiked from "./pages/VideoJD/MostLiked";
import MostPopular from "./pages/VideoJD/MostPopular";
import Dashboard from "./pages/jobsBy/dashboard";
import { regionList } from "./action/jobsByActions";

const EmployeeRoutes = (props) => {
  const ud = getStorage("USER_DETAIL");
  if (ud.USER_TYPE === "EMPLOYEE") {
    return (
      <React.Fragment>
        {props.hideHeader === true ? null : <EmployeeHeader />}
        <Route exact={true} path={props.path} component={props.component} />
        {props.hideFooter === true ? null : <Footer />}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Route exact={true} path={props.path} component={props.component} />
    </React.Fragment>
  );
};

const CandidateRoute = (props) => {
  const token = getStorage(constant.keys.ctoken);
  const urlKeys = Object.entries(constant.component);
  const path = props.path;
  const match = urlKeys.filter((i) => i[1].url === path);
  if (token) {
    if (match.length > 0 && match[0][1].url === path) {
      return (
        <React.Fragment>
          {props.hideHeader === true ? null : <EmployeeHeader HeaderUpdate={props.HeaderUpdate} />}
          <Route exact={true} path={props.path} component={props.component} />
          {props.hideFooter === true ? null : <Footer />}
        </React.Fragment>
      );
    } else {
      window.location.href = constant.component.login.url;
    }
  } else {
    window.location.href = constant.component.login.url;
  }
};

const ModalRoute = (props) => {
  const cd = getStorage(constant.keys.cd);
  if (cd) {
    return (
      <React.Fragment>
        <Route exact={true} path={props.path} component={props.component} />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Route exact={true} path={props.path} component={props.component} />
    </React.Fragment>
  );
};

const PrivateRoutes = (props) => {
  // console.log(update,"update");
  const token = getStorage(constant.keys.ctoken);
  const urlKeys = Object.entries(constant.component);
  const path = props.path;
  const match = urlKeys.filter((i) => i[1].url === path);
  if (token) {
    if (match.length > 0 && match[0][1].url === path) {
      return (
        <React.Fragment>
          {props.hideHeader === true ? null : <EmployeeHeader HeaderUpdate={props.HeaderUpdate} />}
          <Route exact={true} path={props.path} component={props.component} />
          {props.hideFooter === true ? null : <Footer />}
        </React.Fragment>
      );
    } else {
      window.location.href = constant.component.login.url;
    }
  } else {
    if (match.length > 0 && match[0][1].url === path) {
      return (
        <React.Fragment>
          {!props.showLoader && props.hideHeader === true ? null : <Header HeaderUpdate={props.HeaderUpdate} />}

          <Route exact={true} path={props.path} component={props.component} />

          {!props.showLoader && props.hideFooter === true ? null : <Footer />}
        </React.Fragment>
      );
    } else {
      window.location.href = constant.component.login.url;
    }
  }
};

const Router = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [update ,setUpdate]=useState('')
  const HeaderUpdate=(value)=>{
const region = getStorage(constant.keys.region)
let newVal = region?region:value
    setUpdate(newVal)
  }
  useEffect(() => {
    setInterval(() => {
      setShowLoader(false);
    }, 500);
  }, []);

  return (
   
    <React.Fragment>
      
      <Switch>
      {/* <JobsByDashboard
         regionSelect={props.regionSelect}
        /> */}
      <PrivateRoutes
          exact
          path={constant.component.unsubscribe.url}
          showLoader={showLoader}
          component={Unsubscribe}
        />
        <PrivateRoutes
           HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.login.url}
          showLoader={showLoader}
          component={()=><Homepage update={update}  regionSelect={props.regionSelect}/>}
        />
       
        <PrivateRoutes
          exact
          path={constant.component.homepage.url}
          HeaderUpdate={HeaderUpdate}
          showLoader={showLoader}
          component={()=><Homepage update={update} regionSelect={props.regionSelect}/>}
        />

        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.MobileSearch.url}
          showLoader={showLoader}
          component={MobileSearch}
        />

        {/* <PrivateRoutes exact path={constant.component.joblist.url} component={joblist} /> */}
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobdetails.url}
          component={()=><Jobdetails update={update} regionSelect={props.regionSelect}/>}
        />

        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.CreateJobAlert.url}
          component={()=><CreateJobAlert update={update} regionSelect={props.regionSelect}/>}
        />


        <Route exact path={constant.component.signin.url} component={signin} />
        <Route
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.register.url}
          component={register}
        />
        <Route
         HeaderUpdate={HeaderUpdate}
          path={constant.component.resetPassword.url}
          component={ResetPassword}
        />
        <Route
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.forgotPassword.url}
          component={forgotPassword}
        />

        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.privacyPolicy.url}
          component={privacyPolicy}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.termsConditions.url}
          component={termsConditions}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.companieslist.url}
          component={companieslist}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.topcompanieslist.url}
          component={TopCompanyList}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.companydetails.url}
          component={()=><Companydetails update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobbycity.url}
          component={jobbycity}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.CityOverview.url}
          component={CityOverview}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.blog.url}
          component={blog}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.PersonalRecruiter.url}
          component={PersonalRecruiter}
        />

        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.blogDetail.url} component={blogDetails} />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.blogCategory.url}
          component={BlogListByCategory}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.faqs.url}
          component={faqs}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.reportIssue.url}
          component={reportIssue}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.Enquiry.url}
          component={Enquiry}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.error.url}
          component={error}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.faqDetails.url}
          component={faqDetail}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.faqCategory.url}
          component={faqCategory}
        />
        {/* <EmployeeRoutes exact path={constant.component.profile.url} component={profile} /> */}
        {/* <PrivateRoutes exact path={constant.component.jobsByLocation.url} component={jobsByLocation} /> */}
        {/* <PrivateRoutes exact path={constant.component.jobsByCompany.url} component={jobsByCompany} /> */}
        {/* <PrivateRoutes exact path={constant.component.jobsByCategory.url} component={jobsByCategory} /> */}
        {/* <PrivateRoutes exact path={constant.component.jobsByDesignation.url} component={jobsByDesignation} /> */}
        {/* <PrivateRoutes exact path={constant.component.jobsBySkill.url} component={jobsBySkill} /> */}
        <PrivateRoutes
          exact
          path={constant.component.AllJobs.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
          exact
          path={constant.component.jobsByCategory.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
          exact
          path={constant.component.jobsByLocation.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
          exact
          path={constant.component.jobsByCompany.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard  update={update}regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
          exact
          path={constant.component.jobsByDesignation.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard update={update}regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
          exact
          path={constant.component.jobsBySkill.url}
          HeaderUpdate={HeaderUpdate}
          component={()=> <JobsByDashboard update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.joblist.url}
          component={()=> <Joblist update={update} regionSelect={props.regionSelect}/>}
         // component={joblist}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.latestfresherjob.url}
          component={()=><LatestFresherJoblist update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.WorkFromHomeJobs.url}
          component={()=><WorkFromHomeJobs update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.WalkInJobs.url}
          component={()=><WalkInJobs update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.PartTimeJobs.url}
          component={()=><PartTimeJobs update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.RemoteJobs.url}
          component={()=><RemoteJobs update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.MostLikedVideoJdJob.url}
          component={()=><MostLiked update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.MostPopularVideoJdJob.url}
          component={()=><MostPopular update={update} regionSelect={props.regionSelect}/>}
        />
         <PrivateRoutes
          HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.HotSectorVideoJdJob.url}
          component={()=><HotSectors update={update} regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.hiringfresherjob.url}
          component={()=><HiringFresshersJob update={update} regionSelect={props.regionSelect}/>}
        />

        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.governmentJobs.url}
          component={GovermentJobList}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.internationalJobs.url}
          component={InternationalJobList}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.fulltimehiring.url}
          component={Fulltimehiring}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.contracttualstaffing.url}
          component={ContractualStaffingCopy}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.hrmanagementsystem.url}
          component={HRMS}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.marketingtechnology.url}
          component={Marketingtechnologysolution}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.payrollautomation.url}
          component={Payrollautomation}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.startupincubation.url}
          component={Startupincubation}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.discussionForum.url}
          component={DiscussionForum}
        />
        <PrivateRoutes
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.discussionForumDetails.url}
          component={DiscussionForumDetails}
        />

        {/* <EmployeeRoutes exact path={constant.component.editProfile.url} component={editProfile} /> */}
        {/* <EmployeeRoutes exact path={constant.component.savedJobs.url} component={SavedJobs} /> */}
        {/* <EmployeeRoutes exact path={constant.component.recommendedJobs.url} component={RecommendedJobs} /> */}
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.recommendedJobs.url}
          component={()=><RecommendedJobs update={update} regionSelect={props.regionSelect} />}
        />

        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.applicationStatusById.url}
          component={()=><ApplicationStatus update={update} regionSelect={props.regionSelect}/>}
        />
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.applicationStatus.url}
          component={()=><ApplicationStatus update={update} regionSelect={props.regionSelect}/>}
        />
        {/* <EmployeeRoutes exact path={constant.component.profilePreview.url} component={ProfilePreview} /> */}

        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          hideHeader={false}
          exact
          path={constant.component.editProfile.url}
          component={()=><EditProfile  update={update} regionSelect={props.regionSelect}/>}
        />
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.profilePreview.url}
          component={ProfilePreview}
        />
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.profilePreviews.url}
          component={ProfilePreview}
        />
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.myRozgar.url}
          component={myRozgar}
        />
        <CandidateRoute
         HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.allRecommendedJobsList.url}
          component={()=><AllRecommendedJobs  update={update} regionSelect={props.regionSelect}/>}
        />
        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.savedJobs.url}
          component={SavedJobs}
        />
        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.ProfilePerformance.url}
          component={ProfilePerformance}
        />
        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.InboxMessage.url}
          component={InboxMessage}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsByLocation.url}
          component={jobsByLocation}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsByCompany.url}
          component={jobsByCompany}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsByCategory.url}
          component={jobsByCategory}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsByDesignation.url}
          component={jobsByDesignation}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsBySkill.url}
          component={jobsBySkill}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.aboutUs.url}
          component={aboutUs}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.privacyPolicy.url}
          component={privacyPolicy}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.termsConditions.url}
          component={termsConditions}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.searchbBoglist.url}
          component={BlogListBySearch}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.searchjob.url}
          component={()=><JobListBySearch update={update}  regionSelect={props.regionSelect}/>}

          //component={jobListBySearch}
        />
        {/* <PrivateRoutes exact path={constant.component.courseDetail.url} component={JavaFullStack} /> */}
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.courseDetailById.url}
          component={courseDetail}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.ByCompany.url}
          component={ByCompany}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionBySkillsId.url}
          component={BySkills}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.ShareAnInterview.url}
          component={ShareAnInterview}
        />
        {/* <PrivateRoutes exact path={constant.component.AddAnswer.url} component={AddAnswer} /> */}
        {/* <PrivateRoutes exact path={constant.component.AllJobs.url} component={AllJobs} /> */}

        <Route HeaderUpdate={HeaderUpdate} path={constant.component.logout.url} component={Logout} />
        <Route
        HeaderUpdate={HeaderUpdate}
          path={constant.component.verifyEmail.url}
          component={EmailVerificationScreen}
        />

        {/* Profile Picture upload */}
        <ModalRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.profileUpload.url}
          component={profilePicture}
        />
        {/* Profile Picture upload */}

        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.premiumJobs.url}
          component={()=><PremiumJobs update={update}  regionSelect={props.regionSelect} />}
        />
        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.changePassword.url}
          component={ChangePassword}
        />
        <CandidateRoute
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.featuredJobs.url}
          component={()=><FeaturedJobs update={update}  regionSelect={props.regionSelect}/>}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.buyCourse.url}
          component={buyCourse}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.thankYou.url}
          component={ThankYou}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyAbroad.url}
          component={studyAbroad}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.admissionAssistance.url}
          component={AdmissionAssistance}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyAbroadScholarship.url}
          component={StudyAbroadScholarship}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.testPreparation.url}
          component={TestPreparation}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.travelGuidance.url}
          component={travelGuidance}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.visaApplicationAssistance.url}
          component={VisaApplicationAssistance}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.coursesAdviceGuidance.url}
          component={CoursesAdviceGuidance}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyAbroadCounselling.url}
          component={studyAbroadCounselling}
        />
        <PrivateRoutes
          exact
          HeaderUpdate={HeaderUpdate}
          path={constant.component.visaCoverLetter.url}
          component={VisaCoverLetter}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.sopWritingServices.url}
          component={SopWritingServices}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.lorWritingServices.url}
          component={LorWritingServices}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.resumeWritingServices.url}
          component={ResumeWritingServices}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInAustralia.url}
          component={StudyInAustralia}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInCanada.url}
          component={StudyInCanada}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInUk.url}
          component={StudyInUk}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInUsa.url}
          component={StudyInUsa}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInItaly.url}
          component={StudyInItaly}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInIreland.url}
          component={StudyInIreland}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInNewZealand.url}
          component={StudyInNewZealand}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.studyInSingapore.url}
          component={StudyInSingapore}
        />
        <PrivateRoutes
          exact
          HeaderUpdate={HeaderUpdate}
          path={constant.component.internationalWorkVisas.url}
          component={InternationalWorkVisas}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerAstrology.url}
          component={CareerAstrology}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerReport1Year.url}
          component={CareerReport1Year}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.remedialSolutionForCareer.url}
          component={RemedialSolutionForCareer}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.strengthReadingForCareer.url}
          component={StrengthReadingForCareer}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerAsk1Question.url}
          component={CareerAsk1Question}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerAsk3Question.url}
          component={CareerAsk3Question}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerReport2Year.url}
          component={CareerReport2Year}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerReport3Year.url}
          component={CareerReport3Year}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.careerReport5Years.url}
          component={CareerReport5Years}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestion.url}
          component={InterviewQuestion}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionBySkills.url}
          component={InterviewQuestion}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.JobSearchIndia.url}
          component={Jobsearchindia}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.jobsearchIndiaDetail.url}
          component={JobSearchDetail}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionByCompany.url}
          component={InterviewQuestion}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionByDesignation.url}
          component={InterviewQuestion}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewAnswer.url}
          component={AddAnswer}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionByCompanyId.url}
          component={ByCompany}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.interviewQuestionByDesignationId.url}
          component={ByDesignation}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.companyInterviewAnswer.url}
          component={CompanyAnswer}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.designationInterviewAnswer.url}
          component={DesignationAnswer}
        />

        <CandidateRoute HeaderUpdate={HeaderUpdate}  exact path={constant.component.premiumJobs.url} component={()=><PremiumJobs update={update}  regionSelect={props.regionSelect}/>} />
        <CandidateRoute HeaderUpdate={HeaderUpdate} exact path={constant.component.changePassword.url} component={ChangePassword} />
        <CandidateRoute HeaderUpdate={HeaderUpdate} exact path={constant.component.featuredJobs.url} component={()=><FeaturedJobs update={update}  regionSelect={props.regionSelect}/>} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate}  exact path={constant.component.buyCourse.url} component={buyCourse} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}  exact path={constant.component.thankYou.url} component={ThankYou} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}  exact path={constant.component.studyAbroad.url} component={studyAbroad} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.admissionAssistance.url} component={AdmissionAssistance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyAbroadScholarship.url} component={StudyAbroadScholarship} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.testPreparation.url} component={TestPreparation} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.travelGuidance.url} component={travelGuidance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.visaApplicationAssistance.url} component={VisaApplicationAssistance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.coursesAdviceGuidance.url} component={CoursesAdviceGuidance} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate} exact path={constant.component.studyAbroadCounselling.url} component={studyAbroadCounselling} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.visaCoverLetter.url} component={VisaCoverLetter} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.sopWritingServices.url} component={SopWritingServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}  exact path={constant.component.lorWritingServices.url} component={LorWritingServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.resumeWritingServices.url} component={ResumeWritingServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInAustralia.url} component={StudyInAustralia} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate}exact path={constant.component.studyInCanada.url} component={StudyInCanada} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInUk.url} component={StudyInUk} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInUsa.url} component={StudyInUsa} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInItaly.url} component={StudyInItaly} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate}exact path={constant.component.studyInIreland.url} component={StudyInIreland} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInNewZealand.url} component={StudyInNewZealand} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInSingapore.url} component={StudyInSingapore} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.internationalWorkVisas.url} component={InternationalWorkVisas} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerAstrology.url} component={CareerAstrology} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerReport1Year.url} component={CareerReport1Year} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.remedialSolutionForCareer.url} component={RemedialSolutionForCareer} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.strengthReadingForCareer.url} component={StrengthReadingForCareer} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerAsk1Question.url} component={CareerAsk1Question} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}  exact path={constant.component.careerAsk3Question.url} component={CareerAsk3Question} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerReport2Year.url} component={CareerReport2Year} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerReport3Year.url} component={CareerReport3Year} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerReport5Years.url} component={CareerReport5Years} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinAustralia.url} component={WorkinAustralia} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinCanada.url} component={WorkinCanada} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinUK.url} component={WorkinUK} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.educationLoan.url} component={EducationLoan} />
        <CandidateRoute HeaderUpdate={HeaderUpdate} exact path={constant.component.premiumJobs.url} component={()=><PremiumJobs  update={update}  regionSelect={props.regionSelect}/>} />
        <CandidateRoute HeaderUpdate={HeaderUpdate} exact path={constant.component.featuredJobs.url} component={()=><FeaturedJobs update={update}  regionSelect={props.regionSelect}/>} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.buyCourse.url} component={buyCourse} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.thankYou.url} component={ThankYou} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}exact path={constant.component.studyAbroad.url} component={studyAbroad} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.admissionAssistance.url} component={AdmissionAssistance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyAbroadScholarship.url} component={StudyAbroadScholarship} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.testPreparation.url} component={TestPreparation} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.travelGuidance.url} component={travelGuidance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.visaApplicationAssistance.url} component={VisaApplicationAssistance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.coursesAdviceGuidance.url} component={CoursesAdviceGuidance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyAbroadCounselling.url} component={studyAbroadCounselling} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.visaCoverLetter.url} component={VisaCoverLetter} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.sopWritingServices.url} component={SopWritingServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.lorWritingServices.url} component={LorWritingServices} />
        <PrivateRoutes exact path={constant.component.resumeWritingServices.url} component={ResumeWritingServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInAustralia.url} component={StudyInAustralia} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInCanada.url} component={StudyInCanada} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInUk.url} component={StudyInUk} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInUsa.url} component={StudyInUsa} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInItaly.url} component={StudyInItaly} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInIreland.url} component={StudyInIreland} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInNewZealand.url} component={StudyInNewZealand} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.studyInSingapore.url} component={StudyInSingapore} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.internationalWorkVisas.url} component={InternationalWorkVisas} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.careerAstrology.url} component={CareerAstrology} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate} exact path={constant.component.StudentsExplorer.url} component={StudentsExplorer} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.CareerExplorerDetail.url} component={careerExplorerDetail} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeViewOne.url} component={ResumeViewOne} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeViewTwo.url} component={ResumeViewTwo} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeViewThree.url} component={ResumeViewThree} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeTemplateView01.url} component={ResumeTemplateView01} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeTemplateView02.url} component={ResumeTemplateView02} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ResumeTemplateView03.url} component={ResumeTemplateView03} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.resumeTemplate1.url} component={ResumeTemplate1} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinUSA.url} component={WorkinUSA} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.messageById.url} component={MessageById} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinIreland.url} component={WorkinIreland} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinNewZealand.url} component={WorkinNewZealand} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinSingapore.url} component={WorkinSingapore} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.workinMalaysia.url} component={WorkinMalaysia} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.resumeTemplate2.url} component={ResumeTemplate2} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.resumeTemplate3.url} component={ResumeTemplate3} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.resumeTemplate4.url} component={ResumeTemplate4} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.FullStackJobs.url} component={FullStackJobsIndia} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.FullStackDeveloperJobsInIndia.url} component={jobsinIndia} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.upgradeskills.url} component={UpgradeSkills} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.CompanyBrandings.url} component={CompanyBrandings} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.SponsoredJds.url} component={SponsoredJds} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.HrTechSolutions.url} component={HrTechSolutions} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ContractHiring.url} component={ContractHiring} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.CreateMicrosites.url} component={CreateMicrosites} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate}  exact path={constant.component.PremiumJds.url} component={PremiumJds} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate} exact path={constant.component.CustomerRelationshipManagement.url} component={CustomerRelationshipManagement} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.MockInterview.url} component={MockInterview} />

        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.Swiggy.url} component={Swiggy} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.SwiggyJobs.url} component={SwiggyJobs} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.SwiggyAboutUs.url} component={SwiggyAboutUs} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.SwiggyLife.url} component={SwiggyLife} />

        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.PMRozgarMela.url} component={PMRozgarMela} />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.AnswerByQuestion.url}
          component={AnswerByQuestion}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.ResumeMaking.url}
          component={ResumeMaking}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplatePreview.url}
          component={TemplatePreview}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplatePreview01.url}
          component={TemplatePreview01}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplatePreview02.url}
          component={TemplatePreview02}
        />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TemplatePreview04.url} component={TemplatePreview04} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TemplatePreview05.url} component={TemplatePreview05} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TemplatePreview06.url} component={TemplatePreview06} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TemplatePreview07.url} component={TemplatePreview07} />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit.url}
          component={TemplateEdit}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit01.url}
          component={TemplateEdit01}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit02.url}
          component={TemplateEdit02}
        />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TemplateEdit04.url} component={TemplateEdit04} />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.discussionForumSignIn.url}
          component={DiscussionFormSignIn}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.discussionForumCategory.url}
          component={DiscussionForumCategory}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.commonSearchQuestion.url}
          component={CommonSearchQuestions}
        />
        <PrivateRoutes
          exact
          path={constant.component.commonSearchAnswer.url}
          component={CommonSearchAnswer}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.ads.url}
          component={AdsMainPage}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate01.url}
          component={UpdateTemplate}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate02.url}
          component={UpdateTemplate01}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate03.url}
          component={UpdateTemplate02}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate04.url}
          component={UpdateTemplate04}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate05.url}
          component={UpdateTemplate05}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate06.url}
          component={UpdateTemplate06}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.updateTemplate07.url}
          component={UpdateTemplate07}
        />

        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit04.url}
          component={TemplateEdit04}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit05.url}
          component={TemplateEdit05}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit06.url}
          component={TemplateEdit06}
        />
        <PrivateRoutes
        HeaderUpdate={HeaderUpdate}
          exact
          path={constant.component.TemplateEdit07.url}
          component={TemplateEdit07}
        />

        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.RemoteResources.url} component={RemoteResources} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.FreelanceResources.url} component={FreelanceResources} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.RecruitmentProcessOutsourcing.url} component={RecruitmentProcessOutsourcing} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.CampusPlacement.url} component={CampusPlacementPage} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ApplicationTrackingSystem.url} component={ApplicationTrackingSystem} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.LearningManagementSystem.url} component={LearningManagementSystem} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ManagedHrServices.url} component={ManagedHrServices} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.CustomSolutions.url} component={CustomSolutions} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.EmployerBrandingSolutions.url} component={EmployerBrandingSolutions} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.TechnicalCourses.url} component={TechnicalCourses} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ManagementCourses.url} component={ManagementCourses} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate} exact path={constant.component.LeadershipCourses.url} component={LeadershipCourses} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.AstrologyHoroscopeGuidance.url} component={AstrologyHoroscopeGuidance} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.OurUsp.url} component={OurUsp} />
        <PrivateRoutes  HeaderUpdate={HeaderUpdate} exact path={constant.component.CaseStudies.url} component={CaseStudies} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.Clients.url} component={Clients} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.Products.url} component={Products} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.ContactUs.url} component={ContactUs} />
        <PrivateRoutes HeaderUpdate={HeaderUpdate} exact path={constant.component.RequestCallback.url} component={RequestCallback} />

        <Route component={FourZeroFour} />
      </Switch>
    </React.Fragment>
  );
};

export default Router;
