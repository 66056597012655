const component = {
    login: { url: '/' },
    joblist: { url: '/jobs-in-:url' },
    latestfresherjob: { url: '/jobs/fresher' },
    MNCjobs: { url: '/jobs/mnc' },
    RemoteJobs: { url: '/jobs/remote' },
    WorkFromHomeJobs:{url:'/jobs/work-from-home'},
    PartTimeJobs:{url:'/jobs/part-time-jobs'},
    MostPopularVideoJdJob:{url:'/jobs/most-popluar-video-jd-jobs'},
    HotSectorVideoJdJob:{url:'/jobs/hot-sector-video-jd-jobs'},
    MostLikedVideoJdJob:{url:'/jobs/most-liked-video-jd-jobs'},
    WalkInJobs:{url:'/jobs/walk-in-jobs'},
    hiringfresherjob: { url: '/jobs/hiring-fresher' },
    jobdetails: { url: '/job-detail/:url' },
    dashboard: { url: '/dashboard' },
    verifyEmail: { url: '/EmailVerification' },
    signin: { url: '/signin' },
    register: { url: '/register' },
    jobsearchIndiaDetail: { url: '/job-search-india/:KEYWORD_URL' },
    companieslist: { url: '/list/companies' },
    topcompanieslist: { url: '/top-companies' },
    companydetails: { url: '/company/:url' },
    jobbycity: { url: '/job-by-city' },
    CityOverview: { url: '/:city-an-overview' },
    blog: { url: '/blog' },
    blogDetails: { url: '/blog-details' },
    faqs: { url: '/faq' },
    MobileSearch: { url: '/search' },
    faqDetails: { url: '/faq/:URL' },
    faqCategory: { url: '/faq-category/:URL' },
    jobsByLocation: { url: '/jobs-by-location' },
    jobsByCompany: { url: '/jobs-by-company' },
    jobsByCategory: { url: '/jobs-by-category' },
    jobsByDesignation: { url: '/jobs-by-designation' },
    jobsBySkill: { url: '/jobs-by-skill' },
    aboutUs: { url: '/about-us' },
    privacyPolicy: { url: '/privacy-policy' },
    termsConditions: { url: '/terms-conditions' },
    forgotPassword: { url: '/forgot-password' },
    logout: { url: '/logout' },
    myRozgar: { url: '/my-mount-talent' },
    editProfile: { url: '/edit-profile' },
    savedJobs: { url: '/saved-jobs' },
    ProfilePerformance: { url: '/profile-performance' },
    InboxMessage: { url: '/recruiter-message' },
    recommendedJobs: { url: '/recommended-jobs' },
    applicationStatus: { url: '/application-status' },
    applicationStatusById: { url: '/application-status/:id' },
    profilePreview: { url: '/profile/:URL' },
    profilePreviews: { url: '/profile' },
    blogDetail: { url: '/blog/:url' },
    homepage: { url: '/' },
    searchjob: { url: '/search-job' },
    PersonalRecruiter: { url: "/PersonalRecruiter" },
    // editblog: { url: '/blog-detail/:url' },

    blogCategory: { url: '/blog/category/:url' },
    resetPassword: { url: '/reset-password-page' },
    searchbBoglist: { url: '/blog-list/search=:keyword' },
    allRecommendedJobsList: { url: '/all-recommended-jobs' },
    Enquiry: { url: '/under-maintainance-:Enquiry' },
    jobsInNoida: { url: '/Enquiry' },
    reportIssue: { url: '/report-a-problem' },
    CreateJobAlert: { url: '/create-job-alert' },

    error: { url: '/error' },
    BySkills: { url: '/BySkills' },
    ByCompany: { url: '/ByCompany' },
    ShareAnInterview: { url: '/share-an-interview' },
    AddAnswer: { url: '/AddAnswer' },
    AllJobs: { url: '/browse-jobs' },
    JavaFullStack: { url: '/JavaFullStack' },
    governmentJobs: { url: '/government-jobs' },
    internationalJobs: { url: '/international-jobs' },
    profileUpload: { url: '/profile-upload' },
    //Profile Picture upload
    premiumJobs: { url: '/premium-jobs' },
    changePassword: { url: '/change-password' },
    featuredJobs: { url: '/featured-jobs' },
    // courseDetail: { url: '/course-detail/:url' },
    courseDetailById: { url: '/course-detail/:url/:COURSE_ID' },
    fulltimehiring: { url: '/full-time-hiring' },
    contracttualstaffing: { url: '/contractual-staffing' },
    hrmanagementsystem: { url: '/hrms' },
    marketingtechnology: { url: '/marketing-technology' },
    payrollautomation: { url: '/payroll-automation' },
    startupincubation: { url: '/startup-incubation' },
    upgradeskills:{url:'/upgrade-skills'},
    CompanyBrandings:{url:'/company-brandings'},
    SponsoredJds:{url:'/sponsored-jds'},
    HrTechSolutions:{url:'/hr-tech-solutions'},
    ContractHiring:{url:'/contract-hiring'},
    CreateMicrosites:{url:'/create-microsites'},
    PremiumJds:{url:'/premium-jds'},
    CustomerRelationshipManagement:{url:'/customer-relationship-management'},
    MockInterview:{url:'/mock-interview'},

    thankYou: { url: '/Thank-You' },
    studyAbroad: { url: '/study-abroad' },
    admissionAssistance: { url: '/admission-assistance' },
    interviewQuestion: { url: '/interview-questions' },
    JobSearchIndia: { url: '/job-search-india' },
    interviewQuestionBySkills: { url: '/interview-questions/skills' },
    interviewQuestionByCompany: { url: '/interview-questions/company' },
    interviewQuestionByDesignation: { url: '/interview-questions/designations' },
    interviewQuestionBySkillsId: { url: '/interview-questions/skills/:id' },
    interviewQuestionByCompanyId: { url: '/interview-questions/company/:id' },

    interviewQuestionByDesignationId: { url: '/interview-questions/designations/:id' },
    interviewAnswer: { url: '/interview-Answer/:url' },
    studyAbroadScholarship: { url: '/study-abroad-scholarship' },
    testPreparation: { url: '/test-preparation' },
    travelGuidance: { url: '/travel-guidance' },
    visaApplicationAssistance: { url: '/visa-application-assistance' },
    coursesAdviceGuidance: { url: '/courses-advice-guidance' },
    studyAbroadCounselling: { url: '/study-abroad-counselling' },
    visaCoverLetter: { url: '/visa-cover-letter' },
    sopWritingServices: { url: '/sop-writing-services' },
    lorWritingServices: { url: '/lor-writing-services' },
    resumeWritingServices: { url: '/resume-writing-services' },
    studyInAustralia: { url: '/study-in-australia' },
    studyInCanada: { url: '/study-in-canada' },
    studyInUk: { url: '/study-in-uk' },
    studyInUsa: { url: '/study-in-usa' },
    studyInItaly: { url: '/study-in-Italy' },
    studyInIreland: { url: '/study-in-ireland' },
    studyInNewZealand: { url: '/study-In-new-zealand'},
    studyInSingapore: { url: '/study-in-singapore'},
    internationalWorkVisas: { url: '/work-abroad'},
    careerAstrology: { url: '/career-astrology'},
    StudentsExplorer: { url: '/career-explorer'},
    CareerExplorerDetail: { url: '/college-details/:url'},
    ResumeViewOne: { url: '/resume-view'},
    ResumeViewTwo: { url: '/resume-view01'},
    ResumeViewThree: { url: '/resume-view02'},
    ResumeTemplateView01: { url: '/resume-template-view' },
    ResumeTemplateView02: { url: '/resume-template-view01' },
    ResumeTemplateView03: { url: '/resume-template-view03' },
    careerReport1Year: { url: '/career-report-1-year' },
    remedialSolutionForCareer: { url: '/remedial-solution-for-career' },
    strengthReadingForCareer: { url: '/strength-reading-for-career' },
    careerAsk1Question: { url: '/career-ask-1-question' },
    careerAsk3Question: { url: '/career-ask-3-question' },
    careerReport2Year: { url: '/career-report-2-year' },
    careerReport3Year: { url: '/career-report-3-year' },
    careerReport5Years: { url: '/career-report-5-year' },
    educationLoan: { url: '/education-loan' },
    workinAustralia: { url: '/work-in-australia' },
    workinCanada: { url: '/work-in-canada' },
    companyInterviewAnswer: { url: '/interview-Answers/:url' },
    designationInterviewAnswer: { url: '/interview-Answers-Designation/:url' },
    //interviewQuestion by Company

    //interviewQuestion by Company
    discussionForum: { url: '/discussion-forum' },
    discussionForumCategory: { url: '/discussion-forum/category/:category' },
    buyCourse: { url: '/buy-course/:url' },
    ResumeMaking: { url: '/resume-making' },
    TemplatePreview: { url: '/template-preview' },
    TemplatePreview01: { url: '/template-preview01' },
    TemplatePreview02: { url: '/template-preview02' },
    TemplatePreview04: { url: '/template-preview04' },
    TemplatePreview05: { url: '/template-preview05' },
    TemplatePreview06: { url: '/template-preview06' },
    TemplatePreview07: { url: '/template-preview07' },
    TemplateEdit: { url: '/template-edit' },
    TemplateEdit01: { url: '/template-edit01' },
    TemplateEdit02: { url: '/template-edit02' },
    TemplateEdit04: { url: '/template-Edit04' },
    TemplateEdit05: { url: '/template-Edit05' },
    TemplateEdit06: { url: '/template-Edit06' },
    TemplateEdit07: { url: '/template-Edit07' },
    AnswerByQuestion: { url: '/interview-Question-Answer/:id' },
    discussionForumDetails: { url: '/topic/:url' },
    discussionForumSignIn: { url: '/discussionForumSignIn' },
    resumePreview: { url: '/preview-resume' },
    workinUK: { url: '/work-in-uk' },
    resumeTemplate1: { url: '/resume-template-1' },
    workinUSA: { url: '/work-in-usa' },
    workinIreland: { url: '/work-in-ireland' },
    workinNewZealand: { url: '/work-in-new-zealand' },
    workinSingapore: { url: '/work-in-singapore' },
    workinMalaysia: { url: '/work-in-malaysia' },
    resumeTemplate2: { url: '/resume-template-2' },
    resumeTemplate3: { url: '/resume-template-3' },
    resumeTemplate4: { url: '/resume-template-4' },
    FullStackJobs: { url: '/full-stack-jobs/:KEYWORD_URL' },
    FullStackDeveloperJobsInIndia: { url: '/full-stack-developer-jobs-in-india' },

    // resumeTemplate3: {url: '/resume-template-3'},
    // resumeTemplate4: {url: '/resume-template-4'},
    // FullStackJobs: {url: '/full-stack-jobs/:KEYWORD_URL'},
    // FullStackDeveloperJobsInIndia: {url: '/full-stack-developer-jobs-in-india'},


    commonSearchQue: { url: '/interview-search-questions/' },
    commonSearchQuestion: { url: '/interview-search-questions/:Keyword' },
    commonSearchAnswer: { url: '/interview-questions/:url' },
    ads: { url: '/ads' },
    messageById: { url: '/recruiters-message' },
    PMRozgarMela: { url: '/pm-rozgar-mela' },
    Swiggy: { url: '/company/swiggy-16264' },
    SwiggyJobs: { url: '/swiggy-jobs' },
    SwiggyAboutUs: { url: '/swiggy-about-us' },
    SwiggyLife: { url: '/swiggy-life' },
    updateTemplate01: { url: '/template-update01' },
    updateTemplate02: { url: '/template-update02' },
    updateTemplate03: { url: '/template-update03' },
    updateTemplate04: { url: '/template-update04' },
    updateTemplate05: { url: '/template-update05' },
    updateTemplate06: { url: '/template-update06' },
    unsubscribe: { url: '/unsubscribe/:CANDIDATE_ID' },
    updateTemplate07: { url: '/template-update07' },

    // Hiring Solutions
    RemoteResources:{url:'/remote-resources'},
    FreelanceResources:{url:'/freelance-resources'},
    RecruitmentProcessOutsourcing:{url:'/recruitment-process-outsourcing'},
    CampusPlacement:{url:'/campus-placement'},
    ApplicationTrackingSystem:{url:'/application-tracking-system'},
    LearningManagementSystem:{url:'/learning-management-system'},
    ManagedHrServices:{url:'/management-services'},
    CustomSolutions:{url:'/custom-solutions'},
    EmployerBrandingSolutions:{url:'/employer-branding-solutions'},
    TechnicalCourses:{url:'/technical-courses'},
    ManagementCourses:{url:'/management-courses'},
    LeadershipCourses: {url:'/leadership-courses'},
    AstrologyHoroscopeGuidance: {url:'/astrology-horoscope-based-guidance'},
    OurUsp: {url:'/our-usp'},
    CaseStudies: {url:'/case-studies'},
    Clients: {url:'/clients'},
    Products: {url:'/products'},
    ContactUs: {url:'/contact-us'},
    RequestCallback:{url:'/request-callback'}
}

const title = {
    Homepage: "Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Mounttalent.com",
    MobileSearch: "Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Mounttalent.com",
    Joblist: "Jobs List",
    MostPopularVideoJdJob:"MostPopular Video JD Jobs List - Mounttalent.com",
    MostLikedVideoJdJob:"MostLiked Video JD Jobs List - Mounttalent.com",
    HotSectorVideoJdJob:"HotSector Video JD Jobs List - Mounttalent.com",
    MNCJoblist: "MNC Jobs List - Mounttalent.com",
    PartTimeJoblist: "Part Time Jobs List - Mounttalent.com",
    WalkInJobs: "Walk in Jobs List - Mounttalent.com",
    RemoteJoblist: "Remote Jobs List - Mounttalent.com",
    WorkFromHomeJobsList: "Work From Home Jobs List - Mounttalent.com",
    FresherJoblist: "Freshers Jobs List - Mounttalent.com",
    InternationalJoblist: "International Jobs List - Mounttalent.com",
    Signin: "Signin",
    CityOverview: "City Overview - Mounttalent.com",
    Register: "Register",
    CreateJobAlert: "Create Job Alert - Mounttalent.com",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    Companieslist: "All Companies - Mounttalent.com",
    Companydetails: "Company Details",
    Jobbycity: "Job By City",
    JobsInNoida: "jobs in Noida",
    Blog: "Blog | Mounttalent.com",
    BlogDetail: "Blog Details",
    Faqs: "FAQ | Mounttalent.com",
    JobsByLocation: "Browse Jobs by Location - Search Jobs in State/City - Mounttalent.com",
    JobsByCompany: "Browse Jobs by Company - Jobs in Top Companies - Mounttalent.com",
    JobsByCategory: "Browse Jobs by Functional Area/Industry - Mounttalent.com",
    JobsByDesignation: "Browse Jobs by Designation - Mounttalent.com",
    JobsBySkill: "Browse Jobs by IT/Non-IT Skills - Mounttalent.com",
    AboutUs: "About Us - Mounttalent.com",
    PrivacyPolicy: "Privacy Policy",
    TermsConditions: "Terms Conditions",
    MyRozgar: "My Mount Talent",
    EditProfile: "Edit Profile",
    SavedJobs: "Saved Jobs | Mounttalent.com",
    ProfilePerformance: "Profile Performance | Mounttalent.com",
    InboxMessage: "Inbox Message | Mounttalent.com",
    RecommendedJobs: "Recommended Jobs",
    ApplicationStatus: "Application Status",
    CreateRozgarProfile: "FQA Details",
    ProfilePreview: "Profile Preview",
    faqCategory: "FAQ | Mounttalent.com",
    changePassword: "ChangePassowrd | Mounttalent.com",
    faqDetail: "FAQ | Mounttalent.com",
    AddAnswer: "Add Answer | Mounttalent.com",
    recommendedJobsList: "All Recommended Jobs | Mounttalent.com",
    Enquiry: 'We are Working on this | Mounttalent.com',
    BySkill: 'By Skills | Mounttalent.com',
    ShareAnInterview: 'Share An Interview | Mounttalent.com',
    AllJobs: ' Browse Jobs by Company, Location, Skills, Designation & Industry | Mounttalent.com',
    topcompanies: `Top Companies - Mounttalent.com`,
    fulltimehiring: 'fulltime jobs - Mounttalent.com',
    contracttualstaffing: 'contracttual-staffing - Mounttalent.com',
    hrmanagementsystem: 'Human Resource Management System - Mounttalent.com',
    marketingtechnology: 'marketing-technology - Mounttalent.com',
    PersonalRecruiter: 'Get your own Personal Recruiter @ 4999 Only | Mount Talent',
    payrollautomation: 'payroll-automation - Mounttalent.com',
    startupincubation: 'startup-incubation - Mounttalent.com',
    DiscussionForum: 'Discussion Forum | Mounttalent.com',
    UpgradeSkills:'Upgrade Skills | Mounttalent.com',
    CompanyBrandings: 'Company Brandings | Mounttalent.com',
    SponsoredJds:'Sponsored JDs | Mounttalent.com',
    HrTechSolutions:'HR Tech Solutions | Mounttalent.com',
    ContractHiring:'Contract Hiring | Mounttalent.com',
    CreateMicrosites:'Create Microsites | Mounttalent.com',
    PremiumJds:'Premium Jds | Mounttalent.com',
    CustomerRelationshipManagement:'Customer Relationship Management | Mounttalent.com',
    MockInterview:'Mock Interview | Mounttalent.com',

    thankYou: 'Thank-You - Mounttalent.com',
    StudyAbroad: 'Study Abroad - Mounttalent.com',
    AdmissionAssistance: 'Admission Assistance - Mounttalent.com',
    studyAbroadScholarship: 'Study Abroad Scholarship -Mounttalent.com',
    DiscussionForumCategory: 'career | Category | Discussion Forum | Mounttalent.com',
    homeAllQuestions: 'Interview Question | Mounttalent.com',
    homeBySkill: 'By-Skill | Interview Questions | Mounttalent.com',
    homeByCompany: 'By-Company | Interview Questions | Mounttalent.com',
    homeByRole: 'By-Role | Interview-questions | Mounttalent.com',
    interviewQuestion: ' skills | Interview Questions | Mounttalent.com',
    TestPreparation: 'Test-Preparation - Mounttalent.com',
    travelGuidance: 'Travel-Guidance - Mounttalent.com',
    visaApplicationAssistance: 'Visa Application Assistance - Mounttalent.com',
    coursesAdviceGuidance: 'Courses Advice Guidance - Mounttalent.com',
    studyAbroadCounselling: 'Study Abroad Counselling - Mounttalent.com',
    visaCoverLetter: 'Visa Cover Letter - Mounttalent.com',
    sopWritingServices: 'SOP Writing Services - Mounttalent.com',
    lorWritingServices: 'LOR Writing Services - Mounttalent.com',
    resumeWritingServices: 'Resume Writing Services - Mounttalent.com',
    studyInAustralia: 'Study in Australia - Mounttalent.com',
    studyInCanada: 'Study in Canada - Mounttalent.com',
    studyInUk: 'Study in UK - Mounttalent.com',
    studyInUsa: 'Study in USA - Mounttalent.com',
    studyInItaly: 'Study in Italy - Mounttalent.com',
    studyInIreland: 'Study in Ireland - Mounttalent.com',
    studyInNewZealand: 'Study In New Zealand - Mounttalent.com',
    studyInSingapore: 'Study In Singapore - Mounttalent.com',
    interviewQuestionCompany: 'Company | Interview Questions | Mounttalent.com',
    ReportIssue: 'Report-a-problem - Mounttalent.com',
    internationalWorkVisas: 'International Work Visas -Mounttalent.com',
    interviewAnswer: 'Question | Interview Question | Mounttalent.com',
    commonInterviewAnswer: 'Questions | Interview Question | Mounttalent.com',
    careerAstrology: 'Career Astrology - Mounttalent.com',
    StudentsExplorer: 'Career Explorer - Mounttalent.com',
    CareerExplorerDetail: 'Career Explorer Detail- Mounttalent.com',
    ResumeViewOne: 'Resume View - Mounttalent.com',
    ResumeViewTwo: 'Resume View - Mounttalent.com',
    ResumeViewThree: 'Resume View - Mounttalent.com',
    ResumeTemplateView01: 'Resume Template View - Mounttalent.com',
    ResumeTemplateView02: 'Resume Template View - Mounttalent.com',
    careerReport1Year: 'Career Report 1 Year - Mounttalent.com',
    remedialSolutionForCareer: 'Remedial Solution For Career - Mounttalent.com',
    strengthReadingForCareer: 'Strength Reading For Career - Mounttalent.com',
    careerAsk1Question: 'Career Ask 1 Question - Mounttalent.com',
    careerAsk3Question: 'Career Ask 3 Question - Mounttalent.com',
    careerReport2Year: 'Career Report 2 Year - Mounttalent.com',
    careerReport3Year: 'Career Report 3 Year - Mounttalent.com',
    careerReport5Years: 'Career Report 5 Year - Mounttalent.com',
    workinAustralia: 'Work in Australia - Mounttalent.com',
    workinCanada: 'Work in Canada - Mounttalent.com',
    educationLoan: 'Education Loan - Mounttalent.com',
    workinUK: 'Work in UK - Mounttalent.com',
    workinUSA: 'Work in USA - Mounttalent.com',
    workinIreland: 'Work in Ireland - Mounttalent.com',
    workinNewZealand: 'Work in New Zealand - Mounttalent.com',
    workinSingapore: 'Work in Singapore - Mounttalent.com',
    workinMalaysia: 'Work in Malaysia - Mounttalent.com',
    JobSearchIndia: 'Job Search India | Mounttalent.com',

    ResumeMaking: 'Resume Making - Mounttalent.com',
    TemplatePreview: 'Template Preview - Mounttalent.com',
    TemplatePreview01: 'Template Preview01 - Mounttalent.com',
    TemplatePreview02: 'Template Preview02 - Mounttalent.com',
    TemplatePreview04: 'Template Preview04 -Mounttalent.com',
    TemplatePreview05: 'Template Preview05 - Mounttalent.com',
    TemplatePreview06: 'Template Preview06 - Mounttalent.com',
    TemplatePreview07: 'Template Preview07 - Mounttalent.com',
    TemplateEdit: 'Create Free CV - Template-01 - Mounttalent.com',
    TemplateEdit01: 'Create Free CV - Template-02 - Mounttalent.com',
    TemplateEdit02: 'Create Free CV - Template-03 - Mounttalent.com',
    TemplateEdit04: 'Create Free CV - Template-04 - Mounttalent.com',
    TemplateEdit05: 'Create Free CV - Template-05 - Mounttalent.com',
    TemplateEdit06: 'Create Free CV - Template-06 - Mounttalent.com',
    TemplateEdit07: 'Create Free CV - Template-07 - Mounttalent.com',
    discussionForumDetails: 'Discussion Forum Details -Mounttalent.com',
    ResumePreview: 'Resume Preview - Mounttalent.com',
    ResumePreview01: 'Resume Preview 01 - Mounttalent.com',
    ResumePreview02: 'Resume Preview 02 - Mounttalent.com',
    Threadtitle: 'Threadtitle | Discussion Forum | Mounttalent.com',
    page404: 'Not Found',
    interviewQuestionByDesignation: ':Designation | Designation | Interview Question | Mount Talent',
    resumeTemplate1: 'Resume Template one - Mounttalent.com',
    resumeTemplate2: 'Resume Template Two - Mounttalent.com',
    resumeTemplate3: 'Resume Template Three - Mounttalent.com',
    resumeTemplate4: 'Resume Template Four - Mounttalent.com',

    Swiggy: 'Swiggy - Mounttalent.com',
    SwiggyJobs: 'Swiggy Jobs - Mounttalent.com',
    SwiggyAboutUs: 'Swiggy About Us - Mounttalent.com',
    SwiggyLife: 'Swiggy Life - Mounttalent.com',
    SeoKeywordsJob: 'Seo Keywords Job - Mounttalent.com',
    jobsinIndia: 'Full Stack Developer Jobs in India | Mounttalent.com',
    PMRozgarMela: 'PM Rozgar Mela | Mounttalent.com',
    TemplateUpdate01: 'Create Free CV - Template-01 -Mounttalent.com',
    TemplateUpdate02: 'Create Free CV - Template-02 -Mounttalent.com',
    TemplateUpdate03: 'Create Free CV - Template-03 -Mounttalent.com',
    TemplateUpdate04: 'Create Free CV - Template-04 -Mounttalent.com',
    TemplateUpdate05: 'Create Free CV - Template-05 -Mounttalent.com',
    TemplateUpdate06: 'Create Free CV - Template-06 -Mounttalent.com',
    TemplateUpdate07: 'Create Free CV - Template-07 -Mounttalent.com',

    RemoteResources: 'Remote Resources - Mounttalent.com',
    FreelanceResources: 'Freelance Resources - Mounttalent.com',
    RecruitmentProcessOutsourcing: 'Recruitment Process Outsourcing - Mounttalent.com',
    CampusPlacement: 'Campus Placement - Mounttalent.com',
    ApplicationTrackingSystem: 'Application Tracking System - Mounttalent.com',
    LearningManagementSystem: 'Learning Management System - Mounttalent.com',
    ManagedHrServices: 'Managed HR Services - Mounttalent.com',
    CustomSolutions: 'Custom Solutions - Mounttalent.com',
    EmployerBrandingSolutions: 'Employer Branding Solutions - Mounttalent.com',
    TechnicalCourses: 'Technical Courses - Mounttalent.com',
    ManagementCourses: 'Management Courses - Mounttalent.com',
    LeadershipCourses: 'Leadership Courses - Mounttalent.com',
    AstrologyHoroscopeGuidance: 'Astrology Horoscope Guidance - Mounttalent.com',
    OurUsp: 'Our USP - Mounttalent.com',
    CaseStudies: 'Case Studies - Mounttalent.com',
    Clients: 'Clients - Mounttalent.com',
    Products: 'Products - Mounttalent.com',
    ContactUs: 'Contact Us - Mounttalent.com',
    RequestCallback:'Request Callback - Mounttalent.com'
}

const keys = {
    region: 'region.Mounttalent.com',
    token: 'tk.Mounttalent.com',
    userDetails: 'ud.Mounttalent.com',
    ctoken: 'ctk.Mounttalent.com',
    cd: 'cd.Mounttalent.com',
    cLoggedIn: 'logged_in.Mounttalent.com',
    c_remeber_me: 'remeber.Mounttalent.com',
    loginData: 'loginData',
    JobUrl: 'JobUrl',
    addAndUpdate: 'addAndUpdate',
    Url: 'Url',
    register: 'register'
}

const version = {
    web: 1.0
}

const currency = {
    indianRupee: '₹'
}
const build = {

    version: 'Mount Talent-Version 3.1.1'


}
const metaDescription = " - Search, find and apply to job opportunities at Mounttalent.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at sales@mounttalent.com"
export default { component, keys, version, currency, title, build, metaDescription }

