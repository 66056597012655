import React, { Component } from 'react'
import { unsubscribe } from '../../action/common';
import constant from '../../constant';
import Homepage from '../home/homepage';
export default class Unsubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        unsubscribe(this.props.match.params.CANDIDATE_ID)
        this.props.history.push(constant.component.homepage.url)
   
    }
    render() {
        return (
            <React.Fragment>
                
                <Homepage />

            </React.Fragment>
        )
    }
}
