import React, { Component } from 'react'
import Clients from '../../components/DiscoverUs/Clients'
import constant from '../../constant'
export default class Clientss extends Component {
    constructor(porps){
        super(porps);
        this.state = {

        }

    }
    componentDidMount(){
        document.title =  constant.title.Clients
    }
  render() {
    return (
      <React.Fragment>
        <Clients/>
      </React.Fragment>
    )
  }
}
