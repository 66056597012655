import React, { Component, Suspense } from 'react'
import { Helmet } from 'react-helmet';
import { cityAnOverView } from '../../action/cityOverview';
import Shimmer from '../../components/common/Shimmer';
// import CityOverview from '../../components/jobbycity/cityOverview';
import constant from '../../constant'
import { capFirstLetterInSentence } from '../../utils';
const CityOverview = React.lazy(() => import('../../components/jobbycity/cityOverview'));
export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cityDetail: undefined,
            jobsCount: undefined,
            localities: [],
            jobs: [],
            showShimmer: true
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        
        cityAnOverView(this.props.match.params.city).then(res => {
            if (res.status) {
                this.setState({ cityDetail: res.result.cityDetail, jobsCount: res.result.jobsCount, localities: res.result.localities, jobs: res.result.jobs, showShimmer: false })
                document.title = res.result.cityDetail.CITY + ' - An Overview'
            }
            else {
                alert(res.error)
            }

        }).catch(err => {
            alert(err)
        })
    }
    render() {
        console.log("paramssssss>>>>>>>", this.props.match.params);
        const { cityDetail,
            jobsCount,
            localities,
            jobs,
            showShimmer } = this.state
        return (
            <React.Fragment>
                <Suspense fallback={<div><Shimmer /></div>}>
                <Helmet >


{/* <title title={capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}>{ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}</title> */}
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<link rel="canonical"  href={window.location.href} />
<meta name="referrer" content="no-referrer-when-downgrade" />

<meta property="og:site_name" content="Mounttalent.com" />
<meta property="og:title"content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))} />
<meta property="og:description" content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<meta property="og:url" content= {window.location.href}  />
<meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" /><br/>

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))} />
<meta name="twitter:description"  content={ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
<meta name="twitter:url"content= {window.location.href} />
<meta name="twitter:image"  content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@mounttalent_india" />
</Helmet>
                    <CityOverview
                        cityDetail={cityDetail}
                        jobsCount={jobsCount}
                        localities={localities}
                        jobs={jobs}
                        showShimmer={showShimmer}
                    />
                </Suspense>

            </React.Fragment>
        )
    }
}
