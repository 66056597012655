import React, { Component } from 'react'
import OurUsp from '../../components/DiscoverUs/OurUsp'
import constant from '../../constant'
export default class OurUsps extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

    }
    componentDidMount(){
        document.title = constant.title.OurUsp
    }
  render() {
    return (
      <React.Fragment>
        <OurUsp/>
      </React.Fragment>
    )
  }
}
