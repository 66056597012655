import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { IQTotalCompanyById, IQTotalCountById, IQTotalDesignationById, IQTotalSkillById, jobCountByTopCategory, KeywordSearch, LocationSearch, statistics, topCompanyImages, topCompanyList, topPremiumFeaturedCompanyList } from '../../action/dashboard';
import constant from '../../constant';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getRandomColorBtn, getStorage, numberWithCommas, onChange, setError, setOptions, validateForm } from '../../utils';
import Carousel from 'react-bootstrap/Carousel'
import { get } from 'react-scroll/modules/mixins/scroller';
import Shimmer from '../common/Shimmer';
import { premiumCityList } from '../../action/jobsByActions';
import FeaturedSlider from '../common/FeaturedSlider/FeaturedSlider';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RozgarLearn from '../LearningHub/courseDetail';
import { style } from '@mui/system';
import networkinglogo from '../../../src/assets/images/Networking.jpg'
import pmrozgarmelapic from '../../assets/images/pm-rozgar-mela.png'
import PolLogo from '../../assets/images/logo/policy-logo.png';
import byjusLogo from '../../assets/images/logo/byjus-logo.png';
import itchotelLogo from '../../assets/images/logo/itchotel-logo.png';
import adityabLogo from '../../assets/images/logo/adityab-logo.png';
import classplusLogo from '../../assets/images/logo/classPlus-logo.png';
import gistechLogo from '../../assets/images/logo/gistech-logo.png';
import zindgitechLogo from '../../assets/images/logo/zindgitech-logo.png';
import lendenctech from '../../assets/images/logo/lendenctech-logo.png';
import jobsConountIcon from '../../assets/images/jobs-con-icons.jpg';
import jobsClientsIcon from '../../assets/images/jobs-cnt-icons.jpg';
import jobsCandidatesIcon from '../../assets/images/jobs-can-icons.jpg';
import jobsRecruitersIcon from '../../assets/images/jobs-rcs-icons.jpg';
import { getPrmiumJobsandfe } from '../../action/CandidateAction';

export default class hometabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCompanies: false,
            premiumService: false,
            employerService: false,
            CITY_LIST: null,
            clientCount: 0,
            tempKeyword: "",
            tempLocation: "",
            LOCATION_LIST: {},
            jobCount: 0,
            candidatesCount: 0,
            recruiterCount: 0,
            setclientCount: 0,
            setjobCount: 0,
            setcandidatesCount: 0,
            setrecruiterCount: 0,
            // KEYWORD: { name: 'KEYWORD', value: [], options: [], error: '', isRequired: false },
            selectedKeyword: [],
            JOB_COUNT_BY_TOP_CATEGORY: this.props?.JOB_COUNT_BY_TOP_CATEGORY ? this.props?.JOB_COUNT_BY_TOP_CATEGORY : null,
            // FEATURED_COMPANIES: null,
            // PREMIUM_COMPANIES: null,
            FEATURED_COMPANIES: this.props?.FEATURED_COMPANIES ? this.props?.FEATURED_COMPANIES : null,
            PREMIUM_COMPANIES: this.props?.PREMIUM_COMPANIES ? this.props?.PREMIUM_COMPANIES : null,
            premium: [],
            // TOP_COMPANY_IMAGES: this.props?.TOP_COMPANY_IMAGES ? this.props?.TOP_COMPANY_IMAGES : [],
            TOP_COMPANY_IMAGES: [
                {
                    "COMPANY_LOGO": "1660117489096.png",
                    "COMPANY_NAME": "POLICYBAZAAR INSURANCE BROKING PRIVATE LIMITED",
                    "URL": "policybazaar-insurance-broking-private-limited"
                },
                {
                    "COMPANY_LOGO": "1656510288618.jpg",
                    "COMPANY_NAME": "BYJU’S",
                    "URL": "byju-s"
                },
                {
                    "COMPANY_LOGO": "1656510261282.jpg",
                    "COMPANY_NAME": "ITC Hotels",
                    "URL": "itc-hotels"
                },
                {
                    "COMPANY_LOGO": "1656510227980.jpg",
                    "COMPANY_NAME": "Aditya Birla Housing Finance Limited",
                    "URL": "aditya-birla-housing-finance-limited"
                },
                {
                    "COMPANY_LOGO": "1656510593208.jpg",
                    "COMPANY_NAME": "Class Plus",
                    "URL": "class-plus"
                },
                {
                    "COMPANY_LOGO": "1656601891661.jpg",
                    "COMPANY_NAME": "GIS Technologies Pvt. Ltd.",
                    "URL": "gis-technologies-pvt-ltd-"
                },
                {
                    "COMPANY_LOGO": "1656602359635.jpg",
                    "COMPANY_NAME": "Health Assure Pvt. Ltd.",
                    "URL": "health-assure-pvt-ltd-"
                },
                {
                    "COMPANY_LOGO": "1656602805792.jpg",
                    "COMPANY_NAME": "Zindgi Technologies",
                    "URL": "zindgi-technologies"
                },
                {
                    "COMPANY_LOGO": "1656603286158.jpg",
                    "COMPANY_NAME": "Industry Buying",
                    "URL": "industry-buying"
                },
                {
                    "COMPANY_LOGO": "1656603945886.jpg",
                    "COMPANY_NAME": "LENDEN CLUB TECHSERVE PRIVATE LIMITED",
                    "URL": "lenden-club-techserve-private-limited"
                },
                {
                    "COMPANY_LOGO": "1656653969747.jpg",
                    "COMPANY_NAME": "MedCords Healthcare Solutions Pvt Ltd.",
                    "URL": "medcords-healthcare-solutions-pvt-ltd-"
                },
                {
                    "COMPANY_LOGO": "1656654487576.jpg",
                    "COMPANY_NAME": "Vlink Inc.",
                    "URL": "vlink-inc-"
                }
            ],
            KEYWORD: { name: 'KEYWORD', value: [], options: [], error: '', isRequired: true },
            LOCATION: { name: 'LOCATION', value: [], options: [], error: '', isRequired: false },
            EXPERIENCE: {
                name: 'EXPERIENCE', value: [], options: [{ value: [0, 1], range: '0-1 Year' },
                { value: [1, 3], range: '1-3 Years' },
                { value: [3, 5], range: '3-5 Years' },
                { value: [5, 7], range: '5-7 Years' },
                { value: [7, 10], range: '7-10 Years' },
                { value: [10, 12], range: '10-12 Years' },
                { value: [12, 14], range: '12-14 Years' },
                { value: [14, 16], range: '14-16 Years' },
                { value: [16, 18], range: '16-18 Years' },
                { value: [18, 20], range: '18-20 Years' },
                { value: [20, 25], range: '20-25 Years' },
                { value: [25, 30], range: '25-30 Years' },
                { value: [30, undefined], range: '30+ Years' },

                ], error: '', isRequired: false
            },
            SALARY: {
                name: 'SALARY', value: [], options: [{ value: [0, 500000], range: '0-5 Lacs' },
                { value: [500000, 1000000], range: '5-10 Lacs' },
                { value: [1000000, 15000000], range: '10-15 Lacs' },
                { value: [15000000, 2000000], range: '15-20 Lacs' },
                { value: [2000000, 25000000], range: '20-25 Lacs' },
                { value: [25000000, 3000000], range: '25-30 Lacs' },
                { value: [3000000, 4000000], range: '30-40 Lacs' },
                { value: [4000000, 5000000], range: '40-50 Lacs' },
                { value: [5000000, 6000000], range: '50-60 Lacs' },
                { value: [6000000, 7000000], range: '60-70 Lacs' },
                { value: [7000000, 8000000], range: '70-80 Lacs' },
                { value: [8000000, 9000000], range: '80-90 Lacs' },
                { value: [9000000, 10000000], range: '90-100 Lacs' },
                { value: [10000000, undefined], range: '1 Cr+' }], error: '', isRequired: false
            },
            error: {},
            QuestionDetails: [13, 58, 11, 1],
            CompanyQuestionDetails: [6, 8, 17, 1, 16284],
            RolesQuestionDetails: [2272, 171, 226, 262],
            QIdSkillCount: [],
            QIdCompanyCount: [],
            QIdRoleCount: [],
        }
    }

    toggleCompanies = () => {
        const { showCompanies } = this.state;
        this.setState({ showCompanies: !showCompanies })
    }
    togglePremiumService = () => {
        const { premiumService } = this.state;
        this.setState({ premiumService: !premiumService })
    }
    toggleEmployerService = () => {
        const { employerService } = this.state;
        this.setState({ employerService: !employerService })
    }

    componentDidMount() {
        this.setState({ jobCount: 1156843, clientCount: 9593, candidatesCount: 12333676, recruiterCount: 98653 })
        let counter = setInterval(this.counter, 1000)
        clearInterval(counter)
        this.getPremiumCityList()
        this.PremiumJobsList()
        // this.getTopCompanyImages()

        this.InterQuestionSkillById()
        this.InterQuestionCompanyById()
        this.InterQuestionDesignationById()
        jobCountByTopCategory(getStorage(constant.keys.region)?getStorage(constant.keys.region):this.props.regionSelect.REGION_ID).then(res => {
            if (res.status) {
                this.setState({ JOB_COUNT_BY_TOP_CATEGORY: res.result })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
        topPremiumFeaturedCompanyList().then(res => {
            if (res.status) {
                this.setState({ FEATURED_COMPANIES: res.result.featured, PREMIUM_COMPANIES: res.result.premium })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
       
        // statistics().then(res => {
        //     if (res.status) {
        //         this.setState({ jobCount: 0, clientCount: 0, candidatesCount: 0, recruiterCount: 0 })
        //         let counter = setInterval(this.counter, 10);
        //     }
        //     else {
        //         alert(res.error)
        //     }
        // }).catch(err => {
        //     alert(err)
        // })

    }
    InterQuestionSkillById = async () => {
        try {
            const { QuestionDetails } = this.state
            //   let allResult = []

            for (let i = 0; i < QuestionDetails.length; i++) {

                const modal = {
                    SKILL_ID: QuestionDetails[i]
                }
                let res = await IQTotalSkillById(modal)
                // allResult[allResult.length]=res.result  
                //  this.setState({QIdSkillCount:res.result})
                if (res.result.length > 0) {
                    this.setState({ QIdSkillCount: [...this.state.QIdSkillCount, ...res.result] })
                }
            }
        } catch (err) {
            console.log("err", err);
        }
        //    this.setState({QIdSkillCount:allResult})

    }

    InterQuestionDesignationById = async () => {
        try {
            const { RolesQuestionDetails } = this.state
            // let allResult = []
            for (let i = 0; i < RolesQuestionDetails.length; i++) {
                const modal = {
                    ROLES: RolesQuestionDetails[i]
                }
                let res = await IQTotalDesignationById(modal)
                // this.setState({QIdDesignationCount:res.result})
                // allResult[allResult.length]=res.result  
                if (res.result.length > 0) {
                    this.setState({ QIdRoleCount: [...this.state.QIdRoleCount, ...res.result] })
                }
            }
        } catch (err) {
            console.log("err", err)
        }
        // console.log("Roles",allResult);
        // this.setState({QIdRoleCount:allResult})
    }

    InterQuestionCompanyById = async () => {

        try {

            const { CompanyQuestionDetails } = this.state

            for (let i = 0; i < CompanyQuestionDetails.length; i++) {

                const modal = {
                    COMPANIES: CompanyQuestionDetails[i]
                }

                let res = await IQTotalCompanyById(modal)
                if (res.result.length > 0) {
                    this.setState({ QIdCompanyCount: [...this.state.QIdCompanyCount, ...res.result] })
                }
            }


        } catch (err) {
            console.log("err", err)
        }

        console.log("company", this.state.QIdCompanyCount);
        // this.setState({QIdCompanyCount:allResult})
    }
    PremiumJobsList = () => {
        this.setState({ showLoader: true })
        getPrmiumJobsandfe(getStorage(constant.keys.region)?getStorage(constant.keys.region):this.props.regionSelect.REGION_ID).then(res => {
            if (res.status) {
                this.setState({ showLoader: false })
                setTimeout(() => {
                    this.setState({ showShimmer: false })
                }, 1000)
                this.setState({ list: res.result.premiumJobList })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }
    getTopCompanyImages = () => {
        topCompanyImages().then(res => {
            if (res.status) {
                this.setState({ TOP_COMPANY_IMAGES: res.result.images })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    getPremiumCityList = () => {
        premiumCityList(getStorage(constant.keys.region)?getStorage(constant.keys.region):this.props.regionSelect.REGION_ID).then(res => {
            if (res.status) {
                this.setState({ LOCATION_LIST: res.result, CITY_LIST: res.result ? res.result.city : null })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    counter = () => {
        const { setclientCount, setjobCount, setcandidatesCount, setrecruiterCount, jobCount, clientCount, candidatesCount,
            recruiterCount } = this.state
        if (setclientCount < clientCount) {
            this.setState({ setclientCount: setclientCount + 575 })
        }
        if (setjobCount < jobCount) {
            this.setState({ setjobCount: setjobCount + 9543 })
        }
        if (setcandidatesCount < candidatesCount) {
            this.setState({ setcandidatesCount: setcandidatesCount + 3458 })
        }
        if (setrecruiterCount < recruiterCount) {
            this.setState({ setrecruiterCount: setrecruiterCount + 9956 })
        }
    }

    onKeywordChange = (e) => {
        const val = e.map(item => {
            if (typeof item === 'object') {
                return item.label
            }
            else {
                return item
            }
        })

        onChange(this, this.state.KEYWORD.name, val)
    }
    onLocationChange = (e) => {
        const val = e.map(item => {
            if (typeof item === 'object') {
                return item.label
            }
            else {
                return item
            }
        })

        onChange(this, this.state.LOCATION.name, val)
    }

    onKeywordPress = (input, TYPE) => {
        if (TYPE === 'KEYWORD') {
            KeywordSearch(input).then(res => {
                if (res.status) {
                    setOptions(this, this.state.KEYWORD.name, res.result)
                }
                else {
                    alert(res.error)
                }
            }).catch(err => {
                alert(err)
            })
        }
        if (TYPE === 'LOCATION') {
            LocationSearch(input).then(res => {
                if (res.status) {
                    setOptions(this, this.state.LOCATION.name, res.result)
                }
                else {
                    alert(res.error)
                }
            }).catch(err => {
                alert(err)
            })
        }
    }

    typeaheadOnChange = (e) => {
        let { value, name } = e.target
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
        this.onKeywordPress(e)

    }


    // onKeywordInputChange(e) {
    //     this.setState({
    //         customKeyword: e
    //     });
    // }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps == nextState) {
            return false;
        } else {
            return true
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        if (validateForm(this)) {

            const st = this.state;
            const model = {
                KEYWORD: st.KEYWORD.value.split(','),
                LOCATION: st.LOCATION.value.split(','),
                EXPERIENCE: {
                    MIN: '',
                    MAX: '',
                },
                SALARY: {
                    MIN: '',
                    MAX: ''
                }
            }
        }
    }

    validateKeyForm = () => {
        let data = this.state
        let error = {}
        let isValid = true

        if (!data['keyword']) {
            error.KEYWORD = "Please enter valid keyword"
            isValid = false

            this.setState({
                error: error
            })

            return isValid
        }
    }
    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }

    onSearch = (e) => {

        e.preventDefault()
        const { KEYWORD, LOCATION, EXPERIENCE, SALARY } = this.state;

        if (validateForm(this)) {
            let MINEXP = ''
            let MAXEXP = ''
            if (EXPERIENCE.value.length) {
                let exp = EXPERIENCE.value.split(',')
                if (exp && exp.length > 0) {
                    MINEXP = exp[0]
                    MAXEXP = exp[1]
                }
            }

            let MINSALARY = ''
            let MAXSALARY = ''
            if (SALARY.value.length) {
                let sal = SALARY.value.split(',')
                if (sal && sal.length > 0) {
                    MINSALARY = sal[0]
                    MAXSALARY = sal[1]
                }
            }
            let url = ''
            if (KEYWORD.value.length) {

                url = constant.component.searchjob.url + `?keyword=${KEYWORD.value}`

            }

            if (LOCATION.value.length) {
                url = url + `&location=` + LOCATION.value
            }
            if (EXPERIENCE.value.length) {
                url = url + `&exp=` + MINEXP + '-' + MAXEXP

            }
            if (SALARY.value.length) {
                url = url + `&sal=` + MINSALARY + '-' + MAXSALARY

            }

            window.location.href = url

        }


    }
    onClickSkill = (id, data) => {

        // localStorage.setItem("setSkillId",id)
        // this.props.history.push(constant.component.interviewQuestionBySkillsId.url.replace(":id",data))
    }

    onJobSearch(e) {

        let keyword = e.target.value
        let { KEYWORD, LOCATION } = this.state

        console.log("state>>>>", this.state);
        if (e.target.placeholder == "Location") {

            let location = e.target.value
            this.setState({ tempLocation: location })
            if (e.code == "Enter") {
                if (this.state.tempKeyword != "" || this.state.KEYWORD.value.length > 0) {
                    let LocationUrl;
                    if (this.state.tempSkill != "") {

                        LocationUrl = `${constant.component.searchjob.url}?location=${keyword}&keyword=${this.state.tempSkill}`
                    }
                    else if (this.state.KEYWORD.value.length > 0) {
                        let [first] = this.state.KEYWORD.value
                        console.log("first keyword ", first);
                        LocationUrl = `${constant.component.searchjob.url}?location=${keyword}&keyword=${first}`
                    }

                    window.location.href = LocationUrl
                    //  window.open(LocationUrl ,"_blank")
                } else {
                    if (this.state.KEYWORD.value.length == 0)
                        setError(this, this.state.KEYWORD.name, " ")
                }
            }
            return
        }

        if (e.target.placeholder.trim() == "Enter Skill, Company, Designation") {
            let url = ""
            this.setState({ tempSkill: keyword })
            setError(this, this.state.KEYWORD.name, "")
            if (this.state.tempLocation != "" && keyword && e.code == "Enter") {
                let LocationUrl = `${constant.component.searchjob.url}?location=${this.state.tempLocation}&keyword=${keyword}`
                window.location.href = LocationUrl
            }
            else if (keyword && e.code == "Enter" && this.state.tempLocation == "") {
                let url = constant.component.searchjob.url + `?keyword=${keyword}`
                window.location.href = url
                // window.location.reload()
            }


        }

        else if (KEYWORD.value.length > 0) {
            let keywords = []
            let LocationUrl = ""

            if (e.code == "Enter") {
                if (KEYWORD.value.length > 1) {

                    Promise.all(KEYWORD.value.map(ele => keywords.push(ele)))
                    LocationUrl = LocationUrl + `${constant.component.searchjob.url}?keyword=${keywords}`

                } else if (KEYWORD.value.length === 1) {
                    if (LOCATION.value.length == 0) {
                        let [first] = this.state.KEYWORD.value
                        LocationUrl = LocationUrl + `${constant.component.searchjob.url}?keyword=${first}`
                        window.location.href = LocationUrl
                    } else {
                        let [firstLocation] = this.state.LOCATION.value
                        let [first] = this.state.KEYWORD.value

                        LocationUrl = LocationUrl + `${constant.component.searchjob.url}?keyword=${first}&location=${firstLocation}`
                        window.location.href = LocationUrl

                    }

                }

                else if (LOCATION.value.length > 0) {
                    if (e.type = "Enter") {
                        if (KEYWORD.value.length == 0) {
                            setError(this, this.state.KEYWORD.name, " ")
                        }
                    }
                }

            }
        }



    }


    render() {

        const { KEYWORD, LOCATION, EXPERIENCE, SALARY, error, QIdSkillCount, QIdRoleCount, QIdCompanyCount } = this.state;
        const { showCompanies, employerService, premiumService, setclientCount, setjobCount, setcandidatesCount, setrecruiterCount,
            JOB_COUNT_BY_TOP_CATEGORY, FEATURED_COMPANIES, PREMIUM_COMPANIES, premium, TOP_COMPANY_IMAGES, CITY_LIST } = this.state;
        const { LOCATION_LIST } = this.props;

        console.log("CompanyQuestionDetails", this.state.CompanyQuestionDetails, QIdCompanyCount);
        const featuredsettings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        dots: false,
                        arrows: false
                    }
                },

                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: false
                    }
                }
            ]

        };

        const newsfeaturedsettings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            slidesToShow: 7,
            rows:2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: false
                    }
                }
            ]
        };

        const premiumsettings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        dots: false,
                        arrows: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: false
                    }
                }
            ]
        };





        return (

            <main id="rg-main" className="rg-main rg-haslayout">
                <section className='mobilehView'>
                    <div className='mobilehide full-screen'>
                        <div className="rg-wrapper">
                            <div className="rg-search mobsearch">
                                <input type="button" class="form-control" value="Enter Skill, Company, Designation" onClick={() => { this.props.history.push(constant.component.MobileSearch.url) }} />

                                {/* {
                                    <Typeahead
                                        className={KEYWORD.error.length ? 'form-control is-invalid' : 'form-control'}
                                        id='keyword'
                                        useCache={false}
                                        clearButton={false}
                                        multiple={true}
                                        allowNew={true}
                                        name={KEYWORD.name}
                                        selected={KEYWORD.value}
                                        options={KEYWORD.options}
                                        placeholder="Enter Skill, Company, Designation"
                                        onInputChange={(e) => { this.onKeywordPress(e, 'KEYWORD') }}
                                        onChange={(e) => { this.onKeywordChange(e) }}
                                        emptyLabel
                                    />
                                } */}
                                <div className="icon">
                                    <i className='lnr lnr-magnifier'></i>
                                </div>
                            </div>
                        </div>

                        <div className='rg-heading'>
                            <h2>Most Preferred<br />Job Search Partner</h2>
                            <p>Access 2 Lakh + Jobs</p>
                        </div>

                        <div className='rg-mobietext'>
                            <Link className='btnlogin' to={constant.component.signin.url}>Login</Link>
                            <Link className="btnregisternow" to={constant.component.register.url}>Register Now</Link>
                        </div>
                    </div>
                </section>

                <section id="section1" className="rg-haslayout rg-sectionspace pt-0 pb-1">
                    <div className="container" id="container1">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-content">
                                    <div className="banner-title">
                                        <h1>Looking for a Job?</h1>
                                    </div>
                                    <div className="serech-over">
                                        <span>400K + jobs for you to explore</span>
                                    </div>
                                    <div className="banner-search-form">
                                        <form>
                                            <div className="row g-0">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" placeholder="Enter Skill, Company, Designation" />
                                                        <i className="lnr lnr-magnifier"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-6 p-0">
                                                    <div className="form-group">
                                                        <input className="form-control Slocation" type="text" placeholder="Location" />
                                                        <i className="ti-location-pin"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-6 p-0">
                                                    <div className="form-group">
                                                        <select className="form-select form-control">
                                                            <option selected>Select Experience</option>
                                                            <option value="1">0-1 Year</option>
                                                            <option value="2">1-3 Years</option>
                                                            <option value="3">3-5 Years</option>
                                                        </select>
                                                        <i className="lnr lnr-calendar-full"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-6 p-0">
                                                    <div className="form-group style">
                                                        <select className="form-select form-control">
                                                            <option selected>Salary (in lacs)</option>
                                                            <option value="1">0-5 Lacs</option>
                                                            <option value="2">5-10 Lacs</option>
                                                            <option value="3">10-15 Lacs</option>
                                                        </select>
                                                        <i className="fa fa-rupee"></i>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-sm-6">
                                                    <div className="Ssearch-btn">
                                                        <a href='javascript:void();' className="Sdefault-btn">
                                                            <i className="lnr lnr-magnifier"></i>
                                                            Search Jobs
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="trending-keywords">
                                        <ul>
                                            <li>Search <span>252,723</span> new jobs - <span>15,422</span> added in the last 24 hours</li>
                                        </ul>
                                    </div>
                                    <div className="shape-1">
                                        <img src="assets/images/banner/shape-1.png" alt="Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!--************************************
                Featured Jobs Start
        *************************************--> */}
                {/* <FeaturedSlider/> */}
                <section className='home-best-hiring-solution-form'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-12'>
                                <h4 className='best-head'>Get the Best Hiring Solution for your Company</h4>
                            </div>
                            <div className='col-md-3'>
                                <ul className='get-hiring-solution'>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="hsolutions" name="solutions" />
                                            <label class="form-check-label" for="hsolutions">Hiring Solutions</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="ssolutions" name="staffing" />
                                            <label class="form-check-label" for="ssolutions">Staffing Solutions</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="psolutions" name="payroll" />
                                            <label class="form-check-label" for="psolutions">Payroll Solutions</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="recruitment" name="outsourcing" />
                                            <label class="form-check-label" for="recruitment">Recruitment Process Outsourcing</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <div class="form-group">
                                    <label>No. of People to be Hired</label>
                                    <input type="number" name="yourname" class="form-control" placeholder="# of Employees" />
                                </div>
                                <div class="form-group rg-halfformgroup rg-inputwithicon">
                                    <label>How large is your company</label>
                                    <span class="rg-select">
                                        <select>
                                            <option>Select People</option>
                                            <option>10-100 people</option>
                                            <option>100-500 people</option>
                                            <option>500-2000 people</option>
                                            <option>2000+</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div class="form-group">
                                    <input type="number" name="yourname" class="form-control" placeholder="Contact Number" />
                                </div>
                                <div class="form-group">
                                    <input type="text" name="yourname" class="form-control" placeholder="Your Email" />
                                </div>
                                <div class="form-group rg-halfformgroup rg-inputwithicon">
                                    <span class="rg-select">
                                        <select>
                                            <option>Your Role</option>
                                            <option>Leadership/ CEO/ Owner/ Director</option>
                                            <option>Human Resource function</option>
                                            <option>Finance/ Operations</option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                            <div className='col-md-2 d-flex align-items-center'>
                                <button className='btn-best-sumbit btn-black'>Submit</button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='home-banner-area'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-sectionhead">
                                    <h2>Companies Hiring</h2>
                                    <span><em></em></span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-top-companies-hiring">
                                    <ul className="rg-btn-top-companies-hiring">
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={PolLogo} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={byjusLogo} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={adityabLogo} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={classplusLogo} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={zindgitechLogo} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="rg-top-companies-hiring" href="javascript:void(0);">
                                                <img src={lendenctech} />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a className="rg-top-companies-hiring-viewall" href="javascript:void(0);">
                                                View All
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <a data-interception='off' className="rg-btnviewall" target='_blank' href={constant.component.jobsByCompany.url}>View All</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="rg-haslayout rg-sectionspace rg-bglight" id="section2">
                    <div className="container" id="container2">
                        <div className="row" id="row2">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-sectionhead">
                                    <h2>Latest Handpicked jobs for you</h2>
                                    <span><em></em></span>
                                </div>
                            </div>
                            <div className="rg-featuredjobs pr-2">
                                {PREMIUM_COMPANIES === null && <Shimmer />}
                                <div className='row'>
                                    <div className="rg-feature-full-width">
                                        <Slider {...premiumsettings} >
                                            {PREMIUM_COMPANIES !== null && PREMIUM_COMPANIES.map((item, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='col-md-12'>
                                                            <div className="rgcj">
                                                                <h3 className="rgcj-heading">
                                                                    <p className="job-cname">Full Stack Developer</p>
                                                                    <a data-interception='off' target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}>{item.COMPANY_NAME}</a>
                                                                    <div className='job-com-view'>
                                                                        <span className="star">
                                                                            <i className="fa fa-star"></i> <span>4.3</span>
                                                                        </span>
                                                                        <span className="rating">|</span>
                                                                        <span className="reviews">{34} reviews</span>
                                                                    </div>
                                                                </h3>
                                                                <div className="job-c-logo">
                                                                    {item.COMPANY_LOGO === 'NA' ? <h3>{item.COMPANY_NAME.split(' ').map((i) => i.substring(0, 1)).join('')}</h3> : <img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${item.COMPANY_LOGO}`} alt={item.COMPANY_NAME} />}
                                                                </div>
                                                                <ul className="rgcj-need">
                                                                    <li><span title={item.ABOUT_COMPANY.replace('<p>', '')}>{item.ABOUT_COMPANY.length > 50 ? item.ABOUT_COMPANY.substring(0, 50).replace('<p>', '') + '...' : item.ABOUT_COMPANY.replace('<p>', '')}</span></li>
                                                                </ul>
                                                                <div className="rgcj-skils">
                                                                    <div className="sk_list">
                                                                        <span>Design</span>
                                                                        <span>Developer</span>
                                                                        <span>Angular</span>
                                                                        <span>ReactJs</span>
                                                                        <span>PHP</span>
                                                                    </div>
                                                                </div>
                                                                <div className="rg-com-viewjobs">
                                                                    <a data-interception='off' target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}>View Jobs</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <a data-interception='off' className="rg-btnviewall" target='_blank' href={constant.component.jobsByCompany.url}>View All</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="rg-haslayout rg-sectionspace rg-bglight" id="section2">
                    <div className="container" id="container2">
                        <div className="row" id="row2">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-sectionhead">
                                    <h2>Premium Companies Hirings</h2>
                                    <span><em></em></span>
                                </div>
                            </div>
                            <div className="rg-featuredjobs pr-2">
                                {PREMIUM_COMPANIES === null && <Shimmer />}
                                <div className='row'>
                                    <div className="rg-feature-full-width">
                                        <Slider {...featuredsettings} >
                                            {PREMIUM_COMPANIES !== null && PREMIUM_COMPANIES.map((item, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='col-md-12 mob-pad-0'>
                                                            <div className='rgchj'>
                                                                <div className='rgchj-logo'>
                                                                    {item.COMPANY_LOGO === 'NA' ? <h3>{item.COMPANY_NAME.split(' ').map((i) => i.substring(0, 1)).join('')}</h3> : <img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${item.COMPANY_LOGO}`} alt={item.COMPANY_NAME} />}
                                                                </div>
                                                                <div className='rgchj-c-name'>
                                                                    <h3><a data-interception='off' target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}>
                                                                    {item.COMPANY_NAME.length > 23 ? item.COMPANY_NAME.substring(0, 23).replace('<p>', '') + '...' : item.COMPANY_NAME.replace('<p>', '')}</a></h3>
                                                                    <div className='job-comjob-view'>
                                                                        <span className="star">
                                                                            <i className="fa fa-star"></i> <span>4.3</span>
                                                                        </span>
                                                                        <span className="rating">|</span>
                                                                        <span className="reviews">{34} reviews</span>
                                                                    </div>
                                                                </div>
                                                                <div className='rgchj-c-about'>
                                                                    <span title={item.ABOUT_COMPANY.replace('<p>', '')}>{item.ABOUT_COMPANY.length > 30 ? item.ABOUT_COMPANY.substring(0, 30).replace('<p>', '') + '...' : item.ABOUT_COMPANY.replace('<p>', '')}</span>
                                                                </div>
                                                                <div className='rg-comjob-viewjobs'>
                                                                    <a target='_blank' href={constant.component.jobsByCompany.url}>View All</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <a data-interception='off' className="rg-btnviewall" target='_blank' href={constant.component.jobsByCompany.url}>View All</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--************************************
                Featured Jobs End
        *************************************--> */}


                {/* <!--************************************
                Our Professionals Start
        *************************************--> */}
                <section className="rg-haslayout rg-sectionspace" id="section3">
                    <div className="container" id="container3">
                        <div className="row" id="row3">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 pl-0">
                                <div className="rg-ourprofessionals mobilefield">
                                    {JOB_COUNT_BY_TOP_CATEGORY === null && <Shimmer />}
                                    {JOB_COUNT_BY_TOP_CATEGORY !== null && JOB_COUNT_BY_TOP_CATEGORY.length > 0 && JOB_COUNT_BY_TOP_CATEGORY.map((item, index) => {
                                        if (index < 9) {
                                            for (const [key, value] of Object.entries(item)) {
                                                return (<div className="col-12 col-sm-12 col-md-4 col-lg-4 float-left">
                                                    <a target='_blank' href={constant.component.joblist.url.replace(':url', value.url)}>
                                                        <div className="rg-ourprofessional height100 d-flex align-items-center">
                                                            <div className="rg-professionaldetail">
                                                                <div className="rg-popular-caregoriescontent">
                                                                    <div className="rg-popular-caregories-job">
                                                                        <h3 className='showalljob font-weight-600 mb-0'><span>{value.totalJobs}</span></h3>
                                                                    </div>
                                                                    <div className="rg-popular-caregories-text">
                                                                        <h5>{key}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>)
                                            }
                                        }
                                    }
                                    )
                                    }

                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <a href={constant.component.jobsByCategory.url} target='_blank'>
                                    <div className="job-popular-box">
                                        <img src={'./assets/images/jop-popular.jpg'} />
                                        <a data-interception='off' className="btn-tpcategories" target='_blank' href={constant.component.jobsByCategory.url}>VIEW ALL</a>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!--************************************
                Our Professionals End
                *************************************--> */}

                <section id="campusrogar" className="wow fadeInRight" data-wow-duration="1.4s">
                    <div className="container" id="container4">
                        <div className="row" id="row4">
                            <div className="accelerate-your-job row">
                                <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                    <div className="roz-sectionimg">
                                        <img src={'./assets/images/acc-your-job-search.png'} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-10">
                                    <div className="roz-sectiontext">
                                        <h2>Accelerate your job search with premium services</h2>
                                        <p>Services to help you get hired, faster: from preparing your CV, getting recruiter attention, finding the right jobs, and more!</p>
                                        <ul>
                                            <li><i className="fa fa-pencil"></i> Resume writing</li>
                                            <li><i className="fa fa-bolt"></i> Priority applicant</li>
                                            <li><i className="fa fa-file-text"></i> Resume display</li>
                                        </ul>
                                    </div>
                                    <div className="roz-section-by-rozgar d-flex justify-content-center">
                                        <div className="by-rozgar">by Campus Rozgar</div>
                                        <a data-interception='off' className="roz-btn-more" target='_blank' href='https://campus.mounttalent.com/'>Learn More</a>
                                        <p className='paid-services'>Includes paid services</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="counter-stats" className="wow fadeInRight" data-wow-duration="1.4s">
                    <div className="container" id="container5">
                        <div className="row" id="row5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className='statistics-count-box'>
                                    <div className="rg-sectionhead mb-0">
                                        <h2>Rozgar Statistics</h2>
                                        <span><em></em></span>
                                    </div>
                                    <div className='stat-flex-count'>
                                        <div className="stats">
                                            <div className='count-box'>
                                                <img src={jobsConountIcon}/>
                                                {/* <div className="counting" data-count="900000">0</div> */}
                                                <div className="counting" >{numberWithCommas(1156843)}</div>

                                                <h5>Jobs & Counting</h5>
                                            </div>
                                        </div>

                                        <div className="stats">
                                            <div className='count-box'>
                                                <img src={jobsClientsIcon}/>
                                                {/* <div className="counting" data-count="280">0</div> */}
                                                <div className="counting" >{numberWithCommas(9593)}</div>
                                                <h5>Clients</h5>
                                            </div>
                                        </div>

                                        <div className="stats">
                                            <div className='count-box'>
                                                <img src={jobsCandidatesIcon}/>
                                                {/* <div className="counting" data-count="23423434">0</div> */}
                                                <div className="counting" >{numberWithCommas(12333676)}</div>
                                                <h5>Candidates</h5>
                                            </div>
                                        </div>

                                        <div className="stats">
                                            <div className='count-box'>
                                                <img src={jobsRecruitersIcon}/>
                                                <div className="counting" >{numberWithCommas(98653)}</div>
                                                <h5>Recruiters</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!--************************************
                Sponsored Companies Start
        *************************************--> */}

                <section className="rg-haslayout rg-sectionspace rg-bglight" id="section6">
                    <div className="container" id="container6">
                        <div className="row" id="row6">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-sectionhead">
                                    <h2>Featured Sponsored Companies</h2>
                                    <span><em></em></span>
                                </div>
                            </div>
                            <div className="rg-featuredjobs pr-2">
                                {FEATURED_COMPANIES === null && <Shimmer />}
                                <div className='row'>
                                    <div className="rg-feature-full-width">
                                        <Slider {...featuredsettings} >
                                            {FEATURED_COMPANIES !== null && FEATURED_COMPANIES.map((item, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='col-md-12'>
                                                        <div className='rgchj'>
                                                                <div className='rgchj-logo'>
                                                                    {item.COMPANY_LOGO === 'NA' ? <h3>{item.COMPANY_NAME.split(' ').map((i) => i.substring(0, 1)).join('')}</h3> : <img src={`${process.env.REACT_APP_BASE_URL}/company/logo/${item.COMPANY_LOGO}`} alt={item.COMPANY_NAME} />}
                                                                </div>
                                                                <div className='rgchj-c-name'>
                                                                    <h3><a data-interception='off' target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}>
                                                                    {item.COMPANY_NAME.length > 23 ? item.COMPANY_NAME.substring(0, 23).replace('<p>', '') + '...' : item.COMPANY_NAME.replace('<p>', '')}</a></h3>
                                                                    <div className='job-comjob-view'>
                                                                        <span className="star">
                                                                            <i className="fa fa-star"></i> <span>4.3</span>
                                                                        </span>
                                                                        <span className="rating">|</span>
                                                                        <span className="reviews">{34} reviews</span>
                                                                    </div>
                                                                </div>
                                                                <div className='rgchj-c-about'>
                                                                    <span title={item.ABOUT_COMPANY.replace('<p>', '')}>{item.ABOUT_COMPANY.length > 25 ? item.ABOUT_COMPANY.substring(0, 25).replace('<p>', '') + '...' : item.ABOUT_COMPANY.replace('<p>', '')}</span>
                                                                </div>
                                                                <div className='rg-comjob-viewjobs'>
                                                                    <a target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}>View All</a>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <a data-interception='off' className="rg-btnviewall" target='_blank' href={constant.component.jobsByCompany.url}>View All</a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--************************************
                Sponsored Companies End
        *************************************--> */}

                {/* <!--************************************
                Our Professionals Start
        *************************************--> */}
                <section className="rg-haslayout rg-sectionspace" id="section7">
                    <div className="container" id="container7">
                        <div className='worried-about-box' id="worried1">
                            <div className="row" id="row7">
                                <div className='col-md-4'>
                                    <img src={'./assets/images/next-interview-q.jpg'} />
                                </div>
                                <div className='col-md-8'>
                                    <div className='nextinterview'>Worried about your Next Interview?<br/> <span>Start Preparing here with more than 50K Interview Questions</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='interview-questions-box'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='interview-questions-type01'>
                                        <h2>Interview Questions by Role</h2>
                                        <span className='linebelow'><em></em></span>
                                        <div className='content-area'>
                                            <div className='con-role-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionByDesignationId.url.replace(':id', 'software-engineer'),
                                                    state: { DESIGNATION_ID: 2272, DESIGNATION: 'Software Engineer' }
                                                }}>

                                                    <div className="role-area-text">
                                                        <h3><a>Software Engineer</a></h3>
                                                        {/* <span>{QIdRoleCount} questions</span> */}
                                                        <span>{QIdRoleCount && QIdRoleCount.length > 3 ? QIdRoleCount[0].designation : 0} questions</span>
                                                    </div>

                                                    {/* <div className="rg-interviewbyrole">
                                                        <h3><a data-interception='off' href="">Software Engineer</a></h3>
                                                        <span>(4.7K+ questions)</span>
                                                    </div> */}
                                                </Link>
                                            </div>
                                            <div className='con-role-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionByDesignationId.url.replace(':id', 'business-analyst'),
                                                    state: { DESIGNATION_ID: 171, DESIGNATION: 'Business Analyst' }
                                                }}>
                                                    <div className="role-area-text">
                                                        <h3><a>Business Analyst</a></h3>
                                                        {/* <span>(2K+ questions)</span> */}
                                                        <span>{QIdRoleCount && QIdRoleCount.length > 3 ? QIdRoleCount[1].designation : 0} questions</span>

                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='content-area'>
                                            <div className='con-role-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionByDesignationId.url.replace(':id', 'software-engineer'),
                                                    state: { DESIGNATION_ID: 2272, DESIGNATION: 'Software Engineer' }
                                                }}>

                                                    <div className="role-area-text">
                                                        <h3><a>DevOps Manager</a></h3>
                                                        {/* <span>{QIdRoleCount} questions</span> */}
                                                        <span>{QIdRoleCount && QIdRoleCount.length > 3 ? QIdRoleCount[2].designation : 0} questions</span>
                                                    </div>

                                                    {/* <div className="rg-interviewbyrole">
                                                        <h3><a data-interception='off' href="">Software Engineer</a></h3>
                                                        <span>(4.7K+ questions)</span>
                                                    </div> */}
                                                </Link>
                                            </div>
                                            <div className='con-role-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionByDesignationId.url.replace(':id', 'business-analyst'),
                                                    state: { DESIGNATION_ID: 171, DESIGNATION: 'Business Analyst' }
                                                }}>
                                                    <div className="role-area-text">
                                                        <h3><a>Front End Developer</a></h3>
                                                        {/* <span>(2K+ questions)</span> */}
                                                        <span>{QIdRoleCount && QIdRoleCount.length > 3 ? QIdRoleCount[3].designation : 0} questions</span>

                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='interview-questions-in-qa-btn'>
                                            <a data-interception='off' className="rg-iq-btnviewall" href={constant.component.interviewQuestionByDesignation.url}>View All
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='interview-questions-type02'>
                                        <h2>Interview Questions by Skills</h2>
                                        <span className='linebelow'><em></em></span>
                                        <div className='content-area'>
                                            <div className='con-skills-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionBySkillsId.url.replace(':id', 'java'),
                                                    state: { skillId: 13 }
                                                }}>
                                                    <div className="skills-area-text">
                                                        <img src={'./assets/images/java-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>JAVA</a></h3>
                                                                {console.log("this.state", QIdSkillCount && QIdSkillCount.length > 3 ? QIdSkillCount[0].skill : 0)}
                                                                <span>{QIdSkillCount && QIdSkillCount.length > 3 ? QIdSkillCount[0].skill : 0} questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='con-skills-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionBySkillsId.url.replace(':id', 'python'),
                                                    state: { skillId: 58 }
                                                }}>
                                                    <div className="skills-area-text">
                                                        <img src={'./assets/images/python-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>Python</a></h3>
                                                                <span>{QIdSkillCount && QIdSkillCount.length > 3 ? QIdSkillCount[1].skill : 0} questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='content-area'>
                                            <div className='con-skills-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionBySkillsId.url.replace(':id', 'php'),
                                                    state: { skillId: 11 }
                                                }}>
                                                    <div className="skills-area-text">
                                                        <img className='img35' src={'./assets/images/php-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>PHP</a></h3>
                                                                <span>{QIdSkillCount && QIdSkillCount.length > 3 ? QIdSkillCount[2].skill : 0} questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='con-skills-area'>
                                                <Link to={{
                                                    pathname: constant.component.interviewQuestionBySkillsId.url.replace(':id', 'networking'),
                                                    state: { skillId: 1 }
                                                }}>
                                                    <div className="skills-area-text">
                                                        <img  className='img35' src={networkinglogo} alt="image description"/>
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>Networking</a></h3>
                                                                <span>{QIdSkillCount && QIdSkillCount.length > 3 ? QIdSkillCount[3].skill : 0} questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='interview-questions-in-qa-btn'>
                                            <a data-interception='off' className="rg-iq-btnviewall" href={constant.component.interviewQuestionBySkills.url}>View All</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='interview-questions-type03'>
                                        <h2>Interview Questions by Company</h2>
                                        <span className='linebelow'><em></em></span>
                                        <div className='content-area'>
                                            <div className='con-com-area'>
                                                <Link
                                                    to={{
                                                        pathname: constant.component.interviewQuestionByCompanyId.url.replace(':id', '-cognizant'),
                                                        // state: { EMPLOYER_ID: 6, EMPLOYER_NAME: 'Cognizant' }
                                                    }}>
                                                    <div className="com-area-text">
                                                        <img className="incombylogo" src={'./assets/images/cognizant-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>Cognizant</a></h3>
                                                                <span>{QIdCompanyCount && QIdCompanyCount.length > 4 ? QIdCompanyCount[0].company : 0} Questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='con-com-area'>
                                                <Link
                                                    to={{
                                                        pathname: constant.component.interviewQuestionByCompanyId.url.replace(':id', 'accenture'),
                                                        // state: { EMPLOYER_ID: 8, EMPLOYER_NAME: 'Accenture' }
                                                    }}>
                                                    <div className="com-area-text">
                                                        <img className="incombylogo" src={'./assets/images/acc-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>Accenture</a></h3>
                                                                <span>{QIdCompanyCount && QIdCompanyCount.length > 3 ? QIdCompanyCount[1].company : 0}  Questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='content-area'>
                                            <div className='con-com-area'>
                                                <Link
                                                    to={{
                                                        pathname: constant.component.interviewQuestionByCompanyId.url.replace(':id', 'byju-s'),
                                                        // state: { EMPLOYER_ID: 17, EMPLOYER_NAME: 'byjus'.toUpperCase() }
                                                    }}>
                                                    <div className="com-area-text">
                                                        <img className="incombylogo" src={'./assets/images/byju-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover'>Byjus</a></h3>
                                                                <span>{QIdCompanyCount && QIdCompanyCount.length > 3 ? QIdCompanyCount[2].company : 0}  Questions</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='con-com-area'>
                                                <Link
                                                    to={{
                                                        pathname: constant.component.interviewQuestionByCompanyId.url.replace(':id', 'tata-consultancy-service'),
                                                        // state: { EMPLOYER_ID: 1, EMPLOYER_NAME: 'tcs'.toUpperCase() }
                                                    }}>
                                                    <div className="com-area-text">
                                                        <img className="incombylogo" src={'./assets/images/tcs-logo.jpg'} alt="image description" />
                                                        <div className="rg-professionalcontent">
                                                            <div className="rg-professionalname">
                                                                <h3><a data-interception='off' className='rg-onMouseHover' >TCS</a></h3>
                                                                <span>{QIdCompanyCount && QIdCompanyCount.length > 3 ? QIdCompanyCount[3].company : 0}  Questions</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='interview-questions-in-qa-btn'>
                                            <a data-interception='off' className="rg-iq-btnviewall" href={constant.component.interviewQuestionByCompany.url}>View All</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--************************************
                Our Professionals End
        *************************************--> */}

                <section id="section8">
                    <div class="container" id="container8">
                        <div className='mobileappbg' id="mobile1">
                            <div class="row" id="row8">
                                <div class="col-xs-12 col-sm-12 col-md-7">
                                    <h3>Get Rozgar app link on your mobile</h3>
                                    <p>Available for both Android and iOS apps</p>
                                    <div class="appsearch">
                                        <input type="text" placeholder="Enter mobile number..." />
                                        <div class="button-src">
                                            <Link to={{
                                                pathname: constant.component.Enquiry.url.replace(":Enquiry", 'Get Rozgar app link on your mobile'),
                                                state: { title: 'Get Rozgar app link on your mobile' }
                                            }}><button>Get&nbsp;link</button></Link>
                                        </div>
                                    </div>
                                    <strong class="applogo">
                                        <a data-interception='off'>
                                            {/* <Link to={{
                                            pathname: constant.component.Enquiry.url.replace(":Enquiry", 'companies-review'),
                                            state: { title: 'Company Reviews' }
                                        }}> */}
                                            <img src={'./assets/images/android-img.png'} alt="Android Logo" />


                                        </a> &nbsp;
                                        <a data-interception='off'>
                                            {/* <Link to={{
                                            pathname: constant.component.Enquiry.url.replace(":Enquiry", 'companies-review'),
                                            state: { title: 'Company Reviews' }
                                        }}> */}
                                            <img src={'./assets/images/apple-img.png'} alt="Android Logo" />
                                        </a>
                                    </strong>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-5 text-left">
                                    <div className=''>
                                        <img src={'./assets/images/appdev.png'} alt="App Development" />
                                    </div>
                                </div>
                            </div>
                            <div className='appshaperight'>
                                <img src={'./assets/images/app-shape-right.png'} />
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!--************************************
            Blogs News Article Start
        *************************************--> */}
                <section className="rg-location-section rg-haslayout" id="section9">
                    <div className="container" id="container9">
                        <div className="row" id="row9">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-10">
                                <div className='roz-jobbylocation-area'>
                                    <div className="rg-sectionhead">
                                        <h2>Jobs by Location</h2>
                                        <span><em></em></span>
                                    </div>

                                    <div className="roz-jobbylocation">
                                        <ul>

                                            {LOCATION_LIST === null && <Shimmer />}
                                            {
                                                LOCATION_LIST !== null &&
                                                CITY_LIST !== null && CITY_LIST.length > 0 && CITY_LIST.map((item, index) => {
                                                    if (index < 30) {
                                                        return (
                                                            <li><a data-interception='off' target='_blank' href={constant.component.joblist.url.replace(':url', item.URL)}> Jobs in {item.CITY.length > 15 ? item.CITY.substring(0, 13) + '..' : item.CITY}</a></li>

                                                        )
                                                    }
                                                })}
                                            {/* <li><a href="#"><i className="lnr lnr-map-marker"></i> Jobs in Mumbai</a></li> */}
                                        </ul>
                                    </div>
                                    <div className='text-right'>
                                        <a data-interception='off' className="rg-btnviewall" target='_blank' href={constant.component.jobsByLocation.url}>View All</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--************************************
                Blogs News Article End
        *************************************--> */}
                <section className='news-media-area'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-sectionhead">
                                    <h2>We are Featured in Media</h2>
                                    <span><em></em></span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="rg-news-media-hiring">
                                    <div className="rg-btn-news-media-hiring">
                                        <Slider {...newsfeaturedsettings} >
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/ani-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/insa-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/news18-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/republic-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/cnbc-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/tv9-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/financeE-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/panjabK-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/dainikJ-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/timeofindia-logo.jpg' />
                                            </div>

                                            <div>
                                                <img className='newslogo' src='./assets/images/media/timesN-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/dailyH-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/Znews-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/nbtimes-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/ACN-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/evtB-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/newsX-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/indiaahead-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/sahara-logo.jpg' />
                                            </div>
                                            <div>
                                                <img className='newslogo' src='./assets/images/media/BW-logo.jpg' />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        )
    }
}
