import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class CreateMicrosites extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-full-time-hiring-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Create Microsites</h4>
                                <p>Developing Premium Pages For Enhanced Performance </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>What Are Microsites?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>For businesses to grow in the cutthroat digital world, a microsite works as a boosting elixir. A microsite is a standalone webpage with a specific function. Microsites, in contrast to main or general web pages, typically strategically target a specific audience and encourage them to take action.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>Why Microsites Are Useful?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>They have the potential to be potent instruments that aid businesses in achieving robust goals and elevating their marketing and communication strategies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Key Features Of Our Microsites</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>To Provide A Dynamic Experience</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We create a user-friendly, experience-based website using microsites. This means that users spend more time being directed toward your call to action rather than figuring out how to access the information they need or, worse, sifting through a lot of information they don't need.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Optimising Enterprise Communications</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Microsites are ideal for maintaining effective communication. They can also be used for internal communication. By making it simple for everyone to access the information they require when they need it, they can simplify communication. They let you make the most of highly interactive elements like videos, quizzes, or audio, unlike presentations or video calls.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Beyond Target Audience</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>You can create niche user experiences for new marketing segments by curating highly engaging content-based marketing, bridging the gap between specific user interests and your brand. Additionally, there is no need to alter campaign strategies that are successful with your more conventional base.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Simplifying The Primary Website</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Your primary website's excessive clutter can decrease engagement. The typical user visits a website for less than fifteen seconds. To maximise user engagement and concentrate on creating opportunities for action, you should take advantage of any opportunities you have to quickly make information accessible to the right people.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Fast Growth And Rapid Implementation</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>A microsite, in contrast to other methods for creating campaign and communication materials, can be distributed and put into use as soon as it is created, allowing you to edit, revamp, and expand as you see fit. Growth-drive design is the name that is given to this strategy. You can use it effectively to tweak the microsite's user experience by utilising real-time analytics. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
    </React.Fragment>
    )
  }
}
