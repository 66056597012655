import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class aboutUs extends Component {
  render() {
    return (
		<React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-Cstudy-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Company Overview</h4>
                                <p>Intelligent Enterprise Solutions For The Future</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Company Overview</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Mount Talent Consulting is a reputed name in delivering end-to-end top HR services. We have mastered the art of maintaining<br/>quality and reliability with the dedication and passion of our experienced and qualified team members.<br/>MTC ensures complete satisfaction by aiming for high-performance standards.</p>
										<p className='font-18'>Our HR services are tailored according to our individual client's needs and requirements, thereby ensuring value for money.<br/>Our global presence, consistency, niche expertise, and technical advancement distinguished us from others.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm' style={{background:'#fff'}}>
                    <div className='container'>
                        <div className='row mt-5 is-active justify-content-center' id='slide01'>
                            <div className='usp-top-box' style={{width:'98%'}}>
                                <div className='main-head-box'>
                                    <h3>Our Distinguishing Expertise</h3>
                                    <div className='feature-line'>
                                        <span className='animate-bar'></span>
                                    </div>
                                </div>
                                <div className='usp-main-item'>
                                    <div className='usp-item-box color-one'>
                                        <div className='icon-img'><img src='./assets/images/ab1.png' /></div>
                                        <h4>14 Years of Experience</h4>
                                    </div>
                                    <div className='usp-item-box color-two'>
                                        <div className='icon-img'><img src='./assets/images/ab2.png' /></div>
                                        <h4>97% Happy Clients</h4>
                                    </div>
                                    <div className='usp-item-box color-three'>
                                        <div className='icon-img'><img src='./assets/images/ab3.png' /></div>
                                        <h4>We are a Global 24*7</h4>
                                    </div>
									<div className='usp-item-box color-three'>
                                        <div className='icon-img'><img src='./assets/images/ex3.png' /></div>
                                        <h4>Technologically Deep Bench</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<section className='secrtion-bg-gray pddding-top-btm'>
					<div className='container'>
						<div className='row mt-5 is-active' id='slide01'>
							<div className='col-md-6 col-sm-4 mb-5'>
								<div className='one-side-image-bx'>
									<img src='./assets/images/staff-augmentation2.jpg' className='img-fluid' />
								</div>
							</div>
							<div className='col-md-6 col-sm-4  mb-5'>
								<div className='content-page-box'>
									<div className='main-head-box'>
										<h3>Our Offered Services</h3>
										<div className='feature-line'>
											<span className='animate-bar'></span>
										</div>
									</div>
									<p className='fa-18'>Being one of the top leading HR consultancies, Mount Talent Consulting offers a wide range of HR-related services. We expertise in –</p>
									<ul className='list-offer'>
										<li>Full-Time Hiring</li>
										<li>3rd Party Payroll Management</li>
										<li>Employee Management</li>
										<li>Staff Augmentation</li>
										<li>Campus Placement Solutions</li>
										<li>Recruitment Process Outsourcing</li>
									</ul>
									<p>We not only master in taking full care of our client's HR needs and functionalities, but we also extend our support to help them grow. Thanks to our client-centric strategic working approach, we ensure delivering 100% satisfaction to cope with the advancements in the HR industry.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Why Choose MTC?</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Fast Turnaround Time</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We assure providing you with consistent, relevant, and the best HR services within no time</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>100% Guaranteed Satisfaction And Accuracy</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Unless and until you are satisfied, we won't stop. We will make it right and accurate with our consistent, dedicated efforts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Leadership & Intelligence</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our dedicated cross-functional leadership team combines their experience in the nascent technology business.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Our Team Of Experts</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>We have the best professional team of experts offering consistent HR solutions with their industry-oriented experience</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Technological Understanding</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our hard-working and experienced teams of blockchain enthusiasts have worked with 30+ projects in Cryptocurrency and blockchain development</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-six'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>24*7 Support And Assistance</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Expect nothing other than 24*7 support and assistance from our experts. We make sure we are always available for you!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-seven'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Cutting Edge Marketing</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our dedicated marketing team has expertise in the leading variety of ICO, STO, & other projects, ranging from healthcare and agriculture to human resources. We ensure to deliver a successful crowd-funding campaign and much more assistance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-eight'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Client-Focused Approach</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Have specific HR needs? Don't worry; trust our client-focused approach! We guarantee reliable results to let you count on our services again and again.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 ' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='../assets/images/faqs2.jpg' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>FAQs</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <div className='faqscontent'>
                                        <div>
                                            <input type='checkbox' id='question1' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question1' className='question'>
                                                Which company is best for recruitment?
                                            </label>
                                            <div className='answers'>
                                                <p>There are a lot of companies in the business but Mount Talent Consulting stands distinguished with its <b>full time hiring services in Noida. </b>With our industry-oriented experience and expertise, we assure offering immediate <b>full time jobs hiring immediately.</b> </p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question2' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question2' className='question'>
                                                What is a hiring service?
                                            </label>
                                            <div class="answers">
                                                <p>In simple words, hiring is about identifying, sourcing, evaluating, and recruiting compatible professionals to extend the workforce within the organization.
                                                </p>
                                                <p>If you are looking for reliable <b>full time jobs hiring</b> services, get in touch with us. Our teams of expert professionals are completely dedicated to offer the required assistance.
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question3' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question3' className='question'>
                                                What is full-time direct hire?
                                            </label>
                                            <div class="answers">
                                                <p>Full time direct hire is the process where a company offers a job and employs the compatible candidate, for the permanent positions, without the involvement of any 3rd party. It assures better stability and work performance of the candidates.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question4' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question4' className='question'>
                                                Is hiring and recruitment the same?
                                            </label>
                                            <div class="answers">
                                                <p>Hiring is to seek and evaluate a candidate to fill a specific position within the company. On the other hand, recruitment is about the continuous search of the best talents for the company.</p>
                                                <p>Mount Talent Consulting is one of the leading names to offer complete assistance in finding the relevant <b>full time remote work from home jobs</b> opportunities.
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question5' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question5' className='question'>
                                                What is the difference between direct hire and agency?
                                            </label>
                                            <div class="answers">
                                                <p>In direct hire, there isn’t any role of the 3rd party for finding the qualified candidate for the job position. Hiring through an agency involves the consistent efforts of reputed staffing agencies like Mount Talent Consulting to get <b>full time hiring services in India.</b>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
