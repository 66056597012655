import React, { Component } from 'react'
import Signin from '../../components/signin/signin'
import constant from '../../constant';
import { capFirstLetterInSentence, getStorage, setsessionStorage, setStorage } from '../../utils';
import swal from 'sweetalert'
import { candidateLogin, googleLoginAuth } from '../../action/CandidateAction'
import Signinwithotp from '../../components/signin/signinwithotp';
import { Helmet } from 'react-helmet';
import { emailClicked } from '../../action/common';

export default class signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'EMAIL',
      loginDatas: null,
      showLoader: false,
      detail: getStorage(constant.keys.cd),
      data: localStorage.getItem('JobUrl')
    }
  }
  componentDidMount() {
    const id = this.props.location.search.slice(1)
    if (id) {
      emailClicked(id)
    }
    document.title = constant.title.Signin
    window.scrollTo(0, 0)
    let auth = getStorage(constant.keys.ctoken)
    let cd = getStorage(constant.keys.cd)
    if (auth && cd) {
      this.props.history.push(constant.component.editProfile.url)
    }
  }

  onSubmit = (model) => {
    const Url = this.state.data
    this.setState({ showLoader: true })
    candidateLogin(model).then((res) => {
      this.setState({ showLoader: false })
      if (res.status) {
        this.setState({ showLoader: false })
        swal({
          icon: "success",
          text: "You have Successfully Logged In",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false
        });
        this.props.history.push(constant.component.editProfile.url)
      } else {
        this.setState({ showLoader: false })
        swal({
          icon: "error",
          text: res.error,
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    });
  }
  changeTab = (tab) => {
    this.setState({ tab: tab })
  }
  googleLoginHandler = (googleData) => {
    // const saveJobId = this.state.data
    // const {JOB_ID} = this.state.addUpdate ? this.state.addUpdate : ''
  
    const {JOB_ID} = this.props.location.state && this.props.location.state.Job_Id ? this.props.location.state.Job_Id : ''
    if(JOB_ID){
      setsessionStorage('addAndUpdate',this.props.location.state.Job_Id)
    }
    const saveJobId=this.props.location.state && this.props.location.state.saveJobId ? this.props.location.state.saveJobId : ''
    if(saveJobId){
      setsessionStorage('saveJobId',this.props.location.state.saveJobId)
    }

    googleLoginAuth(googleData).then((res) => {
      if (res.status) {
        this.setState({
          loginDatas: res
        })
        let auth = getStorage(constant.keys.ctoken)
        if(JOB_ID && auth){
          this.props.history.push(constant.component.recommendedJobs.url)
        }
        else if(saveJobId && auth){
          this.props.history.push(constant.component.savedJobs.url)
        }
        else{
          this.props.history.push(constant.component.editProfile.url)
        }
      } else {
        swal({
          icon: "error",
          text: res.error,
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    });
  }

  render() {
    const { tab } = this.state;
    return (
      <React.Fragment>
        <Helmet >


          <meta name="HandheldFriendly" content="True" />
          <meta name="Keywords" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
          <meta name="description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
          <link rel="canonical" href={window.location.href} />
          <meta name="referrer" content="no-referrer-when-downgrade" />

          <meta property="og:site_name" content="Mounttalent.com" />
          <meta property="og:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
          <meta property="og:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
          <meta property="og:image:width" content="4000" />
          <meta property="og:image:height" content="6000" /><br />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
          <meta name="twitter:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + constant.metaDescription} />
          <meta name="twitter:url" content={window.location.href} />
          <meta name="twitter:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Smita Nag" />
          <meta name="twitter:label2" content="Filed under" />
          <meta name="twitter:data2" content="Career Advice, Career Insights" />
          <meta name="twitter:site" content="@Mount Talent_india" />
        </Helmet>
        {tab === 'EMAIL' && <Signin
          changeTab={(tab) => this.changeTab(tab)}
          leftBar={this.props.leftBar}
          onSubmit={this.onSubmit}
          googleLoginHandler={this.googleLoginHandler}
          loginData={this.props.loginDatas}
          showLoader={this.state.showLoader}
        />}
        {tab === 'PHONE' && <Signinwithotp
          changeTab={(tab) => this.changeTab(tab)}
          leftBar={this.props.leftBar}
          onSubmit={this.onSubmit}
          googleLoginHandler={this.googleLoginHandler}
          loginData={this.props.loginDatas}
          showLoader={this.state.showLoader}
        />}

      </React.Fragment>
    )
  }

}
