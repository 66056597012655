import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../constant';
import Chatbot from '../home/ChatBot';
import logo from '../../assets/images/logo.png'
import { getGlobalSetting } from "../../action/dashboard";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MasterData: undefined,
            readmoreText:false,
            readlessText:false,
            hideMoreText:true,
            hidelessText:false
        }
    }
    componentDidMount() {
        getGlobalSetting().then(res => {
            if (res.status) {
                this.setState({ MasterData: res.result })
            }
            else {
                console.log(res.error)
            }
        })

    }

    readmoretext=()=>{
        this.setState({readmoreText:!this.state.readmoreText, hideMoreText:!this.state.hideMoreText, hidelessText:true})
    }

    readLessText=()=>{
        this.setState({readlessText:!this.state.readlessText, hidelessText:!this.state.hidelessText,readmoreText:!this.state.readmoreText,hideMoreText:!this.state.hideMoreText })
    }
    
    render() {
        const { MasterData } = this.state
        return (
            <React.Fragment>
                <footer id="rg-footer" className="rg-footer rg-haslayout">
                    <div className="rg-fourcolumns rg-bglight" id="mobilehide">
                        <div className="container">
                            <div className="row">
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Explore jobs</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.jobsBySkill.url}>Jobs by skill</Link></li>
                                            <li><Link target='_blank' to={constant.component.jobsByDesignation.url}>Jobs by designation</Link></li>
                                            <li><Link target='_blank' to={constant.component.jobsByCompany.url}>Jobs by company</Link></li>
                                            <li><Link target='_blank' to={constant.component.jobsByCategory.url}>Jobs by category</Link></li>
                                            <li><Link target='_blank' to={constant.component.jobsByLocation.url}>Jobs by location</Link></li>
                                            <li><Link target='_blank' to={constant.component.CreateJobAlert.url}>Create a Free Job Alert</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Jobs in demand</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.RemoteJobs.url}>Remote jobs</Link></li>
                                            <li> <Link target='_blank' to={constant.component.WorkFromHomeJobs.url}>Work from home jobs</Link></li>
                                            <li> <Link target='_blank' to={constant.component.WalkInJobs.url}>Walk-in jobs</Link></li>
                                            <li><Link target='_blank' to={constant.component.PartTimeJobs.url}>Part-time jobs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Video JDs</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li> <Link target='_blank' to={constant.component.MostPopularVideoJdJob.url}>Most Popular</Link></li>
                                            <li> <Link target='_blank' to={constant.component.HotSectorVideoJdJob.url}>Hot Sectors</Link></li>
                                            <li> <Link target='_blank' to={constant.component.MostLikedVideoJdJob.url}>Most Liked</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Freshers jobs</h3></div>
                                    <div className="rg-widgetcontent">
                                       <ul>
                                      <li><a target='_blank' href={'https://campus.rozgar.com/'}>Latest fresher jobs</a></li> 
                                      <li><a target='_blank' href={'https://campus.rozgar.com/'}>Students</a></li>
                                      <li> <a target='_blank' href={'https://campus.rozgar.com/'}>Institution</a></li>
                                      <li><Link target='_blank' to={constant.component.jobsByCompany.url}>Hiring Company</Link></li>
                                      <li> <a target='_blank' href={'https://campus.rozgar.com/'}>Internships</a></li>
                                       
                                       </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetusfulllinks">
                                    <div className="rg-fwidgettitle"><h3>Quick links</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.register.url}>Register</Link></li>
                                            <li><Link target='_blank' to={constant.component.signin.url}>Sign In</Link></li>
                                            <li><Link target='_blank' to={constant.component.homepage.url}>Smart search</Link></li>
                                            <li><Link target='_blank' to={constant.component.discussionForum.url}>Discussion forum</Link></li>
                                            <li><Link target='_blank' to={constant.component.blog.url}>Blog</Link></li>
                                            <li><Link target='_blank' to={constant.component.faqs.url}>FAQs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rg-fourcolumns rg-bglight" id="mobilehide">
                        <div className="container">
                            <div className="row">
                               
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Services</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.fulltimehiring.url}>Full Time Hiring's</Link></li>
                                            <li><Link target='_blank' to={constant.component.ContractHiring.url}>Contract Hiring</Link></li>
                                            <li><Link target='_blank' to={constant.component.RemoteResources.url}>Remote Resource</Link></li>
                                            <li> <Link target='_blank' to={constant.component.RecruitmentProcessOutsourcing.url}>Recruitment Process Outsourcing</Link></li>
                                            <li> <Link target='_blank' to={constant.component.CampusPlacement.url}>Campus Placement</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea" >
                                    <div className="rg-fwidgettitle"><h3>Branding</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li> <Link target='_blank' to={constant.component.CreateMicrosites.url}>Create Microsites</Link></li>
                                            <li> <Link target='_blank' to={constant.component.PremiumJds.url}>Premium JD’s</Link></li>

                                            <li> <a target='_blank' href='https://recruit.mounttalentonline.com'>Search Candidates</a></li>
                                            <li> <Link target='_blank' to={constant.component.companieslist.url}>View All Companies</Link></li>

                                            {/* <li><a href="javascript:void(0)">Company reviews</a></li> */}
                                            {/* <li><a href="javascript:void(0)">Interview questions</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Software</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.CustomerRelationshipManagement.url}>CRM</Link></li>
                                            <li><Link target='_blank' to={constant.component.hrmanagementsystem.url}>HRMS</Link></li>
                                            <li><Link target='_blank' to={constant.component.ApplicationTrackingSystem.url}>ATS</Link></li>
                                            <li>  <Link target='_blank' to={constant.component.LearningManagementSystem.url}>LMS</Link></li>
                                            <li>  <Link target='_blank' to={constant.component.ManagedHrServices.url}>Management Services</Link></li>
                                            <li> <Link target='_blank' to={constant.component.CustomSolutions.url}>Custom Solutions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetjobarea">
                                    <div className="rg-fwidgettitle"><h3>Learning</h3></div>
                                    <div className="rg-widgetcontent">
                                        <ul>
                                            <li><Link target='_blank' to={constant.component.ResumeMaking.url}>Resume Making</Link></li>
                                            <li><Link target='_blank' to={constant.component.MockInterview.url}>Mock Interview</Link></li>
                                            <li> <Link target='_blank' to={constant.component.TechnicalCourses.url}>Tech Courses</Link></li>
                                            <li>  <Link target='_blank' to={constant.component.ManagementCourses.url}>Management Cources</Link></li>
                                            <li><Link target='_blank' to={constant.component.LeadershipCourses.url}>Leadership Courses</Link></li>
                                            <li> <Link target='_blank' to={constant.component.AstrologyHoroscopeGuidance.url}>Horoscope Based Guidance</Link></li>
                                            <li> <Link target='_blank' to={constant.component.interviewQuestion.url}>Interview Question</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="rg-footercol rg-widgetusfulllinks">
                                    <div className="rg-fwidgettitle"><h3>Discover Us</h3></div>
                                    <div className="rg-widgetcontent">
                                    <ul>
                                            <li><Link to={constant.component.aboutUs.url}>About Us</Link></li>
                                            <li><Link target='_blank' to={constant.component.OurUsp.url}>Our USP</Link></li>
                                            <li><Link target='_blank' to={constant.component.CaseStudies.url}>Case Studies</Link></li>
                                            <li>  <Link target='_blank' to={constant.component.Clients.url}>Clients</Link></li>
                                            <li><Link target='_blank' to={constant.component.Products.url}>Products</Link></li>
                                            <li><Link target='_blank' to={constant.component.ContactUs.url}>Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rg-footeraboutus pt-2 pb-2" id="mobilehide">
                        <div className="container">
                            <div className="row">
                                <div className="fivecolom">
                                    <div className="colom5 pl-3">
                                        <a href={constant.component.homepage.url}><img className="roz-logo" src={logo} alt="mounttalent.com" title={constant.build.version} /></a>
                                    </div>
                                    <div className="colom6">
                                        <h3 className="headh4">Let's begin your search</h3>
                                        <div className="rg-widgetcontent">
                                            <ul className='d-flex'>
                                                <li><Link target='_blank' to={constant.component.AllJobs.url}>Explore cities</Link></li>
                                                <li><Link target='_blank' to={constant.component.JobSearchIndia.url}>Job Search India</Link></li>
                                                <li><Link target='_blank' to={constant.component.FullStackDeveloperJobsInIndia.url}>Full&nbsp;Stack&nbsp;Jobs&nbsp;in&nbsp;India</Link></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="colom6">
                                        <h3 className="headh4">Employer Zone</h3>
                                        <div className="rg-widgetcontent">
                                            <ul className='d-flex'>
                                                <li><a target='_blank' href={'https://recruit.mounttalentonline.com/job-post'}>Post a Job</a></li>
                                                <li><a target='_blank' href={'https://recruit.mounttalentonline.com/subscriptions'}>Buy online</a></li>
                                                <li><a target='_blank' href={'https://recruit.mounttalentonline.com/'}>Employer Login</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='discalaimerbox'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <p><span>Disclaimer:</span> <a href="/">mounttalent.com</a> intended only to serve as a preliminary medium of contact and exchange of information for its users / members / visitors who have a bona fide intention to contact and/or be contacted for the purposes
                                    {

                                    } {this.state.hideMoreText && <a href='javascript:void();' onClick={() =>this.readmoretext()} > Read More</a>}{this.state.readmoreText && <span> related to genuine existing job vacancies and for other career enhancement services.  The site is a public site with free access and Mount Talent assumes no liability for the quality and genuineness of responses. <a href="/">mounttalent.com</a> Pte. LTD cannot monitor the responses that a person may receive in response to information he/she has displayed on the site. The individual/company would have to conduct its own background checks on the bonafide nature of all response(s). We keep updating the portal so any queries and concerns please report a problem or check back soon.</span>}{this.state.hidelessText && <a href='javascript:void();' onClick={() =>this.readLessText()} > Less Read</a>}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rg-footerbottom">
                        {/* <a className="rg-btnscrolltop" href=""><i className="fa fa-angle-double-up"></i></a> */}
                        {/* <a className="rg-btnscrolltop" href="javascript:void(0);"><i className="fa fa-angle-double-up"></i></a> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <p className="rg-copyrights">Copyright © 2021-23 mounttalent.com. All Rights Reserved | Powered by : <span><a target='_blank' href="https://valueinnovationlabs.com/" className='powercom'>Value Innovation Labs</a></span></p>
                                    
                                    <nav className="rg-addnav">
                                        <ul>
                                            <li><a target='_blank' href={constant.component.reportIssue.url}>Report an issue</a></li>
                                            <li><Link to={constant.component.privacyPolicy.url}>Privacy policy</Link></li>
                                            <li><Link to={constant.component.termsConditions.url}>Terms &amp; conditions</Link></li>
                                            <li className="rg-facebook"><a target="_blank" href={'https://www.facebook.com/MountTalentOfficial'}><i className="fa fa-facebook-f"></i></a></li>
                                            <li className="rg-twitter"><a target="_blank" href={' https://twitter.com/mount_talent'}><i className="fab fa-twitter"></i></a></li>
                                            <li className="rg-youtube"><a target="_blank" href={'https://www.youtube.com/@mounttalentofficial'}><i className="fab fa-youtube"></i></a></li>
                                            <li className="rg-linkedin"><a target="_blank" href={'https://www.linkedin.com/company/407445/admin/'}><i className="fab fa-linkedin-in"></i></a></li>
                                            {/* <li><a href="javascript:void(0);">Fraud alert</a></li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <Chatbot /> */}
            </React.Fragment>
        )
    }


}
