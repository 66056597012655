import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class ManagementCourses extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-MCouse-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Management Courses</h4>
                                <p>Creating Tomorrow’s Business Leaders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Management Course</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Management courses are not only popular in India but also across the world as it calls for a successful role and higher salary. Mount Talent Consulting is also taking its turn in transforming and developing the world by honing the talents of individuals and turning them into market genius.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <section className='cmt-row about-section clearfix fill-time-hr-section bg-white'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>Management Courses We Provide</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16 text-black pt-3'>Listed below are some of the management courses we provide that are in demand and pay a higher package.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Master of Financial Management</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>A career-oriented master's program in financial management equips students with both current and historical knowledge of accounting and finance management. It encourages students who lack financial management and specialised knowledge to acquire a solid understanding of finance and accounting. MFM is a program that lasts two years and is best suited to applicants who have completed a project management certification or worked in finance, risk management, or both. Monitoring, economic analysis, marketing analysis, audit, organisational planning, and other topics are covered in the course. Candidates should be well-versed in computer applications and possess strong analytical skills. You could work as a financial analyst, investment manager, or even stockbroker after earning your Master's in Financial Management.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Master in Management Studies</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>One of the most lucrative management courses is this one. The Master of Management Studies is a four-semester, two-year postgraduate program that includes internships, practical assignments, and theoretical lectures. The MMS study focuses on the field of management studies and aims to teach graduates about management skills and knowledge. A bachelor's degree in management studies or a related field is required to enrol in the MMS course. Courses leading to a Master's in Management can be continued immediately after graduation and are designed to develop skills in business management and provide appropriate services to those planning careers in the field. The All India Technical Education Council (AICTE), based in New Delhi, has granted permission for the course. The benefits of this course outweigh those of the highest-paying MBA programs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Masters of Business Administration</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Another one of the most sought-after management courses in India focuses on retail management aspects. The Master of Business Administration in Retail Management is a two-year marketing postgraduate program. It examines the most recent theories and approaches in functional management. The Master of Business Administration in Retail Management requires a graduate degree from an accredited university with a minimum score of 45%. This course welcomes applicants from all backgrounds.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Master in Marketing Management</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>One of India's highest-paying management courses aims to prepare students for success in a multicultural and fiercely competitive marketing environment. Through real-world scenarios (such as market analysis, sales teams, promotions, etc.), students can quickly grasp the primary components of the marketing process and learn how to put them into action in an ever-changing market. Candidates will learn how to develop, implement, and expand efficient and profitable marketing programs, as well as current marketing strategies. They will also learn how to adapt to the latest technologies, create client satisfaction and maintenance, and the client and the people on their marketing team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Master in Risk Management</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>A master's degree in risk management will equip you with the most recent risk management concepts and strategies that you can use in any industry, in addition to pursuing online courses and certifications from top providers. You will have access to numerous career opportunities as a result.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
