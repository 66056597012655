import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class Fulltimehiring extends Component {

    render() {

        return (
            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-full-time-hiring-bg rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>Full-Time Hiring</h4>
                                        <p>Boost Your Company Productivity By Hiring The Best Talent </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry />
                <main id="rg-main" className="rg-main rg-haslayout pt-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Skills</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box color-one'>
                                                    <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                                    <h4>Full Access</h4>
                                                    <p>Through our 14+ years of experience and team of talented experts we are the best employee hiring firm in the market. All your needs will be fulfilled with MTC.</p>
                                                </div>
                                                <div className='usp-item-box color-two'>
                                                    <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                                    <h4>Cost And Time Effective</h4>
                                                    <p>World-class services with the best and most reasonable rates and in no time, nobody else has what we do, and we are proud of our customer satisfaction rates.</p>
                                                </div>
                                                <div className='usp-item-box color-three'>
                                                    <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                                    <h4>Simplified Analysis & Solutions</h4>
                                                    <p>When looking to hire an experienced worker, there are a lot of things to take into account. We deal with all issues, meet all requirements, and provide the best skill in the shortest amount of time.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-4  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Services</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p className='fa-18'>Mount Talent Consulting has built a massive name in the world of staffing and recruitment. With our full-time hiring services, we provide the best, most -talented, qualified and professional candidates for our clients. It is for our clients, we have become a prominent name in the recruitment industry with a global presence.</p>
                                            <ul className='list-offer'>
                                                <li>We evaluate the company's requirements and select the most suitable candidate.</li>
                                                <li>Presenting unrivalled career opportunities to employees.</li>
                                                <li>Immediate hiring following comprehension of the company's requirements, culture, industry, and technological setting.</li>
                                                <li>Creating a value proposition for employees to give the company a competitive advantage.</li>
                                                <li>Support for full-time employee retention.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix fill-time-hr-section'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12 text-center'>
                                        <div className='section-title style2 res-991-pb-15'>
                                            <div className='title-header2'>
                                                <h4 className='title'>What Is The Requirement For Full-Time Hiring?</h4>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>To Grow Your Business</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Business growth is done through the best employee making the best workforce and we provide that best employee for you.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Adapting To The Change</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>You need to bring creativity, passion, productivity, and innovation to the team in order to bring new and enhanced ideas to the table. That's what makes a business grow.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Growth In Performance</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Full-time employees are passionate, deadline and consistency-driven, they know the importance of the project and its impact on the organisation's name and make sure to deliver the best creativity.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-four'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Increased Brand Value</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Utilise refined value proposals and compensation structures to raise the brand's value. Why Choose Mount Talent Consulting for Full-Time Hiring Services?</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-five'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Best And Fast Results</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We promise to quickly provide your company with full-time employees who are trustworthy, compatible, and skilled.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-six'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Guaranteed Customer Satisfaction</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We won't stop you from getting what you want because our aim is to make you happy. We will fix any problem by working hard and consistently.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-seven'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Professional Experts</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We only hire the best and most qualified candidates because we have the industry's most experienced recruiters working for us.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-eight'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Global Assistance</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We provide the best service in the market, but it’s not just our services our assistance and support are also top-class. We are live 24*7, and our experts are ready to assist you no matter what.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-nine'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Client-Centric Approach</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Is any particular problem you have or specific needs you want a solution for? Whatever the reason be our services completely customisable, keeping your requirements in the frame.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className='pddding-top-btm bg-white'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='../assets/images/faqs2.jpg' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>
                                                    Which is the best full-time hiring service provider in the market?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Mount Talent Consulting stands out from the crowd thanks to its full-time hiring services in India, even though there are a lot of companies in the recruitment industry. Due to our industry-focused and experienced approach, we guarantee immediate hiring for full-time positions.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>
                                                    What do full-time hiring services mean?
                                                    </label>
                                                    <div class="answers">
                                                        <p>The process of finding, sourcing, evaluating, and recruiting skilled and competent professional employees is referred to as hiring services. If you're looking for dependable full-time job hiring services, get in touch with us right away. Our team of seasoned recruiters is dedicated to providing the required assistance.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>
                                                    Define the difference between full-time and part-time hiring.
                                                    </label>
                                                    <div class="answers">
                                                        <p>Full-time is a permanent job position for a particular role in an organisation. It is a job role with fixed hours and company benefits. Whereas, part-time is a limited-period job position done for an organisation. It is not a fixed position and is only required on specific occasions with no company benefits and flexible scheduling.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
