import React, { Component } from 'react'
import CustomSolutions from '../../components/HrTechConsulting/CustomSolutions'
import constant from '../../constant'
export default class CustomSolutionss extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
    componentDidMount(){
        document.title = constant.title.CustomSolutions
    }
  render() {
    return (
      <React.Fragment>
        <CustomSolutions/>
      </React.Fragment>
    )
  }
}
