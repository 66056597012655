import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class ApplicationTrackingSystem extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-ats-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Applicant Tracking System</h4>
                                <p>Finding The Best Candidate, Building Workforce.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Applicant Tracking System</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Finding The Best Candidate, Building Workforce.</p>
                                        <p className='font-18'>We provide the best ATS application to screen resumes and find the right match for your advertised role.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-12'>
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>What Is An ATS?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>ATS, formally called Applicant Tracking System, is a software that finds keywords in resumes and filters out those that don't fit the job description.<br/>For every organisation, the hiring process can be a time-consuming and hassle-full experience. To simplify the work,<br/>many organisations have started using applicant tracking systems.</p>
                                        <p className='font-16'>ATS is computer software that handles all hiring-related processes, and it does so by collecting and screening thousands or more resumes.</p>
                                        <p className='font-16'>It is via ATS, hiring managers and HR professionals can find the right candidate pool and track their performance progress throughout the process.<br/>By digitalising these hiring processes an employer can save both time and money.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm bg-white'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>How Does Applicant Tracking System Work?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>There are some basic steps through which you will understand how ATS works in finding the right resume.</p>
                                    <ul className='list-offer'>
                                        <li>The ATS receives an employment request. The position's title desired skills, and required experience are all included in this requisition.</li>
                                        <li>The ideal candidate's profile is then created using this data by the ATS.</li>
                                        <li>The applicant tracking system (ATS) parses, sorts, and ranks resumes submitted by candidates based on how closely they match the profile.</li>
                                        <li>The most qualified candidates are then swiftly identified by hiring managers and moved forward in the hiring process.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-12'>
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>How ATS Is Different From CRM?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>Any recruiting company rely on two types of recruiting software. The first is Applicant Tracking System<br/> and the second is Candidate Relationship Management (CRM).</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Customer Relationship Management (CRM)</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>CRM system is used during the recruitment process and its major job is to target those qualified candidates who have not yet applied for the job. The key features of CRM include email marketing, communication with potential candidates and scheduling of interviews.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box'>
                                    <div className='featured-content'>
                                        <div className='featured-title'>
                                            <h3>Applicant Tracking System (ATS)</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>On the other hand, ATS works during the hiring process and its key features include resume parsing, candidate screening and assessment. One feature that significantly distinguishes ATS from CRM is, ATS only tracks candidates that have already applied for the job position.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-12'>
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Why Use Applicant Tracking System?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>In modern times it can be a daunting task for employers to search for the ideal candidates suitable for the company. This is due to the easy process of submitting applications by a job seeker. It could be a very difficult task to find the right candidate, one who has the experience and right qualifications.</p>
                                        <p className='font-16'>Corporate recruiters face this problem a lot and receive more than thousands of resumes for every job posting. It is where the ATS application comes into play. ATS helps screen the right resume with the desired skills, expertise and qualifications. It saves a lot of time for an organisation and finds the ideal employee for the company.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm bg-white'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>What Are The Benefits Of ATS?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>Some of the best advantages of using ATS for your company are:</p>
                                    <ul className='list-offer'>
                                        <li>Facilitation of communication between hiring managers.</li>
                                        <li>Faster screening of applicants.</li>
                                        <li>Decreased time spent on routine activities.</li>
                                        <li>Net hiring score improvement.</li>
                                        <li>Improved candidate participation.</li>
                                        <li>Improved application overview.</li>
                                        <li>Posting jobs quickly.</li>
                                        <li>Cost savings per hire.</li>
                                        <li>Enhanced hiring quality.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
      </React.Fragment>
    )
  }
}
