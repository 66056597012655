import React, { Component } from 'react'
import swal from 'sweetalert';
import { CompanyHiringSolution } from '../../action/CandidateAction';
import ContactMap from '../../assets/images/contact-map.png'
import NumberFormat from 'react-number-format';
import { clearForm, onChange, setError, validateForm } from '../../utils';
import { inquiryForm } from '../../action/jobsByActions';
export default class RequestCallback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: { name: 'FirstName', value: '', error: '', isRequired: true },
            LastName: { name: 'LastName', value: '', error: '', isRequired: true, },
            Mobile: { name: 'Mobile', value: '', error: '', isRequired: true },
            Email: { name: 'Email', value: '', error: '', isRequired: true },
            TextMessage:{name:'TextMessage' , value:'', error:'',  isRequired:false}
           
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (validateForm(this)) {
            const {Email} = this.state;
            let regex =   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(Email.value)) {
                setError(this, Email.name, 'Incorrect personalEmail Id');

                return false;

            }
           
        }

        if(validateForm(this)){    
        const { Mobile,FirstName,LastName ,Email,TextMessage} = this.state;
        const model = {
            EMAIL: Email.value,
            MOBILE: Mobile.value,
            LAST_NAME:LastName.value,
            NAME:FirstName.value,
            MESSAGE : TextMessage.value
        }
        inquiryForm(model).then((res) => {
            if (res.result) {
                swal({
                    icon: "success",
                    text: "Enquiry submitted successfully",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
                clearForm(this)
            }
            else {
                swal({
                    icon: "error",
                    text: "Something went wrong!!",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
        }).catch((err) => {
            console.log(err);
        });}
    }

        onChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            onChange(this, name, value)
        }

       
  render() {
    const {  Mobile,FirstName,LastName ,Email,TextMessage } = this.state;
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-RequestACallback-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Request a Callback</h4>
                                <p>Interested in our services? Let us know what you are looking for?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-10 offset-1">
                                <div className='formboxarea'>
                                    <form className='contactforminput'>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                                <input type='text' 
                                                  value={FirstName.value}
                                                  name={FirstName.name}
                                                  onChange={this.onChange}
                                                  class={FirstName.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                 placeholder="Enter your name"/>
                                                {/* <input type='number' className='inputheight' placeholder="No. of People to be Hired"/> */}
                                            </div>
                                            <div className='form-group'>
                                                <input type='text' 
                                                  value={Mobile.value}
                                                  name={Mobile.name}
                                                  onChange={this.onChange}
                                                  class={Mobile.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                 placeholder="Enter your mobile No."/>
                                                {/* <input type='text' className='inputheight' placeholder="phone number"/> */}
                                            </div>
                                        </div>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                                <input type='text' 
                                                  value={LastName.value}
                                                  name={LastName.name}
                                                  onChange={this.onChange}
                                                  class={LastName.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                 placeholder="Enter your last name"/>
                                            </div>
                                            <div className='form-group'>
                                                <input type='text' 
                                                  value={Email.value}
                                                  name={Email.name}
                                                  onChange={this.onChange}
                                                  class={Email.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                 placeholder="Enter your email id"/>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                                <textarea type='text'
                                                 class=" requestcallheighta"
                                                 value={TextMessage.value}
                                                name={TextMessage.name}
                                                onChange={this.onChange}
                                                style={{height:'100px !important'}}
                                                 placeholder="Type your message here"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="form-btn-con pt-0 mr-3">
                                        <button onClick={this.onSubmit} type="button">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                    <div className='col-md-8 offset-2'>
                        <div className='row'>
                            
                                <div className='col-md-8 offset-2 text-center'>
                                    <img src={ContactMap}/>
                                </div>
                                <div className='col-md-6 maplnoida'>
                                    <h4>India Engineering Center</h4>
                                    <p><i class="ti-location-pin"></i> Head office: A-51, Sector 16, Noida, U.P. - 201301</p>
                                </div>
                                <div className='col-md-6 mapnewyork'>
                                    <h4>USA Office Address</h4>
                                    <p><i class="ti-location-pin"></i> 1123 Broadway, Suite 301, New York, NY 10010</p>
                                </div>
                                <div className='col-md-6 mapsingapore'>
                                    <h4>Singapore Office Address</h4>
                                    <p><i class="ti-location-pin"></i> 68 Circular Road #02-01 Singapore (049422)</p>
                                </div>
                                <div className='col-md-6 mapdubai'>
                                    <h4>Dubai Office Address</h4>
                                    <p><i class="ti-location-pin"></i> Office No. 301, Malak Ibrahim Muhammad Hussain Rai Business Bay, Dubai - 346-466</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
      </React.Fragment>
    )
  }
}
