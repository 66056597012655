import jsPDF from 'jspdf';
import React, { Component } from 'react'
import html2canvas from 'html2canvas';

export default class ResumeTemplate3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
             
        }
    }
    printPDF2 = () => {

		window.html2canvas = html2canvas;
		var doc = new jsPDF({
			orientation: "p",
			unit: "px",
			format: "a4",
		});

		var content = document.getElementById("resume1");
		const width = doc.internal.pageSize.getWidth();
		console.log(width, "wodth")
		doc.html(content, {
			x: 0,
			y: 10,
			width: width,
			autoPaging: 'text',
			windowWidth: 794,
			margin: [15, 0, 20, 0],
			html2canvas: { scale: 0.57 },
		})
			.then(() => {
				doc.save('Resume.pdf');
			});



            
	}
    render() {
        const details = this.props.candidateList
        return (
            <React.Fragment>
                 <button style={{ display: "block", margin: "10px auto", padding: "12px", borderRadius: "5px", color: "#fff", backgroundColor: "red", fontSize: "14px", fontWeight: "500" }} onClick={() => { this.printPDF2() }}>Download Resume</button>

<div>
    <div className="resume-wrapper-inner mx-auto text-start bg-white"
        ref={el => (this.componentRef = el)}
        id={'resume1'}
    >
                <section className="welcome_area demo2 flex align-items-center">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 col-lg-12 col-md-12">
                                <div className='resume-with-three-box'>
                                    <div className='top-hd-social-bx'>
                                        <ul className='top-ul-list-bx'>
                                            <li className='tw-bx' style={{margiLeft:"20px"}}><img src={'./assets/images/twiter-r.png'} alt='Image' />{details.EMAIL_ID}</li>
                                            {/* <li className='tum-bx'><img src={'./assets/images/tumblr.png'} alt='Image' /> brhetoric.tumblr.com</li> */}
                                            <li className='mob-bx' style={{margiLeft:"20px"}}><img src={'./assets/images/mobile-icon.png'} alt='Image' /> {details.PHONENO}</li>
                                            <li className='em-bx' style={{margiLeft:"20px"}}><img src={'./assets/images/email-icon.png'} alt='Image' /> {details.EMAIL_ID}</li>
                                            <li className='add-box' style={{margiLeft:"20px"}}><img src={'./assets/images/address-icon.png'} alt='Image' /> {details.PERMANENT_ADDRESS}</li>

                                        </ul>

                                    </div>
                                    <div className='resume-wrap-bx'>
                                        <div className='resume-right-temp-three-bx'>
                                            <div className='name-user-bx'>
                                                <h4>{details.CANDIDATE_NAME}</h4>
                                                <p>{details.RESUME_HEADLINE}</p>

                                            </div>
                                            <div className='about-user-bx'>
                                                <p>{details.PROFILE_SUMMARY}</p>

                                            </div>
                                            <div className='project-ditails-bx'>
                                               { details.Projects && details.Projects.map((item)=>{
                                                return(
                                               <div className='project-info-bx'>
                                                    <h5>{item.PROJECT_TITLE}</h5>
                                                    <p>{item.PROJECT_DETAILS}</p>
                                                </div>
                                                  )
                                                })
                                                }
                                                {/* <div className='project-info-bx'>
                                                    <h5>Design Project 2</h5>
                                                    <p>An attempt to use gaming in improving the multi tasking capacity as well as decision making capacity</p>
                                                </div>
                                                <div className='project-info-bx'>
                                                    <h5>Design Project 3</h5>
                                                    <p>System Design project with Centre for social justice in Ahmedabad (NGO). The outcome in the form of a comic strip illustrating critical concepts of human rights for the illiterate</p>
                                                </div>
                                                <div className='diploma-project-bx'>
                                                    <h5>Diploma Project</h5>
                                                    <p>Mutliplayer Game Design & Development for Indian Air Force. Architectural Simulation : Application Design & Development. </p>
                                                    <p><span>Architectural Dissertation :</span> Learnings for architects from game designers</p>
                                                    <p><span>Colloquium :</span> Sound design and user experience.</p>
                                                </div> */}
                                                <div className='achievement-sec-box'>
                                                    <h4>Online Profile</h4>
                                                    <ul className='achievement-list-ul-bx'>
                                                       { details.OnlineProfile && details.OnlineProfile.map((item)=>{
                                                        return(
                                                       <li> <p>{item.URL}</p></li>
                                                            
                                                       )
                                                    })
    }
                                                        {/* <li><img src={'./assets/images/typhlo-icon.png'} alt='Image' />  <span>Typhlo Tactus, India Finalist</span></li>
                                                        <li><img src={'./assets/images/challenge-icon.png'} alt='Image' />  <span>Finalist, Student Challenge,
                                                            Indian Institute of Human Settlement, Bangalore</span></li> */}
                                                    </ul>

                                                </div>

                                            </div>





                                        </div>
                                        <div className='right-section-resume-three'>
                                           { details.Education && details.Education.map((item)=>{
                                            return(
                                           <div className='education-three-box'>
                                                <div className='edu-year-bx'>
                                                    <p>{item.PASSING_OUT_YEAR}</p>
                                                </div>
                                                <div className='edu-name-uni-bx'>
                                                    <h5>{item.QUALIFICATION_NAME}</h5>
                                                    <p>{item.COURSE_STREAM}</p>
                                                </div>


                                            </div>
                                             )
                                            })
                                        }
                                            {/* <div className='education-three-box'>
                                                <div className='edu-year-bx'>
                                                    <p>2007-2012</p>
                                                </div>
                                                <div className='edu-name-uni-bx'>
                                                    <h5>Bachelor of Architecture</h5>
                                                    <p>School of Planning and Architecture, Delhi</p>
                                                </div>


                                            </div>
                                            <div className='education-three-box'>
                                                <div className='edu-year-bx'>
                                                    <p>2005-2007</p>
                                                </div>
                                                <div className='edu-name-uni-bx'>
                                                    <h5>12th CBSE - 82.6%</h5>

                                                </div>


                                            </div>
                                            <div className='education-three-box'>
                                                <div className='edu-year-bx'>
                                                    <p>2005-2007</p>
                                                </div>
                                                <div className='edu-name-uni-bx'>
                                                    <h5>10th CBSE - 94.6%</h5>

                                                </div>


                                            </div>
                                            <div className='education-three-box education-three-last'>
                                                <div className='edu-year-bx'>
                                                    <p>1989</p>
                                                </div>
                                                <div className='edu-name-uni-bx'>
                                                    <h5>Earth</h5>

                                                </div>


                                            </div> */}
                                            <div className='professional-experience-bx'>
                                                <h4>Professional Experience</h4>
                                               {details.Experience && details.Experience.map((item)=>{
                                                return(
                                                <div className='experience-item-bx'>
                                                    <h5>{item.CURRENT_DESIGNATION}</h5>
                                                    <h6>{item.CURRENT_COMPANY}</h6>
                                                    <p>{item.WORKING_TILL_DATE_YEAR}</p>

                                                </div>
                                                      
                                                      )
                                                    })
                                            }
                                                {/* <div className='experience-item-bx'>
                                                    <h5>#Experience Designer</h5>
                                                    <h6>Infoedge India Private Limited, 99acres.com</h6>
                                                    <p>February 2015 - Today</p>

                                                </div> */}
                                                {/* <div className='experience-item-bx'>
                                                    <h5>#Interaction Designer/Experience/Game Designer</h5>
                                                    <h6>Threye INC., New Delhi.</h6>
                                                    <p>May 2014 – October 2014 (6 months)</p>

                                                </div> */}
                                                {/* <div className='experience-item-bx'>
                                                    <h5>#Design Intern</h5>
                                                    <h6>Rajiv Agarwal Architects, New Delhi.</h6>
                                                    <p>January 2011 – June 2011 (6 months)</p>

                                                </div> */}

                                                {/* <div className='experience-item-bx'>
                                                    <h5>#Design Intern</h5>
                                                    <h6>Pradeep Roy Associates, New Delhi.</h6>
                                                    <p>May 2010 – July 2010 (3 months)</p>

                                                </div> */}


                                            </div>
                                            <div className='project-twod pt-4'>
                                                    <div className='font-20 pb-3 font-weight-600'>Skills</div>
                                                   { details.Skills && details.Skills.map((item)=>{
                                                    return(
                                                    <ul className='exp-resume-two'>
                                                        <li>{item.SKILL}</li>
                                                    </ul>
                                                     )
                                                    })
                                                    }
                                                </div>
                                            {/* <div className='tools-box'>
                                                <h4>TOOLS</h4>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>UNITY3D</h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>BLENDER <small>MODELLING</small></h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>BLENDER <small>ANIMATION</small></h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>PHOTOSHOP</h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>

                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>INDESIGN</h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>

                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>PROGRAMMING</h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>

                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='tools-item-box'>
                                                    <div className='tools-name'>
                                                        <h4>PHOTOSHOP</h4>
                                                    </div>
                                                    <div className='tools-mark-bx'>
                                                        <ul>

                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='active'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                            <li className='dactive'></li>
                                                        </ul>
                                                    </div>
                                                </div>


                                            </div> */}
                                            <div className='interests-resume-bx'>
                                                <h4>Interests</h4>
                                              
                                                <ul className='interests-list-bx'>
                                                { details.Interest && details.Interest.map((item)=>{
                                                return( 
                                                    <li><span>{item.INTEREST}</span></li>
                                                    )
                                                    })
                                                }
                                                    {/* <li><img src={'./assets/images/playing-games.png'} alt='Image' /> <span>Playing Games</span></li>
                                                    <li><img src={'./assets/images/3D-modelling.png'} alt='Image' /> <span>3D Modelling</span></li>
                                                    <li><img src={'./assets/images/graffiti.png'} alt='Image' /> <span>Making Music</span></li> */}

                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    
                </section>
                </div>
                </div>
            </React.Fragment>
        )
    }
}
