import React, { Component } from 'react'
import CaseStudies from '../../components/DiscoverUs/CaseStudies'
import constant from '../../constant'
export default class CaseStudiess extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.CaseStudies
    }
  render() {
    return (
      <React.Fragment>
        <CaseStudies/>
      </React.Fragment>
    )
  }
}
