import React, { Component } from 'react'
import AstrologyHoroscopeGuidance from '../../components/LearningHub/AstrologyHoroscopeGuidance'
import constant from '../../constant'
export default class AstrologyHoroscopeGuidances extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.AstrologyHoroscopeGuidance
    }
  render() {
    return (
      <React.Fragment>
        <AstrologyHoroscopeGuidance/>
      </React.Fragment>
    )
  }
}
