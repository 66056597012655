import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class ManagedHrServices extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-hrservices-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Management Services</h4>
                                <p>Mount Talent Consulting has made its name in the world</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Management Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Mount Talent Consulting has made its name in the world as the best-managed service provider. We work day and night to<br/>enhance and strengthen your business across the world and in the market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm bg-white'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-12'>
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Learning Management Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Also referred to as LMS, Learning Management System is a premium web-based e-learning service provided by Learning Management System.<br/>LMS easily handles all training, learning and institute-related activities and requirements. LMS caters towards all<br/>institutional or individual online learning needs through streamlined and consistent techniques.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>HRMS</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>HRMS or Human Resource Management System is a cloud-based HR management software designed by the skilled team of MTC. HRMS aids towards<br/>employee retention, employee nurturing, performance-based workforce, employee experience, adapting to changes and so much more.<br/>HRMS provide a one-stop solution to all HR functionings and makes everything easier and more efficacious.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rozgar-distinguishing-expertise bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Recruitment Management System</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Our recruitment management system is a collection of clever integrations and features to speed up and intensify the recruitment process as well as automate the hiring procedure. You can organise interviews, handle candidates, post diverse types of job postings,<br/> search for sourcing channels, and more with our dependable RMS.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Managed Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>We provide a tool that is built to intensify your business bandwidth. Our managed services work in enhancing brand value and making a trustworthy name in the market. With years of industry and a keen knowledge of management, we provide services with instant results. Through MTC’s managed services you can enjoy benefits like migrating, customising business applications on-premises, virtual and hybrid models, and updating software.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='pddding-top-btm bg-white'>
                    <div className='container'>
                        <div className='row mt-5 ' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='../assets/images/faqs2.jpg' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-6  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>FAQs</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <div className='faqscontent'>
                                        <div>
                                            <input type='checkbox' id='question1' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question1' className='question'>
                                                What does Managed Services mean?
                                            </label>
                                            <div className='answers'>
                                                <p>Services are known as Managed services aid in improving, streamlining, and streamlining all aspects of human resources administration.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question2' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question2' className='question'>
                                                What kind of services incorporates Managed Services?
                                            </label>
                                            <div class="answers">
                                                <p>LMS, RMS, HRMS, Managed Services and so much more make up Managed services.</p>
                                            </div>
                                        </div>

                                        <div>
                                            <input type='checkbox' id='question3' name='q' className='questions' />
                                            <div className='plus'>+</div>
                                            <label for='question3' className='question'>
                                                Which is the best HR services provider in the market?
                                            </label>
                                            <div class="answers">
                                                <p>While many businesses offer some of the most well-known managed services, Mount Talent Consulting integrates all of the smart HR Tech services that help a business expand.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
      </React.Fragment>
    )
  }
}
