import React, { Component } from 'react'
import ManagementCourses from '../../components/LearningHub/ManagementCourses'
import constant from '../../constant'
export default class ManagementCoursess extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.ManagementCourses
    }
  render() {
    return (
      <React.Fragment>
        <ManagementCourses/>
      </React.Fragment>
    )
  }
}
