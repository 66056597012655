import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class CustomerRelationshipManagement extends Component {
  render() {
    return (
      <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-full-time-hiring-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Customer Relationship Management</h4>
                                <p>Building Unbreaklabke Relationship</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>What Is A CRM Software?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>Customer Relationship Management, popularly known as CRM, is software that allows smooth management of all interactions between the company and its potential customers. CRM's objective is to enhance business relationships, help companies stay connected with their clients,<br/>streamline productivity and heightened profitability.</p>
                                        <p className='font-16'>CRM software aids towards focused organisational relationships with individual customers, throughout the lifecycle of a business. Along with that<br/>CRM also helps in finding new clients, getting an edge over market competitors and providing additional customer-relationship service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="rozgar-distinguishing-expertise bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>What Is The Importance Of CRM For Your Business?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>CRM software has grown as the largest and fastest-growing enterprise application software. According to a survey, CRM's worldwide<br/>spending is expected to reach somewhere around $114.4 billion by the year 2027.</p>
                                        <p className='font-16'>For a business to grow and last forever, you will require some strategies for the future that highlights the importance of customer relationship with the right technology. Whatever your needs are; sales, marketing, business development, profitability and more, everything can be achieved via CRM.</p>
                                        <p className='font-16'>To grow your business you will need reliable information, and retrieving that can be tricky. How can you summarise the many creeks of data coming in from sales,<br/>customer service, marketing and social media into beneficial business information? Simple, through our CRM software. </p>
                                        <p className='font-16'>Via our CRM system, you can get a transparent review of your customers. You can monitor everything in one place, through a customisable dashboard<br/>that will help you through your customer’s previous history, the status of an order, outstanding customer service, and more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Can You Run Your Business Without CRM?</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-16'>Simply put if you give more time to the administration that means less time for other business goals. In any organisation, an active sales team means a lot of data but without a proper software, this information may get stored in handwritten notes, laptops and maybe in the head of the salesperson.</p>
                                        <p className='font-16'>Without CRM software, details can get lost, meetings might not get streamlined, and prioritising customers can become an extensive task that you might not handle. Without CRM a company’s sales will suffer greatly.</p>
                                        <p className='font-16'>CRM work as a platform for customer support, without it customer interactions and communications will be missed and no business can face that sort of backless. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
    </React.Fragment>
    )
  }
}
