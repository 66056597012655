import React, { Component } from 'react'
import Products from '../../components/DiscoverUs/Products'
import constant from '../../constant'
export default class Productss extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.Products
    }
  render() {
    return (
      <React.Fragment>
        <Products/>
      </React.Fragment>
    )
  }
}
