import React, { Component } from 'react'
import TechnicalCourses from '../../components/LearningHub/TechnicalCourses'
import constant from '../../constant'
export default class TechnicalCoursess extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

    }
    componentDidMount(){
        document.title = constant.title.TechnicalCourses
    }
  render() {
    return (
      <React.Fragment>
        <TechnicalCourses/>
      </React.Fragment>
    )
  }
}
