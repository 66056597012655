import React, { Component } from 'react'
import LearningManagementSystem from '../../components/HrTechConsulting/LearningManagementSystem'
import constant from '../../constant'
export default class LearningManagementSystems extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

    }
    componentDidMount(){
        document.title = constant.title.LearningManagementSystem
    }
  render() {
    return (
      <React.Fragment>
        <LearningManagementSystem/>
      </React.Fragment>
    )
  }
}
