import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class UpgradeSkills extends Component {
  render() {
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-full-time-hiring-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Upgrade Your Skills</h4>
                                <p>Find courses that are best for your career and improve your skills...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">

                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Upgrade Your Skills</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <div className='usp-main-item'>
                                        <div className='usp-item-box color-one'>
                                            <a href=''>
                                                <div className='icon-img2'><img src='./assets/images/JavaFullStack.jpg' /></div>
                                                <h4>Java Full Stack</h4>
                                            </a>
                                        </div>
                                        <div className='usp-item-box color-two'>
                                            <a href=''>
                                                <div className='icon-img2'><img src='./assets/images/pythonfull.jpg' /></div>
                                                <h4>Python Full Stack</h4>
                                            </a>
                                        </div>
                                        <div className='usp-item-box color-three'>
                                            <a href=''>
                                                <div className='icon-img2'><img src='./assets/images/software-testing.jpg' /></div>
                                                <h4>Software Testing</h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </React.Fragment>
    )
  }
}
