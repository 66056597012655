import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class RemoteResources extends Component {
  render() {
    return (
      <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-remote-resources-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Remote Resources</h4>
                                <p>Leading Digital Solutions For Your Company</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Remote Resources</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>A remote resource is a full-time digital service that invests in an end-to-end solution and produces the best digital solutions for your business problems. Through our expert team and dedicated approach, we fabricate unique solutions in conventional and contemporary ways.</p>
                                        <p className='font-18'>Mount Talent Consulting has the best team with the best solution providing quick and tangible results for business growth.</p>
                                        <p className='font-18'>We collaborate to provide superior software solutions in a variety of areas, including web and app development, digital marketing, branding, user experience and user interface design, search engine optimization (SEO), writing, and graphic design.</p>
                                        <p className='font-18'>Satisfying customers, effective communication, adaptability, effectiveness, and openness are the constant priorities. You will always be aware of how and where your money is being spent because we keep the process open to the public.</p>
                                        <p className='font-18'>The remote resource is assisting a variety of business clients.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>Remote Resources We Provide</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <ul className='list-offer'>
                                        <li>Human Resource Technology Solution</li>
                                        <li>Human Resource Management System</li>
                                        <li>Company Branding</li>
                                        <li>Social Media Marketing</li>
                                        <li>Email & Marketing Automation</li>
                                        <li>Lead Generation</li>
                                        <li>Payroll Automation and more</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
      </React.Fragment>
    )
  }
}
