import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { CompanyLists, DepartmentLists, SkillsForInterview, totalCount } from '../../action/ShareInterviewAction';
import ShareAnInterview from '../../components/InterviewQuestions/ShareAnInterview';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';

export default class shareAnInterview extends Component {
    constructor(props){
        super(props);
        this.state = {
          skill_list:undefined,
          departments:undefined,
          totalCount:undefined
        }
    }
 componentDidMount(){
    document.title = constant.title.ShareAnInterview
    this.SkillsList()
    this.CompanyList()
    this.DepartmentLists()
    this.Counting()
 }
 Counting = () => {
  totalCount().then((res)=>{
    this.setState({totalCount:res.result[0]})
  }).catch(err=>alert(err))
 }
 SkillsList = () =>{
  SkillsForInterview().then((res)=>{
    this.setState({skill_list:res.result})
  }).catch(err=>alert(err))
 }

 CompanyList = () => {
  CompanyLists().then((res)=>{
    this.setState({Company_list:res.result})
  }).catch(err=>alert(err))
 }
 DepartmentLists = () => {
  DepartmentLists().then((res)=>{
    this.setState({departments:res.result})
  })
 }

  render() {
    const {skill_list,Company_list,departments,totalCount} = this.state
    return (
      <React.Fragment>
          <Helmet >


<title title={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))}>{capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))}</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + "The most common job interview questions that employers ask, examples of the best answers for each question, and tips for how to prepare and respond."} />
<link rel="canonical" href={window.location.href} />
<meta name="referrer" content="no-referrer-when-downgrade" />

{/* og meta tag */}
<meta property="og:site_name" content="Mounttalent.com" />
<meta property="og:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
<meta property="og:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " The most common job interview questions that employers ask, examples of the best answers for each question, and tips for how to prepare and respond."} />
<meta property="og:url" content={window.location.href} />
<meta property="og:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

{/* Twitter Meta Tag */}
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' '))} />
<meta name="twitter:description" content={capFirstLetterInSentence(window.location.pathname.replace('/', '').split('/').pop().split('-').join(' ')) + " For every student, getting a job in India is the ultimate goal. Nevertheless, there is no clear definition of what 'good' actually means. A person's aspirations and desires ultimately determine their destiny. Search & Apply for Latest Job Vacancies across Top Companies in India. Register FREE Now!"} />
<meta name="twitter:url" content={window.location.href} />
<meta name="twitter:image" content="https://www.mounttalent.com/static/media/logo.ad274a0965d9f8f14efb.png" />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@mounttalent_india" />

</Helmet>
        <ShareAnInterview 
        skill_list={skill_list}
        Company_list={Company_list}
        departments={departments}
        totalCount={totalCount}
        />
      </React.Fragment>
    )
  }
}
