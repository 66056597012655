import React, { Component } from 'react'
import CampusPlacement from '../../components/HiringSolutions/CampusPlacement'
import constant from '../../constant'
export default class CampusPlacementPage extends Component {
  constructor(props){
    super(props);
    this.state = {

    }

  }
  componentDidMount(){
    document.title = constant.title.CampusPlacement
  }
  render() {
    return (
      <React.Fragment>
        <CampusPlacement/>
      </React.Fragment>
    )
  }
}

