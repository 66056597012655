import React, { Component } from 'react'
import FreelanceResource from '../../components/HiringSolutions/FreelanceResources';
import constant from '../../constant';

export default class freelanceResources extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.FreelanceResources
    }
  render() {
    return (
      <React.Fragment>
        <FreelanceResource/>
      </React.Fragment>
    )
  }
}
