import React, { Component } from 'react'
import EmployerBrandingSolutions from '../../components/HrTechConsulting/EmployerBrandingSolutions'
import constant from '../../constant'
export default class EmployerBrandingSolutionss extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        document.title = constant.title.EmployerBrandingSolutions
    }
  render() {
    return (
      <React.Fragment>
        <EmployerBrandingSolutions/>
      </React.Fragment>
    )
  }
}
