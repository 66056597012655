import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class RecruitmentProcessOutsourcing extends Component {
  render() {
    return (
      <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-rpo-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Recruitment Process Outsourcing</h4>
                                <p>We Build The Ideal Workforce For You</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SendEnquiry />
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Recruitment Process Outsourcing</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18'>Mount Talent Consulting is a leading integrated employment service provider in India. With an experience of 14+ years<br/>and a team of professional experts, we find you the best employee fit for your team and organisation.</p>
                                        <p className='font-18'>It is with our dedication, passion and constant support does we acquired the name of the most trustworthy<br/>Recruitment Process Outsourcing in the market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='usp-top-box'>
                                <div className='main-head-box'>
                                    <h3>Our Skills</h3>
                                    <div className='feature-line'>
                                        <span className='animate-bar'></span>
                                    </div>
                                </div>
                                <div className='usp-main-item'>
                                    <div className='usp-item-box color-one'>
                                        <div className='icon-img'><img src='./assets/images/SC2.png' /></div>
                                        <h4>We take end-to-end guarantees of the recruitment process and completely outsource all HR functionings.</h4>
                                    </div>
                                    <div className='usp-item-box color-two'>
                                        <div className='icon-img'><img src='./assets/images/EA2.png' /></div>
                                        <h4>We provide systematic and streamlined recruitment services with effective outcomes in a short time.</h4>
                                    </div>
                                    <div className='usp-item-box color-three'>
                                        <div className='icon-img'><img src='./assets/images/tm.png' /></div>
                                        <h4>With our standardised recruitment process, we deliver quick-time results with an industry-oriented approach.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row mt-5 is-active' id='slide01'>
                            <div className='col-md-6 col-sm-4 mb-5'>
                                <div className='one-side-image-bx'>
                                    <img src='./assets/images/offered-you5.jpg' className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-4  mb-5'>
                                <div className='content-page-box'>
                                    <div className='main-head-box'>
                                        <h3>Our Services</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                    <p className='fa-18'>The best RPO service ensures a complete workforce solution that answers all recruitment needs. We change the way an organisation hire talented people.</p>
                                    <ul className='list-offer'>
                                        <li>Managing all organisational recruitment lifecycle.</li>
                                        <li>We provide on-demand recruitment through staff augmentation.</li>
                                        <li>Monitoring current recruitment work cycle.</li>
                                        <li>Complete solutions to all HR-related activities like sourcing, screening, onboarding, engagement and more.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xl-12 col-md-12 text-center'>
                                <div className='section-title style2 res-991-pb-15'>
                                    <div className='title-header2'>
                                        <h4 className='title'>What Are The Requirements Of Recruitment Process Outsourcing?</h4>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb_15'>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-one'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Finding Talented Candidates</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Through our RPO we invest time and energy in finding you the best and most qualified candidate.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-two'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Low Cost Of Hiring</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Money is very important when it comes to successful business management. You can not keep spending money without even getting the result. That is why our RPO service ensures maximum efficiency at reasonable prices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-three'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Reduce Hiring Time</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Time is of the essence, and if you keep wasting it then do not expect to achieve success anytime sooner. The reason why we are the best in the market is that we provide the finest service in less time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6 offset-2'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-four'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Strategic Recruitment Insights</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>For strategic analysis and forecasting of potential forthcoming recruitment requirements, outsourcing the RPO recruitment process ensures faster and better access to the company's data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-6'>
                                <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                    <div className='featured-content'>
                                        <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                            <div className='cmt-num'><span className='number number-five'></span></div>
                                        </div>
                                        <div className='featured-title'>
                                            <h3>Enhanced Engagement</h3>
                                        </div>
                                        <div className='featured-desc'>
                                            <p>Our RPO providers make sure that candidates get better in-house expertise to get the most out of their time labouring for the company.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
      </React.Fragment>
    )
  }
}
