import React, { Component } from 'react'
import swal from 'sweetalert';
import { CompanyHiringSolution } from '../../action/CandidateAction';
import ContactMap from '../../assets/images/contact-map.png'
import NumberFormat from 'react-number-format';
import { clearForm, onChange, setError, validateForm } from '../../utils';
export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            People: { name: 'People', value: '', error: '', isRequired: true },
            Company: { name: 'Company', value: '', error: '', isRequired: true, options: [], },
            Number: { name: 'Number', value: '', error: '', isRequired: true },
            Email: { name: 'Email', value: '', error: '', isRequired: true },
            Role: { name: 'Role', value: '', error: '', isRequired: true, options: [], },
            ENQUIRY_FOR: { name: 'ENQUIRY_FOR', value: [], error: '', isRequired: true, options: ['Hiring Solutions', 'Staffing Solutions', 'Payroll Solutions', 'Recruitment Process Outsourcing'] },
            TextMessage:{name:'TextMessage' , value:'', error:'',  isRequired:true}
           
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (validateForm(this)) {
            const {Email} = this.state;
            let regex =   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(Email.value)) {
                setError(this, Email.name, 'Incorrect personalEmail Id');

                return false;

            }
           
        }

        if(validateForm(this)){    
        const { People, Number, Company, Role, Email, ENQUIRY_FOR ,TextMessage} = this.state;
        const model = {
            EMAIL: Email.value,
            ENQUIRY_FOR: ENQUIRY_FOR.value,
            PEOPLE_TO_BE_HIRED: People.value,
            ROLE: Role.value,
            PHONE: Number.value,
            COMPANY_PEOPLE: Company.value,
            MESSAGE : TextMessage.value
        }
        CompanyHiringSolution(model).then((res) => {
            if (res.result) {
                swal({
                    icon: "success",
                    text: "Quick hiring solution enquiry has been submitted.",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
                clearForm(this)
                window.location.reload(false);
            }
            else {
                swal({
                    icon: "error",
                    text: "Something went wrong!!",
                    timer: 2000,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
        }).catch((err) => {
            console.log(err);
        });}
    }

        onChange = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            onChange(this, name, value)
        }

        onCheck = (e) => {
            const { name, value } = e.target;
            let prev_value =[] 
    
            if(this.state.ENQUIRY_FOR.value.includes(value)) {
                prev_value = this.state.ENQUIRY_FOR.value.filter(ele=> ele!=value)
             }else{
                prev_value = this.state.ENQUIRY_FOR.value
                prev_value.push(value)
             }
           
             this.setState({
                 ...this.state ,[this.state.ENQUIRY_FOR.name] :{...this.state.ENQUIRY_FOR ,value:prev_value } 
             } ,()=>{
                 console.log( "updated valkue " , this.state.ENQUIRY_FOR);
             })     
    
    
              
            //  setOptions(this,this.state.ENQUIRY_FOR.name,value)
        //    onChange(this,name,value)
         
        }
  render() {
    const {  People, Number, Email, Company, Role, ENQUIRY_FOR,TextMessage } = this.state;
    return (
        <React.Fragment>
        <div id='rg-innerbannervtwo' className='rg-OUSP-bg rg-hed-section'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                        <div className='page-header-section'>
                            <div className='page-header-content'>
                                <h4>Contact Us</h4>
                                <p>Interested in our services? Let us know what you are looking for?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
            <div className="rg-share-your-interview  rg-haslayout pt-0">
                <div className="rozgar-distinguishing-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className='usp-top-box'>
                                    <div className='main-head-box'>
                                        <h3>Connect With Us</h3>
                                        <div className='feature-line'>
                                            <span className='animate-bar'></span>
                                        </div>
                                        <p className='font-18 mb-2'>Interested in our services? Let us know what you are looking for?</p>
                                        <p className='font-18 mb-2'>Mount Talent Consulting aims to bridge the gap between relevant opportunities and compatible talents.<br/>We are the right platform to offer - top qualified talents to the employers and the best compatible career opportunities to the candidates.</p>
                                        <p className='font-18'>Fill the form according to your requirements. We would love to connect with you.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className='secrtion-bg-gray pddding-top-btm'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-10 offset-1 mob-left-0">
                                <div className='formboxarea'>
                                    <ul className='contactform'>
                                    {ENQUIRY_FOR.options.map((item, index) => {
                                        return (
                                        <li><input  value={item}
                                        name={ENQUIRY_FOR.name}
                                        onChange={this.onCheck}
                                        className={ENQUIRY_FOR.error.length > 1 ?"form-check-input is-invalid":" form-check-input"}
                                         type='checkbox'/>{item}</li>
                                        )
                                    })}
                                    </ul>
                                    <form className='contactforminput'>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                            <NumberFormat
                                        type="text"
                                        name={People.name}
                                        value={People.value}
                                        onChange={this.onChange}
                                        maxLength={10}
                                        minLength={10}
                                        class={People.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                        placeholder="No. of People to be Hired"
                                    />
                                                {/* <input type='number' className='inputheight' placeholder="No. of People to be Hired"/> */}
                                            </div>
                                            <div className='form-group'>
                                            <NumberFormat
                                        type="text"
                                        name={Number.name}
                                        value={Number.value}
                                        onChange={this.onChange}
                                        maxLength={10}
                                        minLength={10}
                                        class={Number.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                        placeholder="phone number"
                                    />
                                                {/* <input type='text' className='inputheight' placeholder="phone number"/> */}
                                            </div>
                                        </div>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                                <input type='text' 
                                                  value={Email.value}
                                                  name={Email.name}
                                                  onChange={this.onChange}
                                                  class={Email.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                 placeholder="your email"/>
                                            </div>
                                            <div className='form-group'>
                                                <select
                                                value={Company.value}
                                                name={Company.name}
                                                onChange={this.onChange}
                                                class={Company.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                               >
                                                    <option value="">How large is your company</option>
                                                    <option value="10-100">10-100 people</option>
                                                    <option value="100-500 ">100-500 people</option>
                                                    <option value="500-2000 ">500-2000 people</option>
                                                    <option value="2000+">2000+</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-4'>
                                            <div className='form-group'>
                                                <select
                                                 value={Role.value}
                                                 name={Role.name}
                                                 onChange={this.onChange}
                                                 class={Role.error.length > 0 ?"inputheight is-invalid":" inputheight"}
                                                >
                                                     <option value=''>Your Role</option>
                                            <option value='Leadership/ CEO/ Owner/ Director'>Leadership/ CEO/ Owner/ Director</option>
                                            <option value='Human Resource function'>Human Resource function</option>
                                            <option value='Finance/ Operations'>Finance/ Operations</option>
                                                </select>
                                            </div>
                                            <div className='form-group'>
                                                <textarea type='text'
                                                 class={TextMessage.error.length > 0 ?"inputheighta is-invalid":" inputheighta"}
                                                 value={TextMessage.value}
                                                name={TextMessage.name}
                                                onChange={this.onChange}
                                                 placeholder="Type your message here"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="form-btn-con pt-2 mr-3">
                                        <button onClick={this.onSubmit} type="button">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='cmt-row about-section clearfix fill-time-hr-section'>
                    <div className='container'>
                    <div className='col-md-8 offset-2 mob-left-0'>
                        <div className='row'>
                                <div className='col-md-8 offset-2 mob-left-0 text-center'>
                                    <img src={ContactMap}/>
                                </div>
                                <div className='col-md-6 maplnoida'>
                                    <h4>India Engineering Center</h4>
                                    <p><i class="ti-location-pin"></i> Head office: A-51, Sector 16, Noida, U.P. - 201301</p>
                                </div>
                                <div className='col-md-6 mapnewyork'>
                                    <h4>USA Office Address</h4>
                                    <p><i class="ti-location-pin"></i> 1123 Broadway, Suite 301, New York, NY 10010</p>
                                </div>
                                <div className='col-md-6 mapsingapore'>
                                    <h4>Singapore Office Address</h4>
                                    <p><i class="ti-location-pin"></i> 68 Circular Road #02-01 Singapore (049422)</p>
                                </div>
                                <div className='col-md-6 mapdubai'>
                                    <h4>Dubai Office Address</h4>
                                    <p><i class="ti-location-pin"></i> Office No. 301, Malak Ibrahim Muhammad Hussain Rai Business Bay, Dubai - 346-466</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </main>
      </React.Fragment>
    )
  }
}
