import React, { Component } from 'react'
import ApplicationTrackingSystem from '../../components/HrTechConsulting/ApplicationTrackingSystem';
import constant from '../../constant';
export default class ApplicationTrackingSystems extends Component {
    constructor(props){
            super(props);
            this.state = {

            }
    }
    componentDidMount(){
        document.title = constant.title.ApplicationTrackingSystem
    }
  render() {
    return (
      <React.Fragment>
        <ApplicationTrackingSystem/>
      </React.Fragment>
    )
  }
}
