import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
export default class Hrmanagementsystem extends Component {

    render() {
        return (
            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-hrms-bg rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>HRMS</h4>
                                        <p>World-Class Human Resource Management System<br/>For A High-Quality Employee Experience</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry />
                <main id="rg-main" className="rg-main rg-haslayout pt-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-12 col-sm-4  mb-5'>
                                        <div className='content-page-box text-center'>
                                            <div className='main-head-box text-center'>
                                                <h3>Human Resource Management System</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p className='font-16'>World-Class Human Resource Management System For A High-Quality Employee Experience.</p>
                                            <p className='font-16'>Mount Talent Consulting is known to be the finest HR service provider in the market with leading services and solutions for brand growth. We provide the most-productive and customisable cloud-based HRMS designed for everyone’s needs. Our HRMS work in streamlining every aspect of HR needs in one place, making all HR functions easier and more accessible. Enjoy employee sourcing, hiring, performance management, attendance, payroll and so much more with our HRMS service.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Skills</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box color-one'>
                                                    <div className='icon-img'><img src='./assets/images/hr1.png' /></div>
                                                    <h4>Streamlined Human Resource Functions.</h4>

                                                </div>
                                                <div className='usp-item-box color-two'>
                                                    <div className='icon-img'><img src='./assets/images/hr2.png' /></div>
                                                    <h4>Making An Employee-Focused Workplace.</h4>

                                                </div>
                                                <div className='usp-item-box color-three'>
                                                    <div className='icon-img'><img src='./assets/images/tm (1).png' /></div>
                                                    <h4>Help Grow Businesses Through Our Advanced Technologies.</h4>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-5 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='./assets/images/hrms-content-img.jpg' className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-4  mb-5'>
                                        <div className='content-page-box hrms-content-bx'>
                                            <div className='main-head-box'>
                                                <h3>Our Services</h3>
                                                <div className='feature-line mb-1'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p>Enjoy the benefits of advanced and automated services of HR functions through MTC’s HRMS. We empower organisations and employees with our high-quality services making even the most complicated task easier. HRMS also plays a vital role in the company’s efficient management of the employee lifecycle.</p>

                                            <h5>1. Improved Function</h5>
                                            <p>HRMS's mission is to enhance the hiring and recruiting processes and employee and employer experiences. The applicant tracking system (ATS), employee onboarding, referral processing, and vendor management systems are the primary focus of our HR management system (HRMS).</p>

                                            <h5>2. Balanced HR Functions</h5>
                                            <p>HRMS software aids in efficient employee performance management. This service provides access to all of the tools necessary for a smooth HR operation, including leave and attendance, business communication, the help desk, asset allocation, and the exit process.</p>
                                            
                                            <h5>3. Improved Productivity</h5>
                                            <p>An employee's performance can be easily tracked with HRMS software. Support for GeoTag and GeoFence lets you control how well your employees are managed and work efficiently.</p>
                                            <p>Real-time tracking, video and voice calling, enterprise groups, analytics and insights, and performance management are just a few of the features offered by HRMS.</p>

                                            <h5>4. Payroll Management System</h5>
                                            <p>The adaptability with which our cloud-based HR management system's software handles all salary-related work and issues is well-known. To simplify HR work and meet employee needs, it incorporates a leave and attendance management system and offers 100% accuracy.</p>
                                            <p>Among the many HRMS functions are payroll, expenses, repayment, loans and advances, compliance, and insurance.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15'>
                                            <div className='title-header2'>
                                                <h4 className='title'>What Is The Requirement Of HRMS?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Data Security</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>The human resource management system provides centralised and converged management of all essential employee functions and data to improve employee background and organisational workflow.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Streamlined HR Functioning</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Our HRMS is designed with advanced technology that allows even the most complicated tasks to turn easy. Numerous policies turn into uncomplicated configurations with pre-existing templates to ensure the utmost efficiency.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Clear Employee Analytics</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>TOur HRMS programming is dependable to keep a definite track and straightforward experiences on the worker’s presentation. Cost of the workforce, employee retention rates, and numerous other factors could be addressed here.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </section>
                        
                        <section className='pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src='../assets/images/faqs2.jpg' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>
                                                    What does HR means?
                                                    </label>
                                                    <div class="answers">
                                                        <p>HR or Human Resources is an integral part of an organisation that is responsible for all employee-related activities, from sourcing the best talent to making the most-effective workforce. 
                                                        </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>What is the function of HRMS?
                                                    </label>
                                                    <div class="answers">
                                                        <p>HRMS or Human Resource Management System is software designed particularly to make all HR-related activities easier, streamlined and more productive. HRMS aids towards payroll, employee data management, recruitment, training, retention, and so much more.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>What is the full form of HRMS?
                                                    </label>
                                                    <div class="answers">
                                                        <p>HRMS stands for Human Resource Management System which is cloud-based software designed and presented by Mount Talent Consulting.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question4' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question4' className='question'>
                                                    Which is better for a company, HRIS or HRMS?
                                                    </label>
                                                    <div class="answers">
                                                        <p>While both HRMS and HRIS software are significant in the field, HRMS focuses on the intricate and complex HR function, while HRIS focuses only on the fundamental process.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
