import React, { Component } from 'react'
import RemoteResources from '../../components/HiringSolutions/RemoteResources';
import constant from '../../constant';
export default class RemoteResources2 extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.title = constant.title.RemoteResources
    }
  render() {
    return (
      <React.Fragment>
        <RemoteResources/>
      </React.Fragment>
    )
  }
}
